import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet-semicircle";
import L from "leaflet";
import { cams as cams2 } from "./data2.js";

export default function Semicircle() {
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        cams2.forEach(element => {
            if (element.type === 2) {
                L.semiCircle([element?.geometry?.coordinates[1], element?.geometry?.coordinates[0]], {
                    radius: 500,
                    startAngle: (element?.azymuth - 20 + 360) % 360,
                    stopAngle: (element?.azymuth + 20) % 3600
                }).addTo(map)
            }
        });

    }, [map]);


    return null;
}