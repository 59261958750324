import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { cams as cams2 } from "./data2.js";
import Semicircle from "./Semicircle.js";




function Map() {
  return (
    <MapContainer center={[42.724, 25.49]} zoom={10} scrollWheelZoom={true}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {cams2.map(element => {
        if (element.type === 2) {
          return (
            <Marker position={[element?.geometry?.coordinates[1], element?.geometry?.coordinates[0]]}>
              <Popup>
                Speed: {element?.speed} <br /><br />
                Cordinates: <br />
                {element?.geometry?.coordinates[0]} <br />
                {element?.geometry?.coordinates[1]} <br /><br />

                Azymuth: {element?.azymuth} <br /><br />

                <a target="_blank" rel="noreferrer" href={`http://maps.google.com/maps?q=&layer=c&cbll=${element?.geometry?.coordinates[1]},${element?.geometry?.coordinates[0]}&cbp=11,${360 - element?.azymuth},0,0,0`}>Street view</a>
                <br /><br />
                <a target="_blank" rel="noreferrer" href={`https://speedcamonline.ru/geo/${element?.geometry?.coordinates[1]}/${element?.geometry?.coordinates[0]}/17/`}>SpeedcamOnline</a>
              </Popup>
            </Marker>
          )
        } else {
          return <></>
        }
      })}
      <Semicircle />

    </MapContainer>
  )
}
export default Map;
