export const cams = [
    {
      id: 16178803,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.140163020887655,
          41.773486195026415
        ]
      },
      azymuth: 304,
      speed: 100,
      createdOn: 1670238821868,
      createdBy: 1362169447,
      updatedOn: 1679923182297,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16192849,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.04726216593968,
          41.810578559405336
        ]
      },
      azymuth: 278,
      speed: 0,
      createdOn: 1679922969147,
      createdBy: 1362169447,
      updatedOn: 1679922969160,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16192848,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.047316425495147,
          41.81071258081909
        ]
      },
      azymuth: 99,
      speed: 0,
      createdOn: 1679922969147,
      createdBy: 1362169447,
      updatedOn: 1679922969161,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16180334,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.349267026454346,
          41.63722984513413
        ]
      },
      azymuth: 280,
      speed: 80,
      createdOn: 1671793973281,
      createdBy: 1362169447,
      updatedOn: 1671793973291,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16144831,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.011314451692048,
          41.80248218066443
        ]
      },
      azymuth: 251,
      speed: 50,
      createdOn: 1641388889442,
      createdBy: 1362169447,
      updatedOn: 1641388912290,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16180335,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.140368811648784,
          41.77338724094397
        ]
      },
      azymuth: 122,
      speed: 100,
      createdOn: 1671794106331,
      createdBy: 1362169447,
      updatedOn: 1671794106345,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16166717,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.441226129590078,
          41.62827489650717
        ]
      },
      azymuth: 144,
      speed: 80,
      createdOn: 1659341531596,
      createdBy: 1362169447,
      updatedOn: 1659341531628,
      updatedBy: 1362169447,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16218939,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.02538509463388,
          43.31527449623647
        ]
      },
      azymuth: 120,
      speed: 130,
      createdOn: 1691591054623,
      createdBy: 1777882415,
      updatedOn: 1691591054635,
      updatedBy: 1777882415,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168806,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.29070795741936,
          43.894281598559196
        ]
      },
      azymuth: 145,
      speed: 50,
      createdOn: 1661252784338,
      createdBy: 27271043,
      updatedOn: 1661252784385,
      updatedBy: 27271043,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16237267,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.276922521267,
          43.903048635234
        ]
      },
      azymuth: 279,
      speed: 50,
      createdOn: 1703265271228,
      createdBy: 876564243,
      updatedOn: 1703265271242,
      updatedBy: 876564243,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16237268,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.275563270165,
          43.903055144937
        ]
      },
      azymuth: 82,
      speed: 50,
      createdOn: 1703265271228,
      createdBy: 876564243,
      updatedOn: 1703265271244,
      updatedBy: 876564243,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16121587,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.151893098974423,
          44.46466545259207
        ]
      },
      azymuth: 69,
      speed: 50,
      createdOn: 1621810660433,
      createdBy: 27271043,
      updatedOn: 1621810660497,
      updatedBy: 27271043,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16121586,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.152827649865557,
          44.46505585005708
        ]
      },
      azymuth: 234,
      speed: 50,
      createdOn: 1621810660433,
      createdBy: 27271043,
      updatedOn: 1621810660493,
      updatedBy: 27271043,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 14612911,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.757422366919602,
          42.27469038202069
        ]
      },
      azymuth: 286,
      speed: 50,
      createdOn: 1501783311755,
      createdBy: 763696034,
      updatedOn: 1644823909169,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369451,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.73907861013661,
          42.30746196019465
        ]
      },
      azymuth: 234,
      speed: 0,
      createdOn: 1508396372521,
      createdBy: 19910588,
      updatedOn: 1594129433868,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16150021,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.75744851845766,
          42.27468542041418
        ]
      },
      azymuth: 107,
      speed: 50,
      createdOn: 1644823886192,
      createdBy: 19910588,
      updatedOn: 1644823909170,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16261211,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.99486293104026,
          42.27410314808755
        ]
      },
      azymuth: 74,
      speed: 0,
      createdOn: 1719855719381,
      createdBy: 772798687,
      updatedOn: 1719855719393,
      updatedBy: 772798687,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369454,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.553295943138,
          42.25726460024
        ]
      },
      azymuth: 253,
      speed: 0,
      createdOn: 1508396406175,
      createdBy: 19910588,
      updatedOn: 1723316765758,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369439,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.885637189734,
          42.435552900129
        ]
      },
      azymuth: 211,
      speed: 0,
      createdOn: 1508396284300,
      createdBy: 19910588,
      updatedOn: 1723306284418,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15233856,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.74291722237017,
          42.277611313990676
        ]
      },
      azymuth: 285,
      createdOn: 1507098322210,
      createdBy: 791501725,
      updatedOn: 1644824012261,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267040,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.79618836747663,
          42.34626980422889
        ]
      },
      azymuth: 0,
      speed: 50,
      createdOn: 1723638135150,
      createdBy: 19910588,
      updatedOn: 1723638135161,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369443,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          22.79618915686,
          42.346258850307
        ]
      },
      azymuth: 184,
      speed: 50,
      createdOn: 1508396349959,
      createdBy: 19910588,
      updatedOn: 1723637993443,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15288997,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.64790542556,
          42.272049093546
        ]
      },
      azymuth: 259,
      createdOn: 1507615971471,
      createdBy: 433528680,
      updatedOn: 1723305990077,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16096536,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.926980793475643,
          42.48763197357886
        ]
      },
      azymuth: 239,
      speed: 0,
      createdOn: 1605473423293,
      createdBy: 1025514422,
      updatedOn: 1605473423288,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16177688,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.994842847788,
          42.274178771438
        ]
      },
      azymuth: 260,
      speed: 0,
      createdOn: 1669115586349,
      createdBy: 19910588,
      updatedOn: 1718199338607,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369424,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.965271631970484,
          42.55505966023481
        ]
      },
      azymuth: 354,
      speed: 0,
      createdOn: 1508396178641,
      createdBy: 19910588,
      updatedOn: 1682966903248,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369422,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.969476341206366,
          42.53912037302818
        ]
      },
      azymuth: 323,
      speed: 0,
      createdOn: 1508396178641,
      createdBy: 19910588,
      updatedOn: 1633586302949,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369423,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.956990658241292,
          42.53266150953538
        ]
      },
      azymuth: 5,
      speed: 0,
      createdOn: 1508396178641,
      createdBy: 19910588,
      updatedOn: 1682005974045,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267150,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.969500534305652,
          42.53910955983301
        ]
      },
      azymuth: 138,
      speed: 0,
      createdOn: 1723744644130,
      createdBy: 19910588,
      updatedOn: 1723744644142,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11430702,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.789273915403395,
          43.87842745069165
        ]
      },
      azymuth: 42,
      createdOn: 1466244679570,
      createdBy: 308682359,
      updatedOn: 1675930368208,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11430703,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.84461131415562,
          43.94835311015715
        ]
      },
      azymuth: 40,
      speed: 0,
      createdOn: 1466245116757,
      createdBy: 308682359,
      updatedOn: 1662191925843,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11530926,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.757458670457027,
          43.820210311804345
        ]
      },
      azymuth: 223,
      createdOn: 1467358254838,
      createdBy: 164222588,
      updatedOn: 1633285490513,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13765299,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.888995,
          43.827293
        ]
      },
      azymuth: 121,
      createdOn: 1493715169142,
      createdBy: 164222588,
      updatedOn: 1662194357732,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11530925,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.789222953432045,
          43.87838491656287
        ]
      },
      azymuth: 223,
      createdOn: 1467358254838,
      createdBy: 164222588,
      updatedOn: 1675930368209,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13771952,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.88904389157919,
          43.82735806397282
        ]
      },
      azymuth: 299,
      speed: 0,
      createdOn: 1493795817019,
      createdBy: 164222588,
      updatedOn: 1493795816974,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11530923,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.844469525131206,
          43.94844734630702
        ]
      },
      azymuth: 221,
      speed: 0,
      createdOn: 1467357322410,
      createdBy: 164222588,
      updatedOn: 1662188827260,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369485,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.839863248176695,
          43.98476621060679
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1508396794514,
      createdBy: 19910588,
      updatedOn: 1592762832685,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11531168,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.790938571758275,
          43.65111394072938
        ]
      },
      azymuth: 116,
      createdOn: 1467359759159,
      createdBy: 164222588,
      updatedOn: 1604045569444,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16178137,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.992968389303435,
          43.7717157561202
        ]
      },
      azymuth: 323,
      speed: 0,
      createdOn: 1669559748005,
      createdBy: 19910588,
      updatedOn: 1669559748058,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11531167,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.713088440475463,
          43.71759578121182
        ]
      },
      azymuth: 190,
      createdOn: 1467358938556,
      createdBy: 164222588,
      updatedOn: 1497201189338,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11963746,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.818952809809186,
          43.98379100900475
        ]
      },
      azymuth: 253,
      speed: 0,
      createdOn: 1472530328010,
      createdBy: 164222588,
      updatedOn: 1472530327973,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11541382,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.84043437015583,
          43.995036390996354
        ]
      },
      azymuth: 195,
      speed: 0,
      createdOn: 1467458135692,
      createdBy: 164222588,
      updatedOn: 1675930265944,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11536135,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.713187583178005,
          43.71757228791063
        ]
      },
      azymuth: 9,
      speed: 0,
      createdOn: 1467409275635,
      createdBy: 164222588,
      updatedOn: 1502307764372,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11536136,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.790966280191622,
          43.651170169736815
        ]
      },
      azymuth: 295,
      speed: 0,
      createdOn: 1467409275635,
      createdBy: 164222588,
      updatedOn: 1502307764368,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11938186,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.81899559755326,
          43.98368682131828
        ]
      },
      azymuth: 73,
      createdOn: 1472224824410,
      createdBy: 164222588,
      updatedOn: 1472530327968,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11454483,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.840710637684403,
          43.99499876415333
        ]
      },
      azymuth: 12,
      createdOn: 1466512977713,
      createdBy: 171624844,
      updatedOn: 1675930265945,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11529490,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.757515474868775,
          43.82024412948864
        ]
      },
      azymuth: 42,
      createdOn: 1467333548516,
      createdBy: 164222588,
      updatedOn: 1497195282010,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11918380,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.82935255404545,
          44.024774607088105
        ]
      },
      azymuth: 140,
      speed: 0,
      createdOn: 1471986150830,
      createdBy: 164222588,
      updatedOn: 1604412837047,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11454484,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.871528286219,
          44.015991237098
        ]
      },
      azymuth: 82,
      createdOn: 1466513128964,
      createdBy: 171624844,
      updatedOn: 1723306154612,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11454485,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.892130718332,
          44.017400536348
        ]
      },
      azymuth: 76,
      createdOn: 1466513187420,
      createdBy: 171624844,
      updatedOn: 1723306605446,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11541396,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.892066345316,
          44.017554844448
        ]
      },
      azymuth: 258,
      speed: 0,
      createdOn: 1467458220480,
      createdBy: 164222588,
      updatedOn: 1723306605446,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11541397,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.871505487443,
          44.016093468689
        ]
      },
      azymuth: 264,
      speed: 0,
      createdOn: 1467458220480,
      createdBy: 164222588,
      updatedOn: 1723306154608,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11918379,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          22.82947567644001,
          44.02484458649912
        ]
      },
      azymuth: 322,
      speed: 0,
      createdOn: 1471986150830,
      createdBy: 164222588,
      updatedOn: 1604412837048,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11744290,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.171145303272,
          41.396639663047
        ]
      },
      azymuth: 93,
      createdOn: 1469530592806,
      createdBy: 164222588,
      updatedOn: 1723390005034,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11744730,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.171101046823,
          41.396643687165
        ]
      },
      azymuth: 272,
      speed: 0,
      createdOn: 1469617434739,
      createdBy: 164222588,
      updatedOn: 1723390005034,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16218499,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.333678597251776,
          41.41897950195176
        ]
      },
      azymuth: 142,
      speed: 0,
      createdOn: 1691235090810,
      createdBy: 19910588,
      updatedOn: 1691235090825,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16256748,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.217676526824,
          41.445411155688
        ]
      },
      azymuth: 203,
      speed: 0,
      createdOn: 1715956325843,
      createdBy: 19910588,
      updatedOn: 1715956386472,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16218498,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.333903902809002,
          41.41911024067449
        ]
      },
      azymuth: 324,
      speed: 0,
      createdOn: 1691235090810,
      createdBy: 19910588,
      updatedOn: 1691235090825,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8927080,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.482622173241527,
          41.84703817490322
        ]
      },
      azymuth: 349,
      speed: 0,
      createdOn: 1441903710740,
      createdBy: 193666435,
      updatedOn: 1671635711821,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 11743091,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.267029238577376,
          41.5529163816205
        ]
      },
      azymuth: 317,
      speed: 0,
      createdOn: 1469592732025,
      createdBy: 164222588,
      updatedOn: 1674229450617,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11745203,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.094856466056697,
          41.97120085602568
        ]
      },
      azymuth: 330,
      createdOn: 1469536562212,
      createdBy: 164222588,
      updatedOn: 1639831793820,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11721907,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.116193862116493,
          41.887419837322
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1469376717322,
      createdBy: 164222588,
      updatedOn: 1622648183194,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11743088,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.273910146891346,
          41.52596154568812
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1469592670526,
      createdBy: 164222588,
      updatedOn: 1675708495542,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16184434,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.271337576027978,
          41.51453498810537
        ]
      },
      azymuth: 178,
      speed: 0,
      createdOn: 1675707890557,
      createdBy: 19910588,
      updatedOn: 1675707909822,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10997282,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.186120684699063,
          41.67462200602683
        ]
      },
      azymuth: 167,
      speed: 50,
      createdOn: 1461858379866,
      createdBy: 275233269,
      updatedOn: 1603702421864,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11719767,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.410944967861308,
          41.876193889882
        ]
      },
      azymuth: 297,
      createdOn: 1469264041820,
      createdBy: 164222588,
      updatedOn: 1662272787792,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11743094,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.186111296967475,
          41.67465806775309
        ]
      },
      azymuth: 347,
      speed: 0,
      createdOn: 1469592812414,
      createdBy: 164222588,
      updatedOn: 1603702421865,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16184435,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.271656758900193,
          41.514549046937184
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1675707890557,
      createdBy: 19910588,
      updatedOn: 1675707909822,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11719768,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.116185815489672,
          41.88736592390485
        ]
      },
      azymuth: 179,
      createdOn: 1469265842981,
      createdBy: 164222588,
      updatedOn: 1622648183193,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8951429,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.09469553351582,
          41.97112806872081
        ]
      },
      azymuth: 149,
      speed: 60,
      createdOn: 1442214524360,
      createdBy: 237951772,
      updatedOn: 1639831793819,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11721919,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.41100934087766,
          41.876163932726065
        ]
      },
      azymuth: 116,
      speed: 0,
      createdOn: 1469376816886,
      createdBy: 164222588,
      updatedOn: 1662272787793,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11622111,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.267085564966905,
          41.552870215693446
        ]
      },
      azymuth: 137,
      createdOn: 1468231785701,
      createdBy: 300513316,
      updatedOn: 1674229450615,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8923768,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.482627537658807,
          41.847010881478674
        ]
      },
      azymuth: 171,
      speed: 0,
      createdOn: 1441869576590,
      createdBy: 193666435,
      updatedOn: 1671635994581,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16219239,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.053853817125148,
          41.99188924594501
        ]
      },
      azymuth: 163,
      speed: 0,
      createdOn: 1691748129336,
      createdBy: 1025514422,
      updatedOn: 1691748129351,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 11622112,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.273805540740003,
          41.525957529588005
        ]
      },
      azymuth: 176,
      createdOn: 1468231996909,
      createdBy: 300513316,
      updatedOn: 1675708495542,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16219238,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.054139472385316,
          41.9919460613475
        ]
      },
      azymuth: 342,
      speed: 0,
      createdOn: 1691748112822,
      createdBy: 1025514422,
      updatedOn: 1691748112835,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 14032492,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.16068362330024,
          41.7205286496359
        ]
      },
      azymuth: 119,
      speed: 50,
      createdOn: 1496581480151,
      createdBy: 310232091,
      updatedOn: 1723311329617,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16175210,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.198875311122343,
          41.632268067469916
        ]
      },
      azymuth: 325,
      speed: 0,
      createdOn: 1667654450565,
      createdBy: 19910588,
      updatedOn: 1667654470934,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16175211,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.198569539294223,
          41.632143773001886
        ]
      },
      azymuth: 142,
      speed: 0,
      createdOn: 1667654470884,
      createdBy: 19910588,
      updatedOn: 1667654470938,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12236250,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.068641689156,
          42.09159389318
        ]
      },
      azymuth: 57,
      createdOn: 1476693538684,
      createdBy: 212255260,
      updatedOn: 1723373968270,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15884438,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.116031961450453,
          42.294656723111046
        ]
      },
      azymuth: 149,
      speed: 0,
      createdOn: 1571228855237,
      createdBy: -2,
      updatedOn: 1571466329169,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15887830,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.034532623243,
          42.159679731837
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1577460356485,
      createdBy: 772798687,
      updatedOn: 1723369474292,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11745206,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.135117961971286,
          42.279398511727635
        ]
      },
      azymuth: 66,
      createdOn: 1469538140673,
      createdBy: 164222588,
      updatedOn: 1472901788789,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15887831,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.034724401188,
          42.159657860971
        ]
      },
      azymuth: 188,
      speed: 0,
      createdOn: 1577460356485,
      createdBy: 772798687,
      updatedOn: 1723369474295,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179603,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.052558603711105,
          42.03378611395125
        ]
      },
      azymuth: 328,
      speed: 0,
      createdOn: 1671097767380,
      createdBy: 1025514422,
      updatedOn: 1671097767438,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11745205,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.041040545014095,
          42.157911947760574
        ]
      },
      azymuth: 2,
      createdOn: 1469537404740,
      createdBy: 164222588,
      updatedOn: 1594131040835,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11758197,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.135034689865936,
          42.27955080912376
        ]
      },
      azymuth: 246,
      speed: 0,
      createdOn: 1469789945700,
      createdBy: 164222588,
      updatedOn: 1602700989387,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11745204,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.038563295858,
          42.064135728099
        ]
      },
      azymuth: 346,
      createdOn: 1469537008841,
      createdBy: 164222588,
      updatedOn: 1708712726928,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12271068,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.068606820439,
          42.091582945989
        ]
      },
      azymuth: 238,
      createdOn: 1477149687624,
      createdBy: 164222588,
      updatedOn: 1723373968175,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15884437,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.11601452709115,
          42.294679539262276
        ]
      },
      azymuth: 334,
      speed: 0,
      createdOn: 1571228855237,
      createdBy: -2,
      updatedOn: 1571466329168,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245712,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.052652073005003,
          42.00785949170723
        ]
      },
      azymuth: 245,
      speed: 0,
      createdOn: 1708501330672,
      createdBy: 19910588,
      updatedOn: 1708501330684,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369410,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.119946925837127,
          42.44660188443865
        ]
      },
      azymuth: 354,
      speed: 0,
      createdOn: 1508396111361,
      createdBy: 19910588,
      updatedOn: 1508396122648,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15887834,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.044917449013,
          42.011634894418
        ]
      },
      azymuth: 329,
      speed: 0,
      createdOn: 1577460457072,
      createdBy: 772798687,
      updatedOn: 1708712798645,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11850813,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.03812616144538,
          42.11308874557186
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1471277541313,
      createdBy: 19910588,
      updatedOn: 1594131199640,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11904860,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.095581330159433,
          42.34579622526739
        ]
      },
      azymuth: 2,
      speed: 140,
      createdOn: 1471792709370,
      createdBy: 322559152,
      updatedOn: 1583501082405,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15887833,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.044646545902,
          42.011505355445
        ]
      },
      azymuth: 146,
      speed: 0,
      createdOn: 1577460457072,
      createdBy: 772798687,
      updatedOn: 1708712798644,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11943418,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.09528628716768,
          42.34581010207717
        ]
      },
      azymuth: 183,
      speed: 140,
      createdOn: 1472282693330,
      createdBy: 193666435,
      updatedOn: 1583501082406,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11943419,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.09623776686477,
          42.32444440622129
        ]
      },
      azymuth: 204,
      speed: 140,
      createdOn: 1472282774538,
      createdBy: 193666435,
      updatedOn: 1505991440987,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11872772,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.09635855092622,
          42.32437612008934
        ]
      },
      azymuth: 22,
      speed: 140,
      createdOn: 1471417213591,
      createdBy: 351787689,
      updatedOn: 1596385821453,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11758177,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.038581400768,
          42.064139212802
        ]
      },
      azymuth: 163,
      speed: 0,
      createdOn: 1469789699277,
      createdBy: 164222588,
      updatedOn: 1708712726928,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11758189,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.041027133969095,
          42.157848321625416
        ]
      },
      azymuth: 180,
      speed: 0,
      createdOn: 1469789834056,
      createdBy: 164222588,
      updatedOn: 1594131040834,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16144118,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.230417228299352,
          42.705768087501845
        ]
      },
      azymuth: 184,
      speed: 0,
      createdOn: 1640811394696,
      createdBy: 772798687,
      updatedOn: 1667834204538,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16239860,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.295501496925,
          42.692283756733
        ]
      },
      azymuth: 224,
      speed: 0,
      createdOn: 1705093129278,
      createdBy: 1025514422,
      updatedOn: 1705093129294,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13640404,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.37374891856689,
          42.951746588088135
        ]
      },
      azymuth: 308,
      speed: 0,
      createdOn: 1492500765473,
      createdBy: 164222588,
      updatedOn: 1595604622687,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13640412,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.36700631470699,
          42.85037989538627
        ]
      },
      azymuth: 313,
      speed: 0,
      createdOn: 1492500812458,
      createdBy: 164222588,
      updatedOn: 1589986499061,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9273472,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.357676916170007,
          42.663555917990934
        ]
      },
      azymuth: 241,
      createdOn: 1446051162750,
      createdBy: 281528514,
      updatedOn: 1589702460016,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16089336,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.35182274020184,
          42.68739401960216
        ]
      },
      azymuth: 347,
      speed: 0,
      createdOn: 1601989628811,
      createdBy: 1025514422,
      updatedOn: 1620802910389,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16166139,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.147030257127966,
          42.96844288066098
        ]
      },
      azymuth: 306,
      speed: 0,
      createdOn: 1658941395508,
      createdBy: 1025514422,
      updatedOn: 1660698672623,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8731787,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.133863047954648,
          42.60140278173746
        ]
      },
      azymuth: 85,
      createdOn: 1439656614748,
      createdBy: 262602509,
      updatedOn: 1595661339242,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16089339,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.344667277101582,
          42.699597977566505
        ]
      },
      azymuth: 167,
      speed: 0,
      createdOn: 1601989721583,
      createdBy: 1025514422,
      updatedOn: 1620802859844,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 9507998,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.346798501367363,
          42.69402280953917
        ]
      },
      azymuth: 11,
      speed: 50,
      createdOn: 1448357779561,
      createdBy: 288026464,
      updatedOn: 1589697676933,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10359950,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.302785681365513,
          42.67907556392346
        ]
      },
      azymuth: 344,
      speed: 0,
      createdOn: 1455867069731,
      createdBy: 301657096,
      updatedOn: 1616096373328,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13124312,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.18118663310071,
          42.77602004683061
        ]
      },
      azymuth: 120,
      speed: 0,
      createdOn: 1487177704598,
      createdBy: 355271078,
      updatedOn: 1658281902049,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16193255,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.131172418030193,
          42.62558250764124
        ]
      },
      azymuth: 298,
      speed: 80,
      createdOn: 1679983053582,
      createdBy: 1025514422,
      updatedOn: 1723308566619,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9503877,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.337949123084034,
          42.690433441796266
        ]
      },
      azymuth: 357,
      createdOn: 1448303728353,
      createdBy: 153422466,
      updatedOn: 1615388939023,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16170208,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.373037360200545,
          42.700788861883375
        ]
      },
      azymuth: 255,
      speed: 0,
      createdOn: 1662240560979,
      createdBy: 1025514422,
      updatedOn: 1691522497882,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16175342,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.22299940994939,
          42.71064050423545
        ]
      },
      azymuth: 73,
      speed: 0,
      createdOn: 1667833669897,
      createdBy: 19910588,
      updatedOn: 1669275782124,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9932939,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334796224411505,
          42.695518343947555
        ]
      },
      azymuth: 101,
      speed: 0,
      createdOn: 1452052847585,
      createdBy: 283805131,
      updatedOn: 1600353482434,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9932938,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334975932415556,
          42.695673093696016
        ]
      },
      azymuth: 189,
      speed: 0,
      createdOn: 1452052799649,
      createdBy: 283805131,
      updatedOn: 1600353482434,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10868411,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.482063761280013,
          42.7057205681597
        ]
      },
      azymuth: 276,
      speed: 90,
      createdOn: 1460632370178,
      createdBy: 285430962,
      updatedOn: 1589381498969,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13640399,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.3590384395586,
          42.97861397757578
        ]
      },
      azymuth: 26,
      speed: 0,
      createdOn: 1492500734328,
      createdBy: 164222588,
      updatedOn: 1604424789404,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16113877,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.324206458514592,
          42.697334925628844
        ]
      },
      azymuth: 330,
      speed: 0,
      createdOn: 1615975096343,
      createdBy: 1025514422,
      updatedOn: 1616096174967,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 751146,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.317317166828637,
          42.70512956888285
        ]
      },
      azymuth: 95,
      createdOn: 1343280127000,
      createdBy: 20302875,
      updatedOn: 1618957269709,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9666743,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.376622022266567,
          42.64804276680818
        ]
      },
      azymuth: 110,
      speed: 50,
      createdOn: 1449903158789,
      createdBy: 283805131,
      updatedOn: 1589696706344,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16243411,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.24025226319352,
          42.6832845458128
        ]
      },
      azymuth: 126,
      speed: 0,
      createdOn: 1707375606149,
      createdBy: 1025514422,
      updatedOn: 1707375606176,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16243410,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.240601620918103,
          42.68332940254324
        ]
      },
      azymuth: 211,
      speed: 0,
      createdOn: 1707375606149,
      createdBy: 1025514422,
      updatedOn: 1707375606173,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16243409,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.240824244266687,
          42.6831164560177
        ]
      },
      azymuth: 300,
      speed: 0,
      createdOn: 1707375606149,
      createdBy: 1025514422,
      updatedOn: 1707375606169,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9252005,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.28521985287638,
          42.67864637367577
        ]
      },
      azymuth: 315,
      createdOn: 1445842583498,
      createdBy: 280528930,
      updatedOn: 1590155000762,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 1448504,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32308985767379,
          42.688759836908254
        ]
      },
      azymuth: 188,
      speed: 0,
      createdOn: 1357382090000,
      createdBy: 14084138,
      updatedOn: 1589630692145,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8162898,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.321064935803193,
          42.68346969567109
        ]
      },
      azymuth: 246,
      speed: 0,
      createdOn: 1432545263434,
      createdBy: 251269825,
      updatedOn: 1616096373326,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16132806,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32379419376581,
          42.697442500411604
        ]
      },
      azymuth: 59,
      speed: 0,
      createdOn: 1630590762295,
      createdBy: 1025514422,
      updatedOn: 1630590762332,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16139456,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.289897052760804,
          42.65090716489099
        ]
      },
      azymuth: 123,
      speed: 80,
      createdOn: 1635710440780,
      createdBy: 288999157,
      updatedOn: 1635765189010,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 4813425,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.320602424258805,
          42.69678151698614
        ]
      },
      azymuth: 99,
      createdOn: 1399035495720,
      createdBy: 11376805,
      updatedOn: 1592941161872,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12378758,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.448972118338,
          42.712149692608
        ]
      },
      azymuth: 352,
      createdOn: 1478587018679,
      createdBy: 355271078,
      updatedOn: 1723306221061,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874226,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.321811188878186,
          42.697569759758224
        ]
      },
      azymuth: 14,
      speed: 0,
      createdOn: 1552119942762,
      createdBy: 1025514422,
      updatedOn: 1627217723089,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2823274,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.329963423663486,
          42.698130026892734
        ]
      },
      azymuth: 352,
      speed: 0,
      createdOn: 1375970989205,
      createdBy: 36048745,
      updatedOn: 1627217748603,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2823273,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.329312057972775,
          42.695038666897624
        ]
      },
      azymuth: 27,
      speed: 0,
      createdOn: 1375970863273,
      createdBy: 36048745,
      updatedOn: 1616096245775,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874225,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.322005941486637,
          42.69775885048371
        ]
      },
      azymuth: 282,
      speed: 0,
      createdOn: 1552119924487,
      createdBy: 1025514422,
      updatedOn: 1593077790117,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874235,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33406818108473,
          42.70149287422989
        ]
      },
      azymuth: 261,
      speed: 0,
      createdOn: 1552136490083,
      createdBy: 1025514422,
      updatedOn: 1590948840802,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9537755,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.331323002529164,
          42.6902379296891
        ]
      },
      azymuth: 222,
      speed: 50,
      createdOn: 1448649578907,
      createdBy: 32661862,
      updatedOn: 1590154803284,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 9537754,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.331274722766455,
          42.68993431726349
        ]
      },
      azymuth: 39,
      speed: 50,
      createdOn: 1448649554487,
      createdBy: 32661862,
      updatedOn: 1593068153242,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9984211,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33020146068481,
          42.68574110681905
        ]
      },
      azymuth: 65,
      speed: 50,
      createdOn: 1452465348366,
      createdBy: 300861068,
      updatedOn: 1589616437261,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15868094,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.230293169144005,
          42.705771616894594
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1540282676325,
      createdBy: 19910588,
      updatedOn: 1540282676299,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9161414,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334312658548743,
          42.69341849449322
        ]
      },
      azymuth: 193,
      speed: 0,
      createdOn: 1444977989256,
      createdBy: 19888339,
      updatedOn: 1617226919897,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11997946,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.449455089475663,
          42.56184411977907
        ]
      },
      azymuth: 49,
      createdOn: 1472974311818,
      createdBy: 164222588,
      updatedOn: 1643802232724,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 407624,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.333998894654783,
          42.68876733070965
        ]
      },
      azymuth: 231,
      speed: 0,
      createdOn: 1329807044000,
      createdBy: 16296370,
      updatedOn: 1589615405416,
      updatedBy: 19910588,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 8681674,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.329177947520996,
          42.69518109691982
        ]
      },
      azymuth: 119,
      speed: 0,
      createdOn: 1439123024581,
      createdBy: -2,
      updatedOn: 1616096245775,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874236,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33373424606275,
          42.70132335534282
        ]
      },
      azymuth: 65,
      speed: 0,
      createdOn: 1552136508533,
      createdBy: 1025514422,
      updatedOn: 1590948840802,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16215736,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.29478184624037,
          42.67186134865365
        ]
      },
      azymuth: 319,
      speed: 0,
      createdOn: 1689606191784,
      createdBy: 1025514422,
      updatedOn: 1689606191805,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 1256518,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.319176210974042,
          42.6890344013248
        ]
      },
      azymuth: 274,
      createdOn: 1353604222000,
      createdBy: 27714078,
      updatedOn: 1593077790119,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16166061,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.317597331822046,
          42.70492232364473
        ]
      },
      azymuth: 12,
      speed: 0,
      createdOn: 1658868012357,
      createdBy: 1025514422,
      updatedOn: 1658868012394,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16102570,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.291942835985367,
          42.6630534848304
        ]
      },
      azymuth: 124,
      speed: 0,
      createdOn: 1609085604387,
      createdBy: 1315349590,
      updatedOn: 1620884000293,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9171178,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.29441170557846,
          42.671697752197375
        ]
      },
      azymuth: 35,
      createdOn: 1445088219633,
      createdBy: 280881818,
      updatedOn: 1589616642600,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9171177,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.294382201278925,
          42.672322902477696
        ]
      },
      azymuth: 211,
      createdOn: 1445088209465,
      createdBy: 280881818,
      updatedOn: 1589616642600,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10588355,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.331011866282868,
          42.686166639599215
        ]
      },
      azymuth: 57,
      speed: 0,
      createdOn: 1458078110849,
      createdBy: 193666435,
      updatedOn: 1590154870351,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14699650,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.201166746326702,
          42.722747446542435
        ]
      },
      azymuth: 258,
      speed: 0,
      createdOn: 1502478704877,
      createdBy: 412140089,
      updatedOn: 1618695276695,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15853205,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.147012097510228,
          42.968427214632854
        ]
      },
      azymuth: 130,
      speed: 0,
      createdOn: 1515357438109,
      createdBy: 832736478,
      updatedOn: 1660698672624,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16202397,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.304125877384713,
          42.6747368070106
        ]
      },
      azymuth: 269,
      speed: 0,
      createdOn: 1682289701723,
      createdBy: 1025514422,
      updatedOn: 1682289701743,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16262802,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.321972286533,
          42.67609842598
        ]
      },
      azymuth: 6,
      speed: 50,
      createdOn: 1721167256068,
      createdBy: 1025514422,
      updatedOn: 1721249113599,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9171175,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334556739569148,
          42.693105040548765
        ]
      },
      azymuth: 322,
      speed: 0,
      createdOn: 1445087566144,
      createdBy: 280881818,
      updatedOn: 1617226908068,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9686240,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.301297304618164,
          42.68426218141428
        ]
      },
      azymuth: 353,
      speed: 0,
      createdOn: 1450085140788,
      createdBy: 291985198,
      updatedOn: 1671010168680,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 548479,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.335041646535778,
          42.6953980922851
        ]
      },
      azymuth: 12,
      speed: 0,
      createdOn: 1335283993000,
      createdBy: -2,
      updatedOn: 1600353482434,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14914659,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.274149035167014,
          42.68560274981287
        ]
      },
      azymuth: 211,
      speed: 0,
      createdOn: 1504249536469,
      createdBy: 412140089,
      updatedOn: 1590155000763,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8200959,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.45368531830091,
          42.63261776644751
        ]
      },
      azymuth: 285,
      speed: 60,
      createdOn: 1433088089306,
      createdBy: 46330472,
      updatedOn: 1589379770652,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 14136401,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.29313597668671,
          42.683362239668185
        ]
      },
      azymuth: 235,
      createdOn: 1497634896874,
      createdBy: 740239390,
      updatedOn: 1639644393101,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8564235,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.45361627742454,
          42.63231996144983
        ]
      },
      azymuth: 103,
      speed: 60,
      createdOn: 1437807291730,
      createdBy: 263226096,
      updatedOn: 1589379446682,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 16262769,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.321974633466,
          42.676091770638
        ]
      },
      azymuth: 176,
      speed: 50,
      createdOn: 1721157370663,
      createdBy: 11140128,
      updatedOn: 1721249113599,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 395917,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.319105132435645,
          42.6888835781293
        ]
      },
      azymuth: 14,
      createdOn: 1329324603000,
      createdBy: 16828400,
      updatedOn: 1593077790119,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 190611,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33758377229671,
          42.69088527217663
        ]
      },
      azymuth: 246,
      speed: 0,
      createdOn: 1317465705000,
      createdBy: 13764780,
      updatedOn: 1589697740796,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9184798,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.321958726670406,
          42.67830281637853
        ]
      },
      azymuth: 353,
      speed: 50,
      createdOn: 1445234646589,
      createdBy: 280881818,
      updatedOn: 1589616353055,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9184799,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.320527152895796,
          42.68284662926145
        ]
      },
      azymuth: 167,
      createdOn: 1445234742595,
      createdBy: 280881818,
      updatedOn: 1616096373326,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11284031,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.241296659407404,
          42.738310490801744
        ]
      },
      azymuth: 127,
      createdOn: 1464597232150,
      createdBy: 285430962,
      updatedOn: 1589869773090,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 383123,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.329015190222396,
          42.70469230287111
        ]
      },
      azymuth: 290,
      speed: 50,
      createdOn: 1328799187000,
      createdBy: 14760714,
      updatedOn: 1618957283621,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16253550,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.27899269555508,
          42.675403036049865
        ]
      },
      azymuth: 50,
      speed: 0,
      createdOn: 1713633289599,
      createdBy: 1025514422,
      updatedOn: 1713633289614,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16253551,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.279368204817725,
          42.67570376154766
        ]
      },
      azymuth: 233,
      speed: 0,
      createdOn: 1713633289599,
      createdBy: 1025514422,
      updatedOn: 1713633289616,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15886958,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334528610300154,
          42.698599518098895
        ]
      },
      azymuth: 81,
      speed: 0,
      createdOn: 1575843375803,
      createdBy: 1025514422,
      updatedOn: 1589697263210,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9151510,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.350359493978182,
          42.6854504306745
        ]
      },
      azymuth: 41,
      speed: 50,
      createdOn: 1444838477607,
      createdBy: 257425258,
      updatedOn: 1589617625398,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15877718,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.250092583593357,
          42.73352236148706
        ]
      },
      azymuth: 126,
      speed: 0,
      createdOn: 1558962601025,
      createdBy: 1025514422,
      updatedOn: 1618569669225,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16054352,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.441900926355398,
          42.75270033880493
        ]
      },
      azymuth: 351,
      speed: 0,
      createdOn: 1596371481518,
      createdBy: 1025514422,
      updatedOn: 1596371493745,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 3235988,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32150382396068,
          42.69766134673148
        ]
      },
      azymuth: 99,
      speed: 0,
      createdOn: 1380601346109,
      createdBy: 152994439,
      updatedOn: 1627217723087,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11947549,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.1177198749945,
          42.573661880792635
        ]
      },
      azymuth: 14,
      speed: 120,
      createdOn: 1472322590153,
      createdBy: 193666435,
      updatedOn: 1571406206487,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9184800,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.302147315620182,
          42.67971740061775
        ]
      },
      azymuth: 166,
      speed: 0,
      createdOn: 1445234952946,
      createdBy: 280881818,
      updatedOn: 1616096373327,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9184802,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334246170378986,
          42.6764032249535
        ]
      },
      azymuth: 265,
      createdOn: 1445237488133,
      createdBy: 280881818,
      updatedOn: 1681499003170,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10586126,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.331489108594425,
          42.68610563394433
        ]
      },
      azymuth: 319,
      speed: 50,
      createdOn: 1458058181492,
      createdBy: 299034505,
      updatedOn: 1589616437260,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9178175,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33347503528665,
          42.67643871992624
        ]
      },
      azymuth: 149,
      createdOn: 1445171891965,
      createdBy: 280881818,
      updatedOn: 1681499091071,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16054351,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.44193579507221,
          42.75270329308574
        ]
      },
      azymuth: 172,
      speed: 0,
      createdOn: 1596371459664,
      createdBy: 1025514422,
      updatedOn: 1607595035232,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 197311,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.312450798081215,
          42.70608600938229
        ]
      },
      azymuth: 272,
      speed: 0,
      createdOn: 1318495265000,
      createdBy: 13764780,
      updatedOn: 1618957246945,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9769002,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.377276481265536,
          42.64810293849728
        ]
      },
      azymuth: 288,
      speed: 50,
      createdOn: 1450806185648,
      createdBy: 283805131,
      updatedOn: 1589696706342,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 594125,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.321679508651325,
          42.69786635834821
        ]
      },
      azymuth: 193,
      speed: 0,
      createdOn: 1336127158000,
      createdBy: 11200654,
      updatedOn: 1627217723088,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245296,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.299178372256,
          42.706907163434
        ]
      },
      azymuth: 13,
      speed: 0,
      createdOn: 1708276862008,
      createdBy: 1025514422,
      updatedOn: 1708276862023,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 12012671,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.44943832566919,
          42.561854491545844
        ]
      },
      azymuth: 225,
      speed: 0,
      createdOn: 1473150547674,
      createdBy: 164222588,
      updatedOn: 1630746544643,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 9253959,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.376935840720606,
          42.648306140821056
        ]
      },
      azymuth: 206,
      speed: 50,
      createdOn: 1445861135232,
      createdBy: 249450728,
      updatedOn: 1589696706344,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16109605,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.41560205817297,
          42.66295237793436
        ]
      },
      azymuth: 160,
      speed: 0,
      createdOn: 1613506462504,
      createdBy: 1490035735,
      updatedOn: 1613506462463,
      updatedBy: 1490035735,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16227364,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.379567595152402,
          42.65220471280834
        ]
      },
      azymuth: 202,
      speed: 0,
      createdOn: 1696833197087,
      createdBy: 1025514422,
      updatedOn: 1697371986105,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16227363,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.37942342641979,
          42.6519832755803
        ]
      },
      azymuth: 26,
      speed: 0,
      createdOn: 1696833197087,
      createdBy: 1025514422,
      updatedOn: 1697371986105,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9676360,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.376970709438318,
          42.647883952725884
        ]
      },
      azymuth: 24,
      speed: 50,
      createdOn: 1449992702455,
      createdBy: 283805131,
      updatedOn: 1589696706343,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14092812,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.281513699775804,
          42.6931517402694
        ]
      },
      azymuth: 227,
      createdOn: 1497197228746,
      createdBy: 740239390,
      updatedOn: 1590155027804,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14092811,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.285390694442707,
          42.69571668808532
        ]
      },
      azymuth: 228,
      createdOn: 1497197165911,
      createdBy: 740239390,
      updatedOn: 1497802577593,
      updatedBy: 285430962,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 9502796,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32888469099986,
          42.68799846833659
        ]
      },
      azymuth: 43,
      speed: 0,
      createdOn: 1448294262975,
      createdBy: 32661862,
      updatedOn: 1620497965464,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9997381,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.31062043511893,
          42.68695560809436
        ]
      },
      azymuth: 317,
      speed: 50,
      createdOn: 1452599391805,
      createdBy: 301201188,
      updatedOn: 1606753096922,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9161321,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33409942293264,
          42.69316615433447
        ]
      },
      azymuth: 121,
      speed: 50,
      createdOn: 1444975695269,
      createdBy: 280352590,
      updatedOn: 1589641809022,
      updatedBy: 19910588,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 15874576,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.330511634300937,
          42.68577822655367
        ]
      },
      azymuth: 345,
      speed: 0,
      createdOn: 1552910838519,
      createdBy: 1025514422,
      updatedOn: 1611596220680,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 4793002,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.330813191922743,
          42.68674444059348
        ]
      },
      azymuth: 229,
      speed: 50,
      createdOn: 1399132770890,
      createdBy: 182330636,
      updatedOn: 1589616437260,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12019284,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.336368626319533,
          42.67239637081334
        ]
      },
      azymuth: 328,
      speed: 0,
      createdOn: 1473257973670,
      createdBy: 317937065,
      updatedOn: 1589615839875,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874582,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.337817019187426,
          42.69040862533021
        ]
      },
      azymuth: 310,
      speed: 0,
      createdOn: 1552912766022,
      createdBy: 1025514422,
      updatedOn: 1604691139265,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874581,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33408472534295,
          42.68860467752946
        ]
      },
      azymuth: 310,
      speed: 0,
      createdOn: 1552911838762,
      createdBy: 1025514422,
      updatedOn: 1589615405416,
      updatedBy: 19910588,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 10815561,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.223001421606096,
          42.71067499421853
        ]
      },
      azymuth: 253,
      speed: 0,
      createdOn: 1460106382377,
      createdBy: 285430962,
      updatedOn: 1669275782123,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12019291,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.335808044635556,
          42.67278585354728
        ]
      },
      azymuth: 147,
      speed: 0,
      createdOn: 1473258055950,
      createdBy: 317937065,
      updatedOn: 1589615845502,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10815051,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.11938636620555,
          42.60065190452698
        ]
      },
      azymuth: 94,
      speed: 50,
      createdOn: 1460110991220,
      createdBy: 285430962,
      updatedOn: 1583562633822,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8659563,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.317986592711556,
          42.705679642937326
        ]
      },
      azymuth: 271,
      speed: 0,
      createdOn: 1438888552499,
      createdBy: 266450287,
      updatedOn: 1617013982552,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10815562,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.119414529399418,
          42.60082465952628
        ]
      },
      azymuth: 274,
      speed: 0,
      createdOn: 1460107035670,
      createdBy: 285430962,
      updatedOn: 1673509896405,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9394302,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.284345452737465,
          42.67887511044519
        ]
      },
      azymuth: 127,
      createdOn: 1447228868988,
      createdBy: 41719455,
      updatedOn: 1590155000763,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8756849,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.004583264328165,
          42.659262012935955
        ]
      },
      azymuth: 178,
      speed: 0,
      createdOn: 1439924450599,
      createdBy: 153422466,
      updatedOn: 1557908635794,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10614873,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.335183825377865,
          42.69556668087851
        ]
      },
      azymuth: 278,
      speed: 0,
      createdOn: 1458329508551,
      createdBy: 193666435,
      updatedOn: 1603905537652,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15874574,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.333634114228495,
          42.688648051752345
        ]
      },
      azymuth: 124,
      speed: 0,
      createdOn: 1552907817506,
      createdBy: 1025514422,
      updatedOn: 1633370849548,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9067403,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33435825610192,
          42.6928980417933
        ]
      },
      azymuth: 12,
      speed: 0,
      createdOn: 1443763485563,
      createdBy: 271783497,
      updatedOn: 1617226908068,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9685394,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.30079707263623,
          42.68463877384726
        ]
      },
      azymuth: 169,
      speed: 0,
      createdOn: 1450077208760,
      createdBy: 158012308,
      updatedOn: 1671010146117,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10818977,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.455839385042,
          42.658081418197
        ]
      },
      azymuth: 194,
      createdOn: 1460123786777,
      createdBy: 285430962,
      updatedOn: 1702719407713,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10613670,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.350435936934762,
          42.68586151967265
        ]
      },
      azymuth: 221,
      speed: 50,
      createdOn: 1458322905678,
      createdBy: 193666435,
      updatedOn: 1589617625398,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10818978,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.455984894881,
          42.658054295976
        ]
      },
      azymuth: 13,
      createdOn: 1460123812530,
      createdBy: 285430962,
      updatedOn: 1702719407713,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10935211,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.347599643132,
          42.659428468574
        ]
      },
      azymuth: 61,
      speed: 50,
      createdOn: 1461260970743,
      createdBy: -2,
      updatedOn: 1723370197361,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 565530,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.322174644496044,
          42.67852465284636
        ]
      },
      azymuth: 289,
      speed: 50,
      createdOn: 1336727584000,
      createdBy: 19362642,
      updatedOn: 1589616353055,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16156644,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.310191455190377,
          42.68711978525631
        ]
      },
      azymuth: 136,
      speed: 0,
      createdOn: 1650798879586,
      createdBy: 1025514422,
      updatedOn: 1650798879627,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11355061,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.22076887986194,
          42.80558181249342
        ]
      },
      azymuth: 320,
      speed: 0,
      createdOn: 1465376984057,
      createdBy: 232915817,
      updatedOn: 1596382624822,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 7787882,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.303820099528004,
          42.66985245373212
        ]
      },
      azymuth: 189,
      createdOn: 1427650021741,
      createdBy: 224534172,
      updatedOn: 1557385381612,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 14085067,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.296834824964,
          42.685182468671
        ]
      },
      azymuth: 54,
      speed: 0,
      createdOn: 1497117286544,
      createdBy: 740239390,
      updatedOn: 1716966823372,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12032918,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.287818835819785,
          42.664877881097425
        ]
      },
      azymuth: 29,
      speed: 0,
      createdOn: 1473404210986,
      createdBy: 317937065,
      updatedOn: 1589616662231,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12032916,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.2874004112135,
          42.665368984148984
        ]
      },
      azymuth: 117,
      speed: 0,
      createdOn: 1473404160140,
      createdBy: 317937065,
      updatedOn: 1593703038600,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15885782,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.329354302763573,
          42.69529543497122
        ]
      },
      azymuth: 208,
      speed: 0,
      createdOn: 1573488500368,
      createdBy: 1025514422,
      updatedOn: 1616096245775,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15885783,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32950584757376,
          42.69514659832832
        ]
      },
      azymuth: 296,
      speed: 0,
      createdOn: 1573488516649,
      createdBy: 1025514422,
      updatedOn: 1616096245776,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16200144,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.33346698865983,
          42.67614588579249
        ]
      },
      azymuth: 64,
      speed: 0,
      createdOn: 1681499072310,
      createdBy: 1025514422,
      updatedOn: 1681499072325,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16132574,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.34359983869462,
          42.679016020231835
        ]
      },
      azymuth: 44,
      speed: 70,
      createdOn: 1630333545845,
      createdBy: 1467444148,
      updatedOn: 1671749489667,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179678,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.31113008089348,
          42.70306792949678
        ]
      },
      azymuth: 100,
      speed: 0,
      createdOn: 1671145974809,
      createdBy: 1025514422,
      updatedOn: 1671145974865,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16111580,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.415729896511973,
          42.66270250829685
        ]
      },
      azymuth: 336,
      speed: 0,
      createdOn: 1614414060789,
      createdBy: 1490035735,
      updatedOn: 1614414060820,
      updatedBy: 1490035735,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16245725,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.309677326143,
          42.698776670361
        ]
      },
      azymuth: 191,
      speed: 0,
      createdOn: 1708507962579,
      createdBy: 772798687,
      updatedOn: 1716966708747,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245724,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.309764497935,
          42.69831934253
        ]
      },
      azymuth: 13,
      speed: 0,
      createdOn: 1708507946460,
      createdBy: 772798687,
      updatedOn: 1716966708746,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8710057,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.133857353164743,
          42.60146350077437
        ]
      },
      azymuth: 266,
      createdOn: 1439451477167,
      createdBy: 36461682,
      updatedOn: 1595661339241,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16111579,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.41582109161832,
          42.66286670856652
        ]
      },
      azymuth: 253,
      speed: 0,
      createdOn: 1614414045322,
      createdBy: 1490035735,
      updatedOn: 1614414045361,
      updatedBy: 1490035735,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 12032920,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.28783224686478,
          42.66554649032178
        ]
      },
      azymuth: 216,
      speed: 0,
      createdOn: 1473404223260,
      createdBy: 317937065,
      updatedOn: 1589616668292,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16111578,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.415513978686334,
          42.66283515059085
        ]
      },
      azymuth: 83,
      speed: 0,
      createdOn: 1614414016185,
      createdBy: 1490035735,
      updatedOn: 1614414016232,
      updatedBy: 1490035735,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16153030,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.400547815800934,
          42.71986318173377
        ]
      },
      azymuth: 283,
      speed: 0,
      createdOn: 1648048062633,
      createdBy: 1025514422,
      updatedOn: 1648048062686,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16165830,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.297794757979876,
          42.70167993444713
        ]
      },
      azymuth: 15,
      speed: 0,
      createdOn: 1658751682621,
      createdBy: 1025514422,
      updatedOn: 1658751865930,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9566112,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.378190293185526,
          42.649696453511815
        ]
      },
      azymuth: 314,
      speed: 50,
      createdOn: 1448905284214,
      createdBy: 289759758,
      updatedOn: 1590743950979,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16114628,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.35484659299031,
          42.69310761739631
        ]
      },
      azymuth: 92,
      speed: 0,
      createdOn: 1616611424110,
      createdBy: 1025514422,
      updatedOn: 1616611424133,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16165831,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.298147468465935,
          42.70199235954382
        ]
      },
      azymuth: 282,
      speed: 0,
      createdOn: 1658751798928,
      createdBy: 1025514422,
      updatedOn: 1658751865929,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16114630,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.353709336367817,
          42.69053289819374
        ]
      },
      azymuth: 108,
      speed: 0,
      createdOn: 1616611496425,
      createdBy: 1025514422,
      updatedOn: 1616611496447,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16153538,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.273571293047905,
          42.763047613928066
        ]
      },
      azymuth: 55,
      speed: 0,
      createdOn: 1648496852299,
      createdBy: 19910588,
      updatedOn: 1648496852339,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9150909,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.30306060779022,
          42.67950148394453
        ]
      },
      azymuth: 251,
      speed: 0,
      createdOn: 1444830560705,
      createdBy: 29775428,
      updatedOn: 1616096373327,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16114627,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.354902499846844,
          42.69450773670379
        ]
      },
      azymuth: 91,
      speed: 0,
      createdOn: 1616611351177,
      createdBy: 1025514422,
      updatedOn: 1630614260514,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16153537,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.2733848795209,
          42.76328293189986
        ]
      },
      azymuth: 236,
      speed: 0,
      createdOn: 1648496852299,
      createdBy: 19910588,
      updatedOn: 1648496852334,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16200143,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.333981972790635,
          42.67604334422115
        ]
      },
      azymuth: 343,
      speed: 0,
      createdOn: 1681499044908,
      createdBy: 1025514422,
      updatedOn: 1681499044921,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 110904,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.339899480067192,
          42.68897256645852
        ]
      },
      azymuth: 315,
      speed: 50,
      createdOn: 1305006339000,
      createdBy: 12113631,
      updatedOn: 1668096597169,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16114633,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.351334309987,
          42.686640890147
        ]
      },
      azymuth: 127,
      speed: 0,
      createdOn: 1616612285384,
      createdBy: 1025514422,
      updatedOn: 1707632983466,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 9189810,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.321119094274074,
          42.68239115338101
        ]
      },
      azymuth: 348,
      speed: 0,
      createdOn: 1445272364771,
      createdBy: 280881818,
      updatedOn: 1662273764196,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16153032,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.40044589185823,
          42.71953606727444
        ]
      },
      azymuth: 100,
      speed: 0,
      createdOn: 1648048062633,
      createdBy: 1025514422,
      updatedOn: 1648048062688,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16165832,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.29732403029837,
          42.70198841728164
        ]
      },
      azymuth: 105,
      speed: 0,
      createdOn: 1658751865890,
      createdBy: 1025514422,
      updatedOn: 1658751865928,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16239543,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.299505382121,
          42.707196215278
        ]
      },
      azymuth: 305,
      speed: 0,
      createdOn: 1704961908424,
      createdBy: 1025514422,
      updatedOn: 1704961908445,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16141234,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.292962974204706,
          42.683149293255205
        ]
      },
      azymuth: 54,
      speed: 0,
      createdOn: 1637758705624,
      createdBy: 1025514422,
      updatedOn: 1639644393100,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16153522,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.296882974204266,
          42.76530640883064
        ]
      },
      azymuth: 116,
      speed: 0,
      createdOn: 1648482811943,
      createdBy: 19910588,
      updatedOn: 1648482811975,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16153521,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.297092186507857,
          42.76559193057204
        ]
      },
      azymuth: 295,
      speed: 0,
      createdOn: 1648482802386,
      createdBy: 19910588,
      updatedOn: 1648482802419,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16114623,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.346895801335446,
          42.683357307263904
        ]
      },
      azymuth: 309,
      speed: 0,
      createdOn: 1616609894073,
      createdBy: 1025514422,
      updatedOn: 1616610563066,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16114622,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.346782196311636,
          42.684158481108
        ]
      },
      azymuth: 308,
      speed: 0,
      createdOn: 1616609863823,
      createdBy: 1025514422,
      updatedOn: 1697920620673,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16117688,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.311902329324546,
          42.70541146935612
        ]
      },
      azymuth: 20,
      speed: 0,
      createdOn: 1618957180303,
      createdBy: 1025514422,
      updatedOn: 1618957180297,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14909874,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.274071041812576,
          42.685195487044595
        ]
      },
      azymuth: 36,
      createdOn: 1504199398135,
      createdBy: 412140089,
      updatedOn: 1590155000763,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11861479,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.11744360746592,
          42.57371916158385
        ]
      },
      azymuth: 198,
      speed: 120,
      createdOn: 1470847075643,
      createdBy: 275875686,
      updatedOn: 1571406212346,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9557450,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.37795828210535,
          42.64949424212871
        ]
      },
      azymuth: 26,
      speed: 50,
      createdOn: 1448827086829,
      createdBy: 283805131,
      updatedOn: 1590743950979,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9557453,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.378006561868062,
          42.649997303377546
        ]
      },
      azymuth: 201,
      speed: 50,
      createdOn: 1448827132413,
      createdBy: 283805131,
      updatedOn: 1590743950979,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 13044645,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.18127246378888,
          42.776147525166614
        ]
      },
      azymuth: 298,
      speed: 0,
      createdOn: 1486288381598,
      createdBy: 355271078,
      updatedOn: 1658281902049,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16204180,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.284585034393952,
          42.67854636803971
        ]
      },
      azymuth: 54,
      speed: 0,
      createdOn: 1683137300390,
      createdBy: 1025514422,
      updatedOn: 1683137300414,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16204179,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.28505509152352,
          42.67891855842562
        ]
      },
      azymuth: 229,
      speed: 0,
      createdOn: 1683137300390,
      createdBy: 1025514422,
      updatedOn: 1683137300411,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9193963,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.320344762680406,
          42.68249565872207
        ]
      },
      azymuth: 66,
      speed: 0,
      createdOn: 1445324645580,
      createdBy: 280881818,
      updatedOn: 1616096373327,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12347348,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.35786735301042,
          42.66323836864623
        ]
      },
      azymuth: 316,
      speed: 0,
      createdOn: 1478184809757,
      createdBy: 317937065,
      updatedOn: 1701788778145,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9524730,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.337495259399905,
          42.690242565101826
        ]
      },
      azymuth: 49,
      speed: 50,
      createdOn: 1448531254988,
      createdBy: 198110449,
      updatedOn: 1589697740796,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12347344,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.357161932039205,
          42.66346321741857
        ]
      },
      azymuth: 136,
      speed: 0,
      createdOn: 1478184773114,
      createdBy: 317937065,
      updatedOn: 1589702460014,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16267146,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.35105108575211,
          42.625943433637346
        ]
      },
      azymuth: 0,
      speed: 80,
      createdOn: 1723738362429,
      createdBy: 1206041330,
      updatedOn: 1723738362439,
      updatedBy: 1206041330,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16204175,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.302694395061295,
          42.68823876336009
        ]
      },
      azymuth: 58,
      speed: 0,
      createdOn: 1683135606937,
      createdBy: 1025514422,
      updatedOn: 1683135606950,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16140175,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.311694657941587,
          42.705466007621574
        ]
      },
      azymuth: 203,
      speed: 0,
      createdOn: 1636447043262,
      createdBy: 1025514422,
      updatedOn: 1636447043335,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16204174,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.302869409199108,
          42.688428280033676
        ]
      },
      azymuth: 232,
      speed: 0,
      createdOn: 1683135569710,
      createdBy: 1025514422,
      updatedOn: 1683135569724,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10436067,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.310441938739174,
          42.69995203526808
        ]
      },
      azymuth: 283,
      speed: 0,
      createdOn: 1456588172608,
      createdBy: 311347412,
      updatedOn: 1621184916301,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16182132,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.37308778573215,
          42.6654115398781
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1673529838636,
      createdBy: 1025514422,
      updatedOn: 1673529838653,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16182133,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.37298049737126,
          42.665218254934985
        ]
      },
      azymuth: 133,
      speed: 0,
      createdOn: 1673529838636,
      createdBy: 1025514422,
      updatedOn: 1673529838656,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16182129,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.37596445489819,
          42.67475654430618
        ]
      },
      azymuth: 46,
      speed: 0,
      createdOn: 1673529590680,
      createdBy: 1025514422,
      updatedOn: 1673529590702,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2943395,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32976494019715,
          42.69813495502764
        ]
      },
      azymuth: 82,
      speed: 0,
      createdOn: 1377007470380,
      createdBy: 152994439,
      updatedOn: 1627217748603,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16218983,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.152290648285064,
          42.67787132078611
        ]
      },
      azymuth: 10,
      speed: 0,
      createdOn: 1691619673115,
      createdBy: 1025514422,
      updatedOn: 1691619673132,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 261531,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.311494633554624,
          42.70565833946451
        ]
      },
      azymuth: 95,
      speed: 0,
      createdOn: 1322462323000,
      createdBy: 14929248,
      updatedOn: 1618957236171,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16083308,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32967787655,
          42.688954460064
        ]
      },
      azymuth: 130,
      speed: 0,
      createdOn: 1600591887932,
      createdBy: 342108264,
      updatedOn: 1723390149042,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16266084,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.270073630236304,
          42.64875961460921
        ]
      },
      azymuth: 266,
      speed: 0,
      createdOn: 1723313115513,
      createdBy: 1025514422,
      updatedOn: 1723313115528,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9290003,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.318730072854358,
          42.682042154527835
        ]
      },
      azymuth: 69,
      speed: 0,
      createdOn: 1446216594962,
      createdBy: 283164410,
      updatedOn: 1616096373327,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16218984,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.152243039075188,
          42.67787427863133
        ]
      },
      azymuth: 190,
      speed: 0,
      createdOn: 1691619673115,
      createdBy: 1025514422,
      updatedOn: 1691619673135,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9286445,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.35730811243031,
          42.66309044137923
        ]
      },
      azymuth: 63,
      createdOn: 1446193705742,
      createdBy: 282537716,
      updatedOn: 1589702460015,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9449264,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.321526854885292,
          42.67875338562589
        ]
      },
      azymuth: 169,
      speed: 50,
      createdOn: 1447771582300,
      createdBy: 286359157,
      updatedOn: 1589616353055,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 13622126,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.367043865633345,
          42.850346466344966
        ]
      },
      azymuth: 133,
      createdOn: 1492338896621,
      createdBy: 164222588,
      updatedOn: 1589986499061,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 13622125,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.373774399553014,
          42.95172499306705
        ]
      },
      azymuth: 125,
      createdOn: 1492337854547,
      createdBy: 164222588,
      updatedOn: 1595604622687,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16155988,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.338999710512237,
          42.702038650356066
        ]
      },
      azymuth: 118,
      speed: 0,
      createdOn: 1650284198530,
      createdBy: 394848275,
      updatedOn: 1650284198564,
      updatedBy: 394848275,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 13622124,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.35896601991453,
          42.978633600833945
        ]
      },
      azymuth: 205,
      createdOn: 1492337441370,
      createdBy: 164222588,
      updatedOn: 1527705294903,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 9526070,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.336969546432737,
          42.690768954517154
        ]
      },
      azymuth: 138,
      speed: 50,
      createdOn: 1448543837382,
      createdBy: 282597614,
      updatedOn: 1589697740795,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15881052,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334901069354817,
          42.69854332253171
        ]
      },
      azymuth: 348,
      speed: 0,
      createdOn: 1565118172651,
      createdBy: 1025514422,
      updatedOn: 1590154803284,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16084315,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.35435863605914,
          42.69681933400089
        ]
      },
      azymuth: 96,
      speed: 0,
      createdOn: 1600801461891,
      createdBy: 1025514422,
      updatedOn: 1600801461867,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15881053,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334540680241286,
          42.69883508071453
        ]
      },
      azymuth: 169,
      speed: 0,
      createdOn: 1565118192732,
      createdBy: 1025514422,
      updatedOn: 1589697263209,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10500880,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.315512570257123,
          42.69774125129541
        ]
      },
      azymuth: 282,
      speed: 50,
      createdOn: 1457251818164,
      createdBy: 297009941,
      updatedOn: 1591087114165,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10500881,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.3151316965772,
          42.69763775973084
        ]
      },
      azymuth: 101,
      speed: 50,
      createdOn: 1457252010625,
      createdBy: 297009941,
      updatedOn: 1591087114166,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9071416,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.334213001672644,
          42.68820946919299
        ]
      },
      azymuth: 43,
      speed: 50,
      createdOn: 1443800681675,
      createdBy: 193666435,
      updatedOn: 1557496840110,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16132932,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.34736549251473,
          42.69904369089113
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1630614232032,
      createdBy: 1025514422,
      updatedOn: 1630614232070,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16002377,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.313421531485087,
          42.78076291488966
        ]
      },
      azymuth: 236,
      speed: 0,
      createdOn: 1589614573080,
      createdBy: 19910588,
      updatedOn: 1589643129297,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 9418575,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.377714201085844,
          42.649745773261365
        ]
      },
      azymuth: 131,
      speed: 50,
      createdOn: 1447450200670,
      createdBy: 282597614,
      updatedOn: 1590743950978,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16179508,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.301619169700817,
          42.68458356672352
        ]
      },
      azymuth: 250,
      speed: 0,
      createdOn: 1671010204725,
      createdBy: 1025514422,
      updatedOn: 1671010204789,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11237224,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.24140888452461,
          42.7384351402113
        ]
      },
      azymuth: 308,
      createdOn: 1464177169921,
      createdBy: 240303958,
      updatedOn: 1589869773090,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179506,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.30034435683284,
          42.68719229514446
        ]
      },
      azymuth: 233,
      speed: 0,
      createdOn: 1671009582967,
      createdBy: 1025514422,
      updatedOn: 1671009640511,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179507,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.299559810695506,
          42.68660869847524
        ]
      },
      azymuth: 55,
      speed: 0,
      createdOn: 1671009640445,
      createdBy: 1025514422,
      updatedOn: 1671009640510,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12029297,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.347504480951446,
          42.659746024139274
        ]
      },
      azymuth: 151,
      speed: 0,
      createdOn: 1473360961869,
      createdBy: 317937065,
      updatedOn: 1590743847880,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12286845,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.29736664476118,
          42.700296342775445
        ]
      },
      azymuth: 11,
      speed: 0,
      createdOn: 1477379729011,
      createdBy: 308881359,
      updatedOn: 1600414911763,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16139569,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.342298838892262,
          42.700559100436074
        ]
      },
      azymuth: 355,
      speed: 0,
      createdOn: 1635842522816,
      createdBy: 1025514422,
      updatedOn: 1635842522856,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9096537,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.35010870743483,
          42.685702802180614
        ]
      },
      azymuth: 129,
      speed: 50,
      createdOn: 1444148278984,
      createdBy: 274302585,
      updatedOn: 1589617625397,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12029294,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.347971799633,
          42.659481725568
        ]
      },
      azymuth: 336,
      speed: 0,
      createdOn: 1473360932464,
      createdBy: 317937065,
      updatedOn: 1723370197360,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11336562,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.22059587738083,
          42.8054834204497
        ]
      },
      azymuth: 142,
      createdOn: 1465146030510,
      createdBy: 232915817,
      updatedOn: 1596382624823,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16144680,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.314006192625243,
          42.6898618003343
        ]
      },
      azymuth: 191,
      speed: 0,
      createdOn: 1641210368533,
      createdBy: 1025514422,
      updatedOn: 1641210368570,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16257831,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.297173076727862,
          42.68547512554162
        ]
      },
      azymuth: 233,
      speed: 0,
      createdOn: 1716966876983,
      createdBy: 1025514422,
      updatedOn: 1716966876998,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15887123,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32376507963546,
          42.68431068975087
        ]
      },
      azymuth: 247,
      speed: 0,
      createdOn: 1576132773111,
      createdBy: 1025514422,
      updatedOn: 1667893434157,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 425445,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.328931629657802,
          42.688259701543984
        ]
      },
      azymuth: 221,
      speed: 0,
      createdOn: 1330521057000,
      createdBy: 17447101,
      updatedOn: 1620497978034,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12381017,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.448716886285,
          42.712127517071
        ]
      },
      azymuth: 171,
      speed: 0,
      createdOn: 1478618571741,
      createdBy: 355271078,
      updatedOn: 1721898562243,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9177962,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.3020708726636,
          42.67910415586846
        ]
      },
      azymuth: 73,
      speed: 0,
      createdOn: 1445169633679,
      createdBy: 280881818,
      updatedOn: 1616096373328,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16175391,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.32380950850978,
          42.683759793443784
        ]
      },
      azymuth: 64,
      speed: 0,
      createdOn: 1667893218747,
      createdBy: 19910588,
      updatedOn: 1699641797547,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16240925,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          23.29893814197,
          42.707213159442
        ]
      },
      azymuth: 127,
      speed: 0,
      createdOn: 1705740430598,
      createdBy: 1025514422,
      updatedOn: 1705740430611,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15888210,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.236018474901755,
          43.433039844025146
        ]
      },
      azymuth: 147,
      speed: 0,
      createdOn: 1577980631771,
      createdBy: 772798687,
      updatedOn: 1604044345771,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534220,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.44950209325409,
          43.268918516605005
        ]
      },
      azymuth: 114,
      createdOn: 1467370666184,
      createdBy: 164222588,
      updatedOn: 1494260611330,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15888209,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.23620086511445,
          43.43312944101464
        ]
      },
      azymuth: 329,
      speed: 0,
      createdOn: 1577980631771,
      createdBy: 772798687,
      updatedOn: 1604044345771,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534219,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.397752705291,
          43.299645675779
        ]
      },
      azymuth: 143,
      createdOn: 1467370414451,
      createdBy: 164222588,
      updatedOn: 1721416050066,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534218,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.306120456859954,
          43.37152607357117
        ]
      },
      azymuth: 153,
      createdOn: 1467369955748,
      createdBy: 164222588,
      updatedOn: 1589435898423,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11534217,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.269489056309983,
          43.39292550360413
        ]
      },
      azymuth: 137,
      speed: 90,
      createdOn: 1467369945276,
      createdBy: 164222588,
      updatedOn: 1697204017291,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11228833,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.449514899475098,
          43.26896175741154
        ]
      },
      azymuth: 295,
      createdOn: 1464093198200,
      createdBy: 326266093,
      updatedOn: 1710667270221,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369472,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.0571436360539,
          43.406919023389825
        ]
      },
      azymuth: 73,
      speed: 0,
      createdOn: 1508396640071,
      createdBy: 19910588,
      updatedOn: 1662186913298,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11178790,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.397727224305,
          43.299674956522
        ]
      },
      azymuth: 324,
      createdOn: 1463593599780,
      createdBy: 262197506,
      updatedOn: 1721416050066,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11592317,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.26945821090568,
          43.39294791770546
        ]
      },
      azymuth: 317,
      speed: 90,
      createdOn: 1467972172767,
      createdBy: 164222588,
      updatedOn: 1697204017292,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11531169,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.10174461099738,
          43.44577110851156
        ]
      },
      azymuth: 126,
      createdOn: 1467360858794,
      createdBy: 164222588,
      updatedOn: 1589894836378,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 13767682,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.236608035713086,
          43.44127162953867
        ]
      },
      azymuth: 201,
      speed: 60,
      createdOn: 1493720118787,
      createdBy: 164222588,
      updatedOn: 1589894576146,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11326456,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.306101681396783,
          43.37155434480514
        ]
      },
      azymuth: 334,
      createdOn: 1465046444805,
      createdBy: 232915817,
      updatedOn: 1589435898423,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15369470,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.153349109001127,
          43.22923895940566
        ]
      },
      azymuth: 320,
      speed: 0,
      createdOn: 1508396590806,
      createdBy: 19910588,
      updatedOn: 1508396590777,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8887602,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.205123656485227,
          43.41407612928207
        ]
      },
      azymuth: 292,
      speed: 50,
      createdOn: 1441437413238,
      createdBy: 244303914,
      updatedOn: 1649957863600,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11541541,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.1017124244892,
          43.4457925293832
        ]
      },
      azymuth: 303,
      speed: 0,
      createdOn: 1467459393460,
      createdBy: 164222588,
      updatedOn: 1589894836379,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8898801,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.2051558429934,
          43.414066387419744
        ]
      },
      azymuth: 108,
      speed: 50,
      createdOn: 1441550944295,
      createdBy: 221686040,
      updatedOn: 1649957863601,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16154222,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.122963712646122,
          43.1203207525165
        ]
      },
      azymuth: 63,
      speed: 0,
      createdOn: 1649170563361,
      createdBy: 19910588,
      updatedOn: 1649170563397,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11355060,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.24040279076167,
          43.39552295482094
        ]
      },
      azymuth: 356,
      speed: 60,
      createdOn: 1465376984057,
      createdBy: 232915817,
      updatedOn: 1598901994514,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11336561,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.24041140244674,
          43.39548811293139
        ]
      },
      azymuth: 176,
      speed: 60,
      createdOn: 1465140813330,
      createdBy: 232915817,
      updatedOn: 1598901994515,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13816271,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.236771650462607,
          43.44121125709749
        ]
      },
      azymuth: 22,
      speed: 0,
      createdOn: 1494264326695,
      createdBy: 164222588,
      updatedOn: 1673813224608,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13767681,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.234700985176683,
          43.521496369911006
        ]
      },
      azymuth: 146,
      createdOn: 1493719790961,
      createdBy: 164222588,
      updatedOn: 1682952900873,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369481,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.027643924148506,
          43.6219033759121
        ]
      },
      azymuth: 168,
      speed: 0,
      createdOn: 1508396739895,
      createdBy: 19910588,
      updatedOn: 1589993258936,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 16246034,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.2502106253694,
          43.65803888918409
        ]
      },
      azymuth: 355,
      speed: 0,
      createdOn: 1708761026257,
      createdBy: 19910588,
      updatedOn: 1708761026270,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16246033,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.250211292685,
          43.658023360241
        ]
      },
      azymuth: 174,
      speed: 0,
      createdOn: 1708760991632,
      createdBy: 19910588,
      updatedOn: 1708761026266,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13775080,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.23467416308579,
          43.52152359868467
        ]
      },
      azymuth: 323,
      speed: 0,
      createdOn: 1493829612222,
      createdBy: 164222588,
      updatedOn: 1682952900874,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11690265,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.360084607889,
          43.515248096052
        ]
      },
      azymuth: 224,
      createdOn: 1468945244312,
      createdBy: 164222588,
      updatedOn: 1723307615159,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11791948,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.360217377235,
          43.515154730496
        ]
      },
      azymuth: 43,
      speed: 0,
      createdOn: 1470143998800,
      createdBy: 164222588,
      updatedOn: 1723307615159,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16077397,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.394826786071587,
          44.57913325961054
        ]
      },
      azymuth: 290,
      speed: 0,
      createdOn: 1598267002731,
      createdBy: 11225731,
      updatedOn: 1598267002711,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16077396,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.356240402785573,
          44.58494585408645
        ]
      },
      azymuth: 281,
      speed: 0,
      createdOn: 1598266837686,
      createdBy: 11225731,
      updatedOn: 1598266837681,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15868305,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.225428719569056,
          44.97097547204827
        ]
      },
      azymuth: 232,
      speed: 0,
      createdOn: 1540647282777,
      createdBy: 11225731,
      updatedOn: 1540647282793,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16077395,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.05572640135073,
          44.589739781729236
        ]
      },
      azymuth: 89,
      speed: 0,
      createdOn: 1598266596098,
      createdBy: 11225731,
      updatedOn: 1598266596088,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9635313,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.407087,
          45.03335
        ]
      },
      azymuth: 109,
      createdOn: 1449590358420,
      createdBy: 272380749,
      updatedOn: 1503893767377,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11721939,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.526316036862493,
          41.88850088955589
        ]
      },
      azymuth: 94,
      speed: 0,
      createdOn: 1469377041639,
      createdBy: 164222588,
      updatedOn: 1605121203180,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11728659,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.50396853368566,
          41.83267722829026
        ]
      },
      azymuth: 297,
      createdOn: 1469356444596,
      createdBy: 164222588,
      updatedOn: 1595616014506,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11719766,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.5262758037266,
          41.88850787821065
        ]
      },
      azymuth: 276,
      createdOn: 1469263531969,
      createdBy: 164222588,
      updatedOn: 1605121203179,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16133586,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.895614737931687,
          41.58182625556889
        ]
      },
      azymuth: 178,
      speed: 90,
      createdOn: 1631198040651,
      createdBy: 873600165,
      updatedOn: 1631198040692,
      updatedBy: 873600165,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16133587,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.895614830954955,
          41.58182421738137
        ]
      },
      azymuth: 0,
      speed: 90,
      createdOn: 1631198076319,
      createdBy: 873600165,
      updatedOn: 1631198076351,
      updatedBy: 873600165,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16204642,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.742881086273524,
          41.58713104325355
        ]
      },
      azymuth: 0,
      speed: 50,
      createdOn: 1683469675601,
      createdBy: 1559831808,
      updatedOn: 1683469805098,
      updatedBy: 1559831808,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16267389,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.606966713039434,
          41.96852360728399
        ]
      },
      azymuth: 16,
      speed: 0,
      createdOn: 1723991674516,
      createdBy: 19910588,
      updatedOn: 1723991674525,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267390,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.606951960889663,
          41.96850067334267
        ]
      },
      azymuth: 201,
      speed: 0,
      createdOn: 1723991674516,
      createdBy: 19910588,
      updatedOn: 1723991674527,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15355405,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.737532993908562,
          41.62134034774382
        ]
      },
      azymuth: 13,
      speed: 0,
      createdOn: 1508251336990,
      createdBy: 19910588,
      updatedOn: 1595616501777,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15355429,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.781483782694,
          41.534720560581
        ]
      },
      azymuth: 332,
      speed: 0,
      createdOn: 1508251521638,
      createdBy: 19910588,
      updatedOn: 1723550792699,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 4631494,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.852100667641093,
          42.43175945285912
        ]
      },
      azymuth: 162,
      speed: 140,
      createdOn: 1397298519126,
      createdBy: 17724506,
      updatedOn: 1589694433215,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16257208,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.619767780502492,
          42.3531062257644
        ]
      },
      azymuth: 63,
      speed: 0,
      createdOn: 1716389470954,
      createdBy: 19910588,
      updatedOn: 1716389544744,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16152594,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.563208689904705,
          42.3057222091175
        ]
      },
      azymuth: 152,
      speed: 0,
      createdOn: 1647563472857,
      createdBy: 1025514422,
      updatedOn: 1647563472902,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 12012688,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.52371161380797,
          42.33964854976452
        ]
      },
      azymuth: 95,
      speed: 0,
      createdOn: 1473150745583,
      createdBy: 164222588,
      updatedOn: 1596008380634,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16152593,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.56322203336136,
          42.3057270160195
        ]
      },
      azymuth: 333,
      speed: 0,
      createdOn: 1647563472857,
      createdBy: 1025514422,
      updatedOn: 1647563472899,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16167583,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.854415840038126,
          42.4270759443752
        ]
      },
      azymuth: 346,
      speed: 0,
      createdOn: 1660141962303,
      createdBy: 1025514422,
      updatedOn: 1660141962344,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 11997948,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.52361505428344,
          42.33966837571678
        ]
      },
      azymuth: 277,
      createdOn: 1472976179117,
      createdBy: 164222588,
      updatedOn: 1596008380633,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11997947,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.535603646528838,
          42.41640895684352
        ]
      },
      azymuth: 171,
      createdOn: 1472975685214,
      createdBy: 164222588,
      updatedOn: 1604494751527,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16082553,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.87644650684467,
          42.379384291275116
        ]
      },
      azymuth: 356,
      speed: 0,
      createdOn: 1600344577392,
      createdBy: 1025514422,
      updatedOn: 1600344577363,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10525358,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.923493494373286,
          42.35237878176123
        ]
      },
      azymuth: 134,
      speed: 80,
      createdOn: 1457518636194,
      createdBy: 305796512,
      updatedOn: 1492536384348,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16082554,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.876368722783326,
          42.37937636590555
        ]
      },
      azymuth: 175,
      speed: 0,
      createdOn: 1600344577392,
      createdBy: 1025514422,
      updatedOn: 1600344577366,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10736979,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.92363639428387,
          42.35248421015888
        ]
      },
      azymuth: 314,
      speed: 80,
      createdOn: 1459373589087,
      createdBy: 193666435,
      updatedOn: 1595926038241,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12012679,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.53559425879725,
          42.41645450081752
        ]
      },
      azymuth: 349,
      speed: 0,
      createdOn: 1473150645683,
      createdBy: 164222588,
      updatedOn: 1604494751526,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16167584,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.854100680478417,
          42.42701011477107
        ]
      },
      azymuth: 163,
      speed: 0,
      createdOn: 1660141975772,
      createdBy: 1025514422,
      updatedOn: 1660141975808,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 16131052,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.986539223619356,
          42.22573894697686
        ]
      },
      azymuth: 120,
      speed: 0,
      createdOn: 1628946051173,
      createdBy: 1025514422,
      updatedOn: 1628946051209,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16131053,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.986515083738002,
          42.22570617494596
        ]
      },
      azymuth: 298,
      speed: 0,
      createdOn: 1628946051173,
      createdBy: 1025514422,
      updatedOn: 1628946051212,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245617,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.58159982719967,
          42.60826414514843
        ]
      },
      azymuth: 289,
      speed: 0,
      createdOn: 1708445270750,
      createdBy: 1025514422,
      updatedOn: 1708445270771,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10692324,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.73061301065929,
          42.985133020824634
        ]
      },
      azymuth: 148,
      createdOn: 1458999514200,
      createdBy: 10631541,
      updatedOn: 1661582822599,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10804780,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.826580396436,
          42.894861631709
        ]
      },
      azymuth: 197,
      speed: 0,
      createdOn: 1460053782915,
      createdBy: 19910588,
      updatedOn: 1705581994754,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16163902,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.528022268394572,
          42.72521948602108
        ]
      },
      azymuth: 268,
      speed: 0,
      createdOn: 1657182583543,
      createdBy: 1025514422,
      updatedOn: 1657182583582,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16163903,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.52775874135816,
          42.72497367891742
        ]
      },
      azymuth: 90,
      speed: 0,
      createdOn: 1657182583543,
      createdBy: 1025514422,
      updatedOn: 1657182583586,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10558513,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.657504541143865,
          42.57395857347625
        ]
      },
      azymuth: 327,
      speed: 140,
      createdOn: 1457813017863,
      createdBy: 193666435,
      updatedOn: 1589694617912,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10734771,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.702677191018253,
          42.55040008367582
        ]
      },
      azymuth: 126,
      speed: 140,
      createdOn: 1459353331315,
      createdBy: 193666435,
      updatedOn: 1633770492434,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11184890,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.796656618385416,
          42.831680623280896
        ]
      },
      azymuth: 179,
      speed: 90,
      createdOn: 1463672723738,
      createdBy: 193666435,
      updatedOn: 1589784073025,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10558517,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.657241684660278,
          42.57378870680076
        ]
      },
      azymuth: 148,
      speed: 140,
      createdOn: 1457813053470,
      createdBy: 193666435,
      updatedOn: 1589694617912,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11184894,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.796659300594058,
          42.83174750154696
        ]
      },
      azymuth: 0,
      speed: 90,
      createdOn: 1463672740154,
      createdBy: 193666435,
      updatedOn: 1589784073025,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10619511,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.73059088243464,
          42.985125172352255
        ]
      },
      azymuth: 329,
      createdOn: 1458371999364,
      createdBy: 285337515,
      updatedOn: 1661582822598,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11264562,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.994858231795103,
          42.67136747745548
        ]
      },
      azymuth: 109,
      speed: 60,
      createdOn: 1464418041536,
      createdBy: 317130311,
      updatedOn: 1671385150846,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10794180,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.826848617336,
          42.89480857677
        ]
      },
      azymuth: 14,
      speed: 0,
      createdOn: 1459932348473,
      createdBy: 19910588,
      updatedOn: 1705581994753,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10804800,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.581611893858,
          42.608287829562
        ]
      },
      azymuth: 112,
      speed: 0,
      createdOn: 1460097724581,
      createdBy: 282963361,
      updatedOn: 1708445270765,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2265995,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.99483744467522,
          42.67137092863649
        ]
      },
      azymuth: 289,
      speed: 60,
      createdOn: 1369222329356,
      createdBy: 36461682,
      updatedOn: 1671385150846,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11573785,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.76913309227878,
          42.94524770346469
        ]
      },
      azymuth: 327,
      createdOn: 1467786276681,
      createdBy: 344396491,
      updatedOn: 1593702245377,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15274948,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.770640197935904,
          42.72227385476808
        ]
      },
      azymuth: 61,
      createdOn: 1507475716550,
      createdBy: 791043681,
      updatedOn: 1640276051672,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13360062,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.803507732925,
          42.879087255244
        ]
      },
      azymuth: 222,
      speed: 120,
      createdOn: 1489504157087,
      createdBy: 164222588,
      updatedOn: 1723310431328,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10878173,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.584079,
          42.62288
        ]
      },
      azymuth: 341,
      speed: 50,
      createdOn: 1460725629973,
      createdBy: 285430962,
      updatedOn: 1634018287249,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15884747,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.735980793144627,
          42.77866910995812
        ]
      },
      azymuth: 82,
      speed: 0,
      createdOn: 1571723089738,
      createdBy: 280548302,
      updatedOn: 1571726426570,
      updatedBy: 54023336,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 15884750,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.877700876425635,
          42.90780963419735
        ]
      },
      azymuth: 262,
      speed: 0,
      createdOn: 1571723364829,
      createdBy: 280548302,
      updatedOn: 1589784437326,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534225,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.76917868983195,
          42.94520450885462
        ]
      },
      azymuth: 142,
      createdOn: 1467373321742,
      createdBy: 164222588,
      updatedOn: 1593702245376,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15884748,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.73614262238152,
          42.77879434361761
        ]
      },
      azymuth: 262,
      speed: 0,
      createdOn: 1571723105716,
      createdBy: 280548302,
      updatedOn: 1571726426571,
      updatedBy: 54023336,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 10736988,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.702846170186845,
          42.5505897733557
        ]
      },
      azymuth: 304,
      speed: 140,
      createdOn: 1459373679249,
      createdBy: 193666435,
      updatedOn: 1633770492436,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15884749,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.877765249441985,
          42.907538520970824
        ]
      },
      azymuth: 78,
      speed: 0,
      createdOn: 1571723349935,
      createdBy: 280548302,
      updatedOn: 1589784437326,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534223,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.573734783956276,
          43.24095817146157
        ]
      },
      azymuth: 111,
      createdOn: 1467371237663,
      createdBy: 164222588,
      updatedOn: 1593707512169,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534222,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.56520310074012,
          43.24340338615555
        ]
      },
      azymuth: 111,
      createdOn: 1467371185716,
      createdBy: 164222588,
      updatedOn: 1593707481918,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534221,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.51684110150454,
          43.24562730935764
        ]
      },
      azymuth: 57,
      createdOn: 1467371011402,
      createdBy: 164222588,
      updatedOn: 1710667055654,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11592274,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.565146774350588,
          43.243422924699914
        ]
      },
      azymuth: 291,
      speed: 0,
      createdOn: 1467971754227,
      createdBy: 164222588,
      updatedOn: 1593707481917,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16258586,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.736621881456,
          43.431849532279
        ]
      },
      azymuth: 250,
      speed: 0,
      createdOn: 1717511416810,
      createdBy: 19910588,
      updatedOn: 1717511459072,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16258587,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.736658381678307,
          43.43185871923928
        ]
      },
      azymuth: 75,
      speed: 0,
      createdOn: 1717511448630,
      createdBy: 19910588,
      updatedOn: 1717511459072,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11592248,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.573686504194463,
          43.240973802924806
        ]
      },
      azymuth: 290,
      speed: 0,
      createdOn: 1467971570391,
      createdBy: 164222588,
      updatedOn: 1593707512168,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11573786,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.688271242264847,
          43.14292228952642
        ]
      },
      azymuth: 294,
      createdOn: 1467787925766,
      createdBy: 344396491,
      updatedOn: 1638116616347,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16181436,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.663151769200763,
          43.30014399643647
        ]
      },
      azymuth: 38,
      speed: 0,
      createdOn: 1672768921181,
      createdBy: 1591212739,
      updatedOn: 1672768921192,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16177981,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.559605734375555,
          43.22309317949422
        ]
      },
      azymuth: 12,
      speed: 40,
      createdOn: 1669388128391,
      createdBy: 1025514422,
      updatedOn: 1669388128455,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16181437,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.66304448083988,
          43.30019670133222
        ]
      },
      azymuth: 215,
      speed: 0,
      createdOn: 1672768921181,
      createdBy: 1591212739,
      updatedOn: 1672768921194,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 11686814,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.603464343031,
          43.264072641007
        ]
      },
      azymuth: 48,
      createdOn: 1468908300708,
      createdBy: 164222588,
      updatedOn: 1723316816641,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16169895,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.77569572374,
          43.428560253468
        ]
      },
      azymuth: 261,
      speed: 0,
      createdOn: 1661958466800,
      createdBy: 358960284,
      updatedOn: 1723305812630,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11689767,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.60328372875397,
          43.26417259484771
        ]
      },
      azymuth: 227,
      speed: 0,
      createdOn: 1469008088468,
      createdBy: 164222588,
      updatedOn: 1593709573987,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 876917,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.60886444437852,
          43.17424702070252
        ]
      },
      azymuth: 114,
      speed: 90,
      createdOn: 1345364041000,
      createdBy: 23370356,
      updatedOn: 1593703549478,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16199727,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.585677814738524,
          43.18506952422628
        ]
      },
      azymuth: 118,
      speed: 50,
      createdOn: 1681411351457,
      createdBy: 772798687,
      updatedOn: 1681411351470,
      updatedBy: 772798687,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16199726,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          23.585650126502344,
          43.18507997405736
        ]
      },
      azymuth: 299,
      speed: 50,
      createdOn: 1681411322892,
      createdBy: 772798687,
      updatedOn: 1681411322902,
      updatedBy: 772798687,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11326455,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.516807573891597,
          43.245610702196224
        ]
      },
      azymuth: 238,
      createdOn: 1465045382182,
      createdBy: 232915817,
      updatedOn: 1710667055655,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11534224,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.688196140412135,
          43.142789208325084
        ]
      },
      azymuth: 113,
      createdOn: 1467371918200,
      createdBy: 164222588,
      updatedOn: 1638116616345,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15372602,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.798931858798152,
          43.535978316806236
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1508422258028,
      createdBy: 19910588,
      updatedOn: 1604413771068,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16246539,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.72613990307,
          43.778986403384
        ]
      },
      azymuth: 119,
      speed: 0,
      createdOn: 1708951927414,
      createdBy: 164222588,
      updatedOn: 1708951927430,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16246538,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.726182818413,
          43.779071613395
        ]
      },
      azymuth: 306,
      speed: 0,
      createdOn: 1708951927414,
      createdBy: 164222588,
      updatedOn: 1708951927427,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16094715,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.81812457691454,
          43.71522556798109
        ]
      },
      azymuth: 315,
      speed: 0,
      createdOn: 1604420500910,
      createdBy: 19910588,
      updatedOn: 1604420500943,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 6457271,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.980964,
          44.32752
        ]
      },
      azymuth: 247,
      createdOn: 1414910640547,
      createdBy: 211848913,
      updatedOn: 1504684679488,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 6740079,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          23.933631,
          44.233325
        ]
      },
      azymuth: 151,
      createdOn: 1417713099219,
      createdBy: 214312623,
      updatedOn: 1513153467917,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10935996,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.03768897916726,
          41.11725791178277
        ]
      },
      azymuth: 29,
      speed: 50,
      createdOn: 1461269627200,
      createdBy: 20650508,
      updatedOn: 1461269627154,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 10935834,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.158090407410004,
          41.14495321889654
        ]
      },
      azymuth: 269,
      speed: 0,
      createdOn: 1461268695748,
      createdBy: 20650508,
      updatedOn: 1461268695730,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 10935922,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.246855072422044,
          41.064732320523554
        ]
      },
      azymuth: 335,
      speed: 50,
      createdOn: 1461269164720,
      createdBy: 20650508,
      updatedOn: 1461269164686,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 10936080,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.049285364418218,
          41.12390333105297
        ]
      },
      azymuth: 236,
      speed: 50,
      createdOn: 1461269987926,
      createdBy: 20650508,
      updatedOn: 1461269987951,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 10935897,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.237777201223214,
          41.0773995791366
        ]
      },
      azymuth: 150,
      speed: 50,
      createdOn: 1461268986430,
      createdBy: 20650508,
      updatedOn: 1501846911384,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 10935953,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.199967492065436,
          41.12611705107256
        ]
      },
      azymuth: 336,
      speed: 50,
      createdOn: 1461269356373,
      createdBy: 20650508,
      updatedOn: 1461269356334,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11395478,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.208246881029318,
          41.93905636255333
        ]
      },
      azymuth: 222,
      speed: 0,
      createdOn: 1465835898368,
      createdBy: 164222588,
      updatedOn: 1662919755139,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11211180,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.208463682208947,
          41.93890920240091
        ]
      },
      azymuth: 46,
      createdOn: 1463912563882,
      createdBy: 164222588,
      updatedOn: 1469593527846,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11430617,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.423870791975027,
          41.7342107676996
        ]
      },
      azymuth: 164,
      createdOn: 1466244405280,
      createdBy: 164222588,
      updatedOn: 1605205971210,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11442225,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.42386810976549,
          41.73425880545582
        ]
      },
      azymuth: 345,
      speed: 0,
      createdOn: 1466366253048,
      createdBy: 164222588,
      updatedOn: 1605205971210,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16078130,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.443345957030605,
          41.910430177061436
        ]
      },
      azymuth: 60,
      speed: 0,
      createdOn: 1598467754983,
      createdBy: 19910588,
      updatedOn: 1598467755123,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10793508,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.313022203649798,
          42.246431432015
        ]
      },
      azymuth: 9,
      speed: 0,
      createdOn: 1459924364295,
      createdBy: 19910588,
      updatedOn: 1603702821428,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 1920796,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.27760354206815,
          42.27814864900808
        ]
      },
      azymuth: 294,
      speed: 140,
      createdOn: 1364652235609,
      createdBy: 19779946,
      updatedOn: 1669455914250,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10556098,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.164071975532615,
          42.313108894756255
        ]
      },
      azymuth: 106,
      speed: 140,
      createdOn: 1457795592520,
      createdBy: 193666435,
      updatedOn: 1511806417093,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10558114,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.16409884655756,
          42.313178337247635
        ]
      },
      azymuth: 287,
      speed: 140,
      createdOn: 1457808136663,
      createdBy: 193666435,
      updatedOn: 1507956034563,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10736967,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.23229427888198,
          42.29507557591907
        ]
      },
      azymuth: 301,
      speed: 0,
      createdOn: 1459373480219,
      createdBy: 193666435,
      updatedOn: 1667240457980,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16142482,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.211805138183763,
          42.16421874063509
        ]
      },
      azymuth: 73,
      speed: 50,
      createdOn: 1639147134359,
      createdBy: 19910588,
      updatedOn: 1639147134402,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16132691,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.30711247706011,
          42.149649002208534
        ]
      },
      azymuth: 210,
      speed: 0,
      createdOn: 1630424989499,
      createdBy: 1025514422,
      updatedOn: 1630424989537,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13824084,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.17601080020177,
          42.187422194854044
        ]
      },
      azymuth: 281,
      speed: 50,
      createdOn: 1494350744088,
      createdBy: 164222588,
      updatedOn: 1654421063490,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11347682,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.272282489714,
          42.416091882354
        ]
      },
      azymuth: 330,
      speed: 0,
      createdOn: 1465284380134,
      createdBy: 164222588,
      updatedOn: 1723306730631,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16039141,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.413154914321748,
          42.185605534484225
        ]
      },
      azymuth: 274,
      speed: 0,
      createdOn: 1592659999156,
      createdBy: 19910588,
      updatedOn: 1615187850824,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11721958,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.07075842838302,
          42.0353428828926
        ]
      },
      azymuth: 39,
      speed: 0,
      createdOn: 1469377201151,
      createdBy: 164222588,
      updatedOn: 1639149079705,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10734772,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.232058253967264,
          42.29503861605394
        ]
      },
      azymuth: 120,
      speed: 140,
      createdOn: 1459354900173,
      createdBy: 193666435,
      updatedOn: 1511806417100,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11718987,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.070707466411665,
          42.035307023961764
        ]
      },
      azymuth: 221,
      createdOn: 1469259006276,
      createdBy: 164222588,
      updatedOn: 1639149079703,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13823691,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.175997317790983,
          42.18736111349114
        ]
      },
      azymuth: 101,
      speed: 50,
      createdOn: 1494331032215,
      createdBy: 164222588,
      updatedOn: 1494350744119,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11341042,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.272299924074,
          42.416061189502
        ]
      },
      azymuth: 146,
      createdOn: 1465199511481,
      createdBy: 164222588,
      updatedOn: 1723306730632,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15325462,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.30714481459485,
          42.14968363535935
        ]
      },
      azymuth: 30,
      createdOn: 1507983589274,
      createdBy: 427525382,
      updatedOn: 1633371762347,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15884227,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.096328773895845,
          42.701591219268884
        ]
      },
      azymuth: 67,
      speed: 0,
      createdOn: 1570795288429,
      createdBy: 1144721908,
      updatedOn: 1671237089729,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11963757,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.075390414277827,
          42.69457970534851
        ]
      },
      azymuth: 235,
      speed: 50,
      createdOn: 1472530578727,
      createdBy: 164222588,
      updatedOn: 1668153007795,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 2350351,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.07539644924794,
          42.694570341356155
        ]
      },
      azymuth: 56,
      speed: 50,
      createdOn: 1370168650350,
      createdBy: 38946771,
      updatedOn: 1668153007795,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179763,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.09629749812,
          42.701574026617
        ]
      },
      azymuth: 243,
      speed: 0,
      createdOn: 1671237089675,
      createdBy: 1025514422,
      updatedOn: 1711828715123,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11033064,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.27382654435856,
          43.038405790848536
        ]
      },
      azymuth: 108,
      speed: 50,
      createdOn: 1462197533727,
      createdBy: -2,
      updatedOn: 1630215805151,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 15884179,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.272083064344915,
          43.326213142905644
        ]
      },
      azymuth: 36,
      speed: 0,
      createdOn: 1570684647105,
      createdBy: 280548302,
      updatedOn: 1589272827547,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13611917,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.156976998711,
          43.090691515445
        ]
      },
      azymuth: 289,
      createdOn: 1492248909118,
      createdBy: 164222588,
      updatedOn: 1723368973484,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13687412,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.15702125516,
          43.090678783598
        ]
      },
      azymuth: 108,
      speed: 0,
      createdOn: 1492963968730,
      createdBy: 164222588,
      updatedOn: 1723368973484,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245651,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.358702511008,
          43.315127711586
        ]
      },
      azymuth: 232,
      speed: 0,
      createdOn: 1708462531144,
      createdBy: 1591212739,
      updatedOn: 1708462531159,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16266076,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.131584849227,
          43.056155412058
        ]
      },
      azymuth: 203,
      speed: 0,
      createdOn: 1723306545901,
      createdBy: 19910588,
      updatedOn: 1723306551363,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245650,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.358714552875,
          43.315088192626
        ]
      },
      azymuth: 61,
      speed: 0,
      createdOn: 1708462531144,
      createdBy: 1591212739,
      updatedOn: 1708462531158,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 15884180,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.27205087783674,
          43.32618582614397
        ]
      },
      azymuth: 217,
      speed: 0,
      createdOn: 1570684663019,
      createdBy: 280548302,
      updatedOn: 1589272827546,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16042749,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.381985175151573,
          43.03425191838248
        ]
      },
      azymuth: 259,
      speed: 0,
      createdOn: 1593413359887,
      createdBy: 19910588,
      updatedOn: 1630260334979,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12290160,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.148539639775,
          43.157979814811
        ]
      },
      azymuth: 154,
      speed: 0,
      createdOn: 1477420216638,
      createdBy: 164222588,
      updatedOn: 1713170867732,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15887065,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.273834590986276,
          43.03842637522243
        ]
      },
      azymuth: 286,
      speed: 0,
      createdOn: 1576057276416,
      createdBy: 19910588,
      updatedOn: 1630215805151,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12287563,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.47075269004729,
          43.416076614289686
        ]
      },
      azymuth: 67,
      createdOn: 1477388179221,
      createdBy: 164222588,
      updatedOn: 1663238408769,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12287562,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.14851147658,
          43.158023838121
        ]
      },
      azymuth: 331,
      createdOn: 1477386594505,
      createdBy: 164222588,
      updatedOn: 1713170867731,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16132515,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.381963717479753,
          43.03429897155464
        ]
      },
      azymuth: 79,
      speed: 0,
      createdOn: 1630260334946,
      createdBy: 1025514422,
      updatedOn: 1630260334984,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12290157,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.470575664252774,
          43.4163552216863
        ]
      },
      azymuth: 246,
      speed: 0,
      createdOn: 1477420138221,
      createdBy: 164222588,
      updatedOn: 1663238408770,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14425450,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.131685432065,
          43.056129934006
        ]
      },
      azymuth: 19,
      speed: 0,
      createdOn: 1500209281152,
      createdBy: 317937065,
      updatedOn: 1723306530904,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16249527,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.243158223998424,
          44.38234475050164
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710591397033,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249526,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.22915172852305,
          44.371016437078424
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710591374512,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249535,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.42458484841424,
          44.47138492865674
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710592133792,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249534,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.423494201571973,
          44.46038978243031
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710592123720,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249533,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.422776265111413,
          44.45306606912276
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710592103916,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249532,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.422584351906004,
          44.44975424883965
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710591489316,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249531,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.422624585040996,
          44.44002275647925
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710591473419,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16249530,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.42271650302076,
          44.417712702185455
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1710591458343,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16236569,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.44199465489,
          44.491403746084
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702766612759,
      createdBy: 11225731,
      updatedOn: 1702766612772,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16249529,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.414062906811,
          44.408789334135
        ]
      },
      azymuth: 45,
      speed: 0,
      createdOn: 1710591436152,
      createdBy: 11225731,
      updatedOn: 1722512113251,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236568,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.425617086644,
          44.474758889568
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702766372913,
      createdBy: 11225731,
      updatedOn: 1702766372927,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16249528,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.365938371827,
          44.3951857018
        ]
      },
      azymuth: 59,
      speed: 0,
      createdOn: 1710591416993,
      createdBy: 11225731,
      updatedOn: 1722512228186,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236570,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.456730711218,
          44.502641791613
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702766744393,
      createdBy: 11225731,
      updatedOn: 1702766744405,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16249474,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.234461570496,
          44.857815735324
        ]
      },
      azymuth: 167,
      speed: 0,
      createdOn: 1710577846695,
      createdBy: 1175159865,
      updatedOn: 1710577858871,
      updatedBy: 1175159865,
      permissions: -1,
      rank: 4,
      lockRank: 4
    },
    {
      id: 7331080,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.234446818346,
          44.857822389905
        ]
      },
      azymuth: 344,
      createdOn: 1423160664311,
      createdBy: 177889771,
      updatedOn: 1710577822054,
      updatedBy: 1175159865,
      permissions: -1,
      rank: 4,
      lockRank: 4
    },
    {
      id: 16249536,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.478204267756,
          44.512917442848
        ]
      },
      azymuth: 72,
      speed: 0,
      createdOn: 1710592170291,
      createdBy: 11225731,
      updatedOn: 1722512144516,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10647574,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.303088351242305,
          45.49370972807966
        ]
      },
      azymuth: 28,
      speed: 0,
      createdOn: 1458636538121,
      createdBy: 11225731,
      updatedOn: 1504627959172,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5328951,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.381497,
          45.07774
        ]
      },
      azymuth: 80,
      createdOn: 1404310132100,
      createdBy: 189968872,
      updatedOn: 1511824218325,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5160137,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.296257376365638,
          45.451368516425426
        ]
      },
      azymuth: 120,
      createdOn: 1402654966662,
      createdBy: 54023336,
      updatedOn: 1504962739784,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4701805,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.358983,
          45.213367
        ]
      },
      azymuth: 121,
      createdOn: 1397982602701,
      createdBy: 36063847,
      updatedOn: 1503200012280,
      updatedBy: 36063847,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16261075,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.352334285929,
          45.080524897496
        ]
      },
      azymuth: 231,
      speed: 0,
      createdOn: 1719749764164,
      createdBy: 11225731,
      updatedOn: 1723484966313,
      updatedBy: 105774162,
      permissions: 0,
      rank: 6,
      lockRank: "None"
    },
    {
      id: 7602031,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.375739,
          45.198488
        ]
      },
      azymuth: 189,
      createdOn: 1425746215230,
      createdBy: 11225731,
      updatedOn: 1492119135771,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 7602030,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.373928,
          45.156507
        ]
      },
      azymuth: 336,
      speed: 50,
      createdOn: 1425745882911,
      createdBy: 11225731,
      updatedOn: 1507544489790,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 7602029,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.364469,
          45.131312
        ]
      },
      azymuth: 1,
      speed: 50,
      createdOn: 1425745697323,
      createdBy: 11225731,
      updatedOn: 1504162436538,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11061449,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.76827629868934,
          41.08703170088581
        ]
      },
      azymuth: 83,
      speed: 50,
      createdOn: 1462476725268,
      createdBy: 20650508,
      updatedOn: 1462477139744,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11074922,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.68021869063337,
          41.00908026982726
        ]
      },
      azymuth: 233,
      speed: 90,
      createdOn: 1462602244619,
      createdBy: 20650508,
      updatedOn: 1462602289475,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 6046041,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.900139000000006,
          41.12874332300948
        ]
      },
      azymuth: 131,
      speed: 50,
      createdOn: 1410623886200,
      createdBy: -2,
      updatedOn: 1464291618583,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 6105704,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.795398145998032,
          41.07626366934572
        ]
      },
      azymuth: 305,
      speed: 90,
      createdOn: 1411215325447,
      createdBy: -2,
      updatedOn: 1464292194770,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11250419,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.876774926383487,
          41.12019553225315
        ]
      },
      azymuth: 218,
      speed: 0,
      createdOn: 1464290969562,
      createdBy: 20650508,
      updatedOn: 1464291043688,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 6105703,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.81664455092618,
          41.07686643853785
        ]
      },
      azymuth: 227,
      speed: 50,
      createdOn: 1411215241482,
      createdBy: -2,
      updatedOn: 1464291857133,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 6105702,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.831274811010374,
          41.08129294325301
        ]
      },
      azymuth: 92,
      speed: 70,
      createdOn: 1411215187899,
      createdBy: -2,
      updatedOn: 1464291792799,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11238985,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.87360402527952,
          41.994645742177525
        ]
      },
      azymuth: 284,
      speed: 0,
      createdOn: 1464195011427,
      createdBy: 164222588,
      updatedOn: 1604078203618,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11445514,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.68739298087247,
          41.716755890095094
        ]
      },
      azymuth: 183,
      speed: 0,
      createdOn: 1466406101313,
      createdBy: 164222588,
      updatedOn: 1672235676239,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11446151,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.69072478809239,
          41.87410232502984
        ]
      },
      azymuth: 190,
      speed: 0,
      createdOn: 1466416353592,
      createdBy: 164222588,
      updatedOn: 1466416353588,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11494086,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.692383189782625,
          41.64060238158483
        ]
      },
      azymuth: 323,
      speed: 0,
      createdOn: 1466945169603,
      createdBy: 164222588,
      updatedOn: 1523818535828,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11816376,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.962908427169,
          41.944347571266
        ]
      },
      azymuth: 311,
      speed: 0,
      createdOn: 1470635334702,
      createdBy: 164222588,
      updatedOn: 1707845336451,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11238982,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.873585249816347,
          41.99457995870719
        ]
      },
      azymuth: 109,
      speed: 0,
      createdOn: 1464194987614,
      createdBy: 164222588,
      updatedOn: 1493997006473,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11505250,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.91191882620701,
          41.53205605289593
        ]
      },
      azymuth: 90,
      speed: 0,
      createdOn: 1467060217340,
      createdBy: 164222588,
      updatedOn: 1593534425316,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11816377,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.962947319199,
          41.944315651065
        ]
      },
      azymuth: 130,
      speed: 0,
      createdOn: 1470635334702,
      createdBy: 164222588,
      updatedOn: 1707845435977,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11505249,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.911881275280656,
          41.532057056826666
        ]
      },
      azymuth: 272,
      speed: 0,
      createdOn: 1467060217340,
      createdBy: 164222588,
      updatedOn: 1593534425317,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11505247,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.910799870185055,
          41.53138253150797
        ]
      },
      azymuth: 215,
      speed: 0,
      createdOn: 1467060168811,
      createdBy: 164222588,
      updatedOn: 1467060217334,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11479485,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.692309393848397,
          41.640566869832604
        ]
      },
      azymuth: 140,
      createdOn: 1466786880126,
      createdBy: 164222588,
      updatedOn: 1523818535828,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16136718,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.693280692090983,
          41.699685397372605
        ]
      },
      azymuth: 178,
      speed: 0,
      createdOn: 1633758560983,
      createdBy: 19910588,
      updatedOn: 1633758573128,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11265009,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.690880737434394,
          41.87407027660252
        ]
      },
      azymuth: 14,
      createdOn: 1464422103406,
      createdBy: 332855223,
      updatedOn: 1642680299767,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11502645,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.911035923942585,
          41.53125777921958
        ]
      },
      azymuth: 37,
      createdOn: 1467028841974,
      createdBy: 164222588,
      updatedOn: 1604077931026,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11432946,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.687477574239757,
          41.71674696400216
        ]
      },
      azymuth: 0,
      speed: 40,
      createdOn: 1466264274105,
      createdBy: 164222588,
      updatedOn: 1633758795898,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16143337,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.632063681484066,
          41.66531185818733
        ]
      },
      azymuth: 119,
      speed: 0,
      createdOn: 1640182936546,
      createdBy: 19910588,
      updatedOn: 1640183088424,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10558113,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.508683354492156,
          42.205121947327264
        ]
      },
      azymuth: 270,
      speed: 140,
      createdOn: 1457807193527,
      createdBy: 193666435,
      updatedOn: 1603730064928,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11211179,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.635615846557542,
          42.1353414280834
        ]
      },
      azymuth: 265,
      createdOn: 1463909472636,
      createdBy: 164222588,
      updatedOn: 1660716307476,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10898468,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.85984,
          42.216968
        ]
      },
      azymuth: 265,
      speed: 140,
      createdOn: 1460899078179,
      createdBy: 193666435,
      updatedOn: 1503851353995,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10800033,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.731735983317076,
          42.278955030870314
        ]
      },
      azymuth: 192,
      speed: 50,
      createdOn: 1459976780185,
      createdBy: 193666435,
      updatedOn: 1604149328693,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10952992,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.687626927936087,
          42.207582904610895
        ]
      },
      azymuth: 67,
      speed: 140,
      createdOn: 1461426955230,
      createdBy: 193666435,
      updatedOn: 1603728658338,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11255267,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.732728579300296,
          42.20707868659244
        ]
      },
      azymuth: 167,
      speed: 90,
      createdOn: 1464346018245,
      createdBy: 164222588,
      updatedOn: 1603738044801,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10735338,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.782004932540925,
          42.210503160055076
        ]
      },
      azymuth: 90,
      speed: 140,
      createdOn: 1459356156668,
      createdBy: 193666435,
      updatedOn: 1459373260532,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11115171,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.854038,
          42.029156
        ]
      },
      azymuth: 144,
      createdOn: 1462983074324,
      createdBy: 164222588,
      updatedOn: 1604078807333,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11212967,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.635614317790985,
          42.13540693413711
        ]
      },
      azymuth: 85,
      createdOn: 1463928902900,
      createdBy: 164222588,
      updatedOn: 1471117867798,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11115172,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.857441,
          42.025454
        ]
      },
      azymuth: 326,
      createdOn: 1462983074324,
      createdBy: 164222588,
      updatedOn: 1604078807332,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16265718,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.713721464355057,
          42.11763375488049
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1723116804576,
      createdBy: 1540483543,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16265719,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.713721464355057,
          42.11763375488049
        ]
      },
      azymuth: 0,
      speed: 50,
      createdOn: 1723116804576,
      createdBy: 1540483543,
      updatedOn: 1723116933430,
      updatedBy: 1540483543,
      permissions: -1,
      rank: 0,
      lockRank: 0
    },
    {
      id: 15878116,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.747080814411,
          42.143086367116
        ]
      },
      azymuth: 83,
      speed: 40,
      createdOn: 1559593217767,
      createdBy: 164222588,
      updatedOn: 1723311028111,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11165885,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.66808682053439,
          42.154170531599945
        ]
      },
      azymuth: 100,
      createdOn: 1463476466701,
      createdBy: 311573441,
      updatedOn: 1589265643201,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13876676,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.806762718829837,
          42.147429433885634
        ]
      },
      azymuth: 83,
      speed: 50,
      createdOn: 1495028237724,
      createdBy: 164222588,
      updatedOn: 1593626373962,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10736955,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.782155173561165,
          42.21060972038346
        ]
      },
      azymuth: 271,
      speed: 140,
      createdOn: 1459373282711,
      createdBy: 193666435,
      updatedOn: 1474806963913,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11169714,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.736956241733562,
          42.12133961022585
        ]
      },
      azymuth: 23,
      speed: 0,
      createdOn: 1463507150978,
      createdBy: 164222588,
      updatedOn: 1655793351246,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267104,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.742041072955,
          42.302474653947
        ]
      },
      azymuth: 18,
      speed: 0,
      createdOn: 1723701609827,
      createdBy: 19910588,
      updatedOn: 1723701609838,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11164979,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.760375,
          42.140426
        ]
      },
      azymuth: 255,
      speed: 30,
      createdOn: 1463462117382,
      createdBy: 164222588,
      updatedOn: 1464606700376,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 12114341,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.944303065306674,
          42.13697894221358
        ]
      },
      azymuth: 271,
      speed: 0,
      createdOn: 1474755846002,
      createdBy: 164222588,
      updatedOn: 1668504334541,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16137963,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.723643465002393,
          42.2442832745475
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1634660016603,
      createdBy: 19910588,
      updatedOn: 1634660504020,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13864845,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.806717121276666,
          42.14760443440664
        ]
      },
      azymuth: 265,
      speed: 50,
      createdOn: 1494928549735,
      createdBy: 164222588,
      updatedOn: 1593626373961,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10793732,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.731752076571613,
          42.278988767499584
        ]
      },
      azymuth: 9,
      speed: 50,
      createdOn: 1459924292279,
      createdBy: 193666435,
      updatedOn: 1604149328694,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10556099,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.508423433204715,
          42.205006039494265
        ]
      },
      azymuth: 91,
      speed: 140,
      createdOn: 1457796556175,
      createdBy: 193666435,
      updatedOn: 1462472461029,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 12022803,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.944341286785857,
          42.1369784449705
        ]
      },
      azymuth: 92,
      speed: 0,
      createdOn: 1473261158428,
      createdBy: 164222588,
      updatedOn: 1668504334540,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267103,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.742028332462,
          42.30245184062
        ]
      },
      azymuth: 194,
      speed: 0,
      createdOn: 1723701609827,
      createdBy: 19910588,
      updatedOn: 1723701609838,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12141139,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.80935539717739,
          42.20528879793447
        ]
      },
      azymuth: 199,
      speed: 60,
      createdOn: 1475501139752,
      createdBy: 164222588,
      updatedOn: 1592825249048,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 4572392,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.637547392273923,
          42.19841748746684
        ]
      },
      azymuth: 269,
      speed: 140,
      createdOn: 1396594993830,
      createdBy: 162800398,
      updatedOn: 1589354744603,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10879944,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.859831077385017,
          42.21686570218637
        ]
      },
      azymuth: 86,
      speed: 140,
      createdOn: 1460734214306,
      createdBy: 193666435,
      updatedOn: 1500031154805,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11212421,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.67591173877121,
          42.14850842886297
        ]
      },
      azymuth: 5,
      createdOn: 1463925860289,
      createdBy: 308682359,
      updatedOn: 1589385335357,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11165381,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.675907715457797,
          42.14847263385263
        ]
      },
      azymuth: 184,
      createdOn: 1463468432306,
      createdBy: 164222588,
      updatedOn: 1589385335357,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15876483,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.751881968546,
          42.143696920081
        ]
      },
      azymuth: 247,
      speed: 40,
      createdOn: 1556804100366,
      createdBy: 11606828,
      updatedOn: 1723311028110,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 4301683,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.817834790751732,
          42.0640236380392
        ]
      },
      azymuth: 146,
      speed: 90,
      createdOn: 1393855938360,
      createdBy: 17724506,
      updatedOn: 1577090309417,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15858563,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.737264732769667,
          42.1855402699726
        ]
      },
      azymuth: 355,
      speed: 0,
      createdOn: 1523622798779,
      createdBy: 164222588,
      updatedOn: 1589283513107,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16126788,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.772845116126813,
          42.138758384361815
        ]
      },
      azymuth: 244,
      speed: 0,
      createdOn: 1626347931441,
      createdBy: 164222588,
      updatedOn: 1626347931476,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15858565,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.737270097187842,
          42.18550648335789
        ]
      },
      azymuth: 172,
      speed: 0,
      createdOn: 1523622817085,
      createdBy: 164222588,
      updatedOn: 1589283513108,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16126787,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.772826675009846,
          42.13860448428897
        ]
      },
      azymuth: 64,
      speed: 0,
      createdOn: 1626347908134,
      createdBy: 164222588,
      updatedOn: 1640252946146,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10952671,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.68776237949174,
          42.20762263968418
        ]
      },
      azymuth: 249,
      speed: 140,
      createdOn: 1461417137690,
      createdBy: 193666435,
      updatedOn: 1603728658340,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11307733,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.66803617692957,
          42.15417947954806
        ]
      },
      azymuth: 278,
      speed: 0,
      createdOn: 1464850670653,
      createdBy: 164222588,
      updatedOn: 1589265643202,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10558619,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.64025053381722,
          42.19837987397202
        ]
      },
      azymuth: 87,
      speed: 140,
      createdOn: 1457813736663,
      createdBy: 193666435,
      updatedOn: 1593672741713,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10787678,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.809376818046132,
          42.20533691387557
        ]
      },
      azymuth: 21,
      speed: 0,
      createdOn: 1459864963008,
      createdBy: 19910588,
      updatedOn: 1592825249047,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11253776,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.732719191568705,
          42.20710352121471
        ]
      },
      azymuth: 346,
      speed: 0,
      createdOn: 1464327789724,
      createdBy: 94460944,
      updatedOn: 1660574679254,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13544434,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.775440544702573,
          42.11918053281646
        ]
      },
      azymuth: 158,
      speed: 0,
      createdOn: 1491580573447,
      createdBy: 164222588,
      updatedOn: 1660588366517,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10558620,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.640510708091263,
          42.19843054356625
        ]
      },
      azymuth: 267,
      speed: 140,
      createdOn: 1457813761488,
      createdBy: 193666435,
      updatedOn: 1593672741713,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13590960,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.77542713365758,
          42.11921137055804
        ]
      },
      azymuth: 339,
      speed: 0,
      createdOn: 1492072105718,
      createdBy: 164222588,
      updatedOn: 1660588366518,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 4876856,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.665377,
          42.851799
        ]
      },
      azymuth: 98,
      createdOn: 1399274393685,
      createdBy: 41719455,
      updatedOn: 1604735336691,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13669780,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.62854144324743,
          42.70108559612025
        ]
      },
      azymuth: 87,
      speed: 0,
      createdOn: 1492802282829,
      createdBy: 164222588,
      updatedOn: 1671456535577,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16215743,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.685759268683004,
          42.96105449770039
        ]
      },
      azymuth: 348,
      speed: 0,
      createdOn: 1689610487556,
      createdBy: 1025514422,
      updatedOn: 1689610487582,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16044525,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.826345889419006,
          42.547346142205896
        ]
      },
      azymuth: 22,
      speed: 0,
      createdOn: 1593712277084,
      createdBy: 164222588,
      updatedOn: 1662837876161,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16215742,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.685712330025062,
          42.96105155337008
        ]
      },
      azymuth: 170,
      speed: 0,
      createdOn: 1689610487556,
      createdBy: 1025514422,
      updatedOn: 1689610487578,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16044526,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.82632443174719,
          42.54732144177845
        ]
      },
      azymuth: 201,
      speed: 0,
      createdOn: 1593712277084,
      createdBy: 164222588,
      updatedOn: 1662837876161,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13632894,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.62854010214266,
          42.70106637730631
        ]
      },
      azymuth: 266,
      createdOn: 1492422778274,
      createdBy: 170834208,
      updatedOn: 1671456535577,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12813180,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.948284585030695,
          42.607348975168236
        ]
      },
      azymuth: 72,
      createdOn: 1483707913456,
      createdBy: 41269476,
      updatedOn: 1692445759707,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12845083,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.948275197299107,
          42.607370197098746
        ]
      },
      azymuth: 253,
      createdOn: 1484044231232,
      createdBy: 164222588,
      updatedOn: 1692445759706,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16239607,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.956827758842,
          43.26960174493
        ]
      },
      azymuth: 251,
      speed: 0,
      createdOn: 1704982869127,
      createdBy: 285430962,
      updatedOn: 1704982869170,
      updatedBy: 285430962,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 105600,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.887903263722656,
          43.04759611918836
        ]
      },
      azymuth: 66,
      speed: 60,
      createdOn: 1303885270000,
      createdBy: 11855933,
      updatedOn: 1668064231836,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179191,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.615260427134526,
          43.417978643553575
        ]
      },
      azymuth: 161,
      speed: 0,
      createdOn: 1670624775407,
      createdBy: 1591212739,
      updatedOn: 1672257296590,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16239606,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.95683915823,
          43.269582214784
        ]
      },
      azymuth: 73,
      speed: 0,
      createdOn: 1704982869127,
      createdBy: 285430962,
      updatedOn: 1704982869170,
      updatedBy: 285430962,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16182002,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.587097393584916,
          43.430896887438266
        ]
      },
      azymuth: 289,
      speed: 0,
      createdOn: 1673422066908,
      createdBy: 1591212739,
      updatedOn: 1673422080106,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16182001,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.58702296228414,
          43.430748852097544
        ]
      },
      azymuth: 110,
      speed: 0,
      createdOn: 1673422066908,
      createdBy: 1591212739,
      updatedOn: 1673422066921,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 7495416,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.594736821953283,
          43.43888812154955
        ]
      },
      azymuth: 118,
      speed: 90,
      createdOn: 1424690269313,
      createdBy: 219424118,
      updatedOn: 1700234013929,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 7533432,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.5947006121308,
          43.438896885631
        ]
      },
      azymuth: 294,
      speed: 90,
      createdOn: 1425060631322,
      createdBy: 29192919,
      updatedOn: 1700234013930,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179174,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.622353086766577,
          43.39763605804964
        ]
      },
      azymuth: 43,
      speed: 0,
      createdOn: 1670620185094,
      createdBy: 1591212739,
      updatedOn: 1670620185146,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179175,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.6226186254588,
          43.39764385365336
        ]
      },
      azymuth: 321,
      speed: 0,
      createdOn: 1670620185094,
      createdBy: 1591212739,
      updatedOn: 1670620185148,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179172,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.627261955853037,
          43.411584907252994
        ]
      },
      azymuth: 10,
      speed: 0,
      createdOn: 1670620021075,
      createdBy: 1591212739,
      updatedOn: 1670620021141,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179173,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.627742071267033,
          43.412144110529134
        ]
      },
      azymuth: 232,
      speed: 0,
      createdOn: 1670620021075,
      createdBy: 1591212739,
      updatedOn: 1670620021144,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179170,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.627980787869255,
          43.411949266768026
        ]
      },
      azymuth: 270,
      speed: 0,
      createdOn: 1670620021075,
      createdBy: 1591212739,
      updatedOn: 1670620021134,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16220387,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.597832348835343,
          43.04297642715501
        ]
      },
      azymuth: 67,
      speed: 0,
      createdOn: 1692702061941,
      createdBy: 1025514422,
      updatedOn: 1692702061959,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179171,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.626921315308106,
          43.41191809170791
        ]
      },
      azymuth: 88,
      speed: 0,
      createdOn: 1670620021075,
      createdBy: 1591212739,
      updatedOn: 1670620021138,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179168,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.63681866657249,
          43.41174857703894
        ]
      },
      azymuth: 320,
      speed: 0,
      createdOn: 1670619777281,
      createdBy: 1591212739,
      updatedOn: 1670619777342,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179169,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.63585307132723,
          43.412148007398066
        ]
      },
      azymuth: 109,
      speed: 0,
      createdOn: 1670619826133,
      createdBy: 1591212739,
      updatedOn: 1670619826191,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179425,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.608744689760663,
          43.418151051813105
        ]
      },
      azymuth: 120,
      speed: 0,
      createdOn: 1670854943517,
      createdBy: 1025514422,
      updatedOn: 1670854943581,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16179158,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.623491401344864,
          43.418217540556306
        ]
      },
      azymuth: 258,
      speed: 0,
      createdOn: 1670618601039,
      createdBy: 1591212739,
      updatedOn: 1670618601100,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179159,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.622934842972498,
          43.418083111912495
        ]
      },
      azymuth: 67,
      speed: 0,
      createdOn: 1670618655479,
      createdBy: 1591212739,
      updatedOn: 1670618655530,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179156,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.61527139776861,
          43.41766328909704
        ]
      },
      azymuth: 67,
      speed: 0,
      createdOn: 1670618421059,
      createdBy: 1591212739,
      updatedOn: 1670618421119,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179157,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.623334492117397,
          43.41790095088078
        ]
      },
      azymuth: 323,
      speed: 0,
      createdOn: 1670618601039,
      createdBy: 1591212739,
      updatedOn: 1670618601098,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179155,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.615523525414936,
          43.41779138685274
        ]
      },
      azymuth: 207,
      speed: 0,
      createdOn: 1670618421059,
      createdBy: 1591212739,
      updatedOn: 1670618421119,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 12289053,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.68356762035174,
          43.240049414559984
        ]
      },
      azymuth: 147,
      createdOn: 1477393670398,
      createdBy: 164222588,
      updatedOn: 1699127374081,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179166,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.63600327503175,
          43.41184794776377
        ]
      },
      azymuth: 72,
      speed: 0,
      createdOn: 1670619777281,
      createdBy: 1591212739,
      updatedOn: 1670619777337,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179422,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.619668180576188,
          43.41046533595739
        ]
      },
      azymuth: 326,
      speed: 0,
      createdOn: 1670845733446,
      createdBy: 1591212739,
      updatedOn: 1670845733503,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179167,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.636748929137962,
          43.41216359486998
        ]
      },
      azymuth: 240,
      speed: 0,
      createdOn: 1670619777281,
      createdBy: 1591212739,
      updatedOn: 1670619777339,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179420,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.619617218604834,
          43.410897898856334
        ]
      },
      azymuth: 227,
      speed: 0,
      createdOn: 1670845678614,
      createdBy: 1591212739,
      updatedOn: 1670845678672,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179418,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.62289621363751,
          43.41836206087035
        ]
      },
      azymuth: 143,
      speed: 0,
      createdOn: 1670845491294,
      createdBy: 1591212739,
      updatedOn: 1670845491352,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 11202183,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.99000023041548,
          43.439446088926445
        ]
      },
      azymuth: 76,
      speed: 0,
      createdOn: 1463822817182,
      createdBy: 11172139,
      updatedOn: 1668184094672,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179419,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.619194770685148,
          43.410972915080386
        ]
      },
      azymuth: 152,
      speed: 0,
      createdOn: 1670845678614,
      createdBy: 1591212739,
      updatedOn: 1670845678669,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16180164,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.61507310897618,
          43.41196789123945
        ]
      },
      azymuth: 324,
      speed: 0,
      createdOn: 1671570238155,
      createdBy: 1591212739,
      updatedOn: 1671570238206,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 11184921,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.717397952810035,
          43.04210719015865
        ]
      },
      azymuth: 185,
      speed: 0,
      createdOn: 1463673026138,
      createdBy: 193666435,
      updatedOn: 1669464279894,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179010,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.6177575503486,
          43.40473037897813
        ]
      },
      azymuth: 291,
      speed: 0,
      createdOn: 1670460551463,
      createdBy: 1025514422,
      updatedOn: 1670460551521,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16182466,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.614686276826674,
          43.42036958994099
        ]
      },
      azymuth: 81,
      speed: 0,
      createdOn: 1673891053822,
      createdBy: 1591212739,
      updatedOn: 1673891053836,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16180163,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.6143342618738,
          43.41228932021803
        ]
      },
      azymuth: 140,
      speed: 0,
      createdOn: 1671570238155,
      createdBy: 1591212739,
      updatedOn: 1671570238204,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179009,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.617353877892086,
          43.40465438068466
        ]
      },
      azymuth: 34,
      speed: 0,
      createdOn: 1670460452271,
      createdBy: 1025514422,
      updatedOn: 1670460560899,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16182465,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.615580122981598,
          43.42050206534449
        ]
      },
      azymuth: 262,
      speed: 0,
      createdOn: 1673891053822,
      createdBy: 1591212739,
      updatedOn: 1673891053833,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16179405,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.615530197127097,
          43.41756035710102
        ]
      },
      azymuth: 331,
      speed: 0,
      createdOn: 1670834787721,
      createdBy: 1591212739,
      updatedOn: 1670834787774,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 11184919,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.717405999437755,
          43.04215619760498
        ]
      },
      azymuth: 6,
      speed: 60,
      createdOn: 1463673009452,
      createdBy: 193666435,
      updatedOn: 1596780829944,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 606153,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.88785096064654,
          43.04757749801449
        ]
      },
      azymuth: 245,
      speed: 60,
      createdOn: 1337942276000,
      createdBy: 14907211,
      updatedOn: 1668064231835,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16188087,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.61999669576831,
          43.397443556594666
        ]
      },
      azymuth: 316,
      speed: 0,
      createdOn: 1678863671794,
      createdBy: 1591212739,
      updatedOn: 1678863671806,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 11568759,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.596185490412626,
          43.04245043442544
        ]
      },
      azymuth: 71,
      createdOn: 1467725366730,
      createdBy: 164222588,
      updatedOn: 1675674132939,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16170171,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.548458133051582,
          43.49879249255202
        ]
      },
      azymuth: 30,
      speed: 0,
      createdOn: 1662223418672,
      createdBy: 14361387,
      updatedOn: 1692621887206,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16184376,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.597810891162627,
          43.043013672289845
        ]
      },
      azymuth: 243,
      speed: 0,
      createdOn: 1675674087036,
      createdBy: 19910588,
      updatedOn: 1692702061958,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16181666,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.585721030360205,
          43.43030666172299
        ]
      },
      azymuth: 162,
      speed: 0,
      createdOn: 1673074606535,
      createdBy: 1591212739,
      updatedOn: 1673589901573,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 300499,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.52939368291467,
          43.04032736080206
        ]
      },
      azymuth: 88,
      speed: 50,
      createdOn: 1324561960000,
      createdBy: 15662492,
      updatedOn: 1630265595875,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 7926048,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          24.529361496406494,
          43.04032638062478
        ]
      },
      azymuth: 279,
      speed: 50,
      createdOn: 1429338276580,
      createdBy: 243244210,
      updatedOn: 1630265655768,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16182190,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.607315987348457,
          43.41925392263481
        ]
      },
      azymuth: 315,
      speed: 0,
      createdOn: 1673590552910,
      createdBy: 1591212739,
      updatedOn: 1673590552924,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16182191,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.607384383678216,
          43.41930311480944
        ]
      },
      azymuth: 320,
      speed: 0,
      createdOn: 1673590552910,
      createdBy: 1591212739,
      updatedOn: 1673590552924,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16180780,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.620779052974513,
          43.40528640633854
        ]
      },
      azymuth: 180,
      speed: 0,
      createdOn: 1672257273189,
      createdBy: 1591212739,
      updatedOn: 1672257273202,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16180781,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          24.620461211206162,
          43.4049726720571
        ]
      },
      azymuth: 88,
      speed: 0,
      createdOn: 1672257273189,
      createdBy: 1591212739,
      updatedOn: 1672257273205,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16185386,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.631609598820308,
          43.40011959443222
        ]
      },
      azymuth: 159,
      speed: 0,
      createdOn: 1676453231302,
      createdBy: 1591212739,
      updatedOn: 1676453231312,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 12290130,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.683531410530158,
          43.240097286618315
        ]
      },
      azymuth: 329,
      speed: 0,
      createdOn: 1477419819998,
      createdBy: 164222588,
      updatedOn: 1699127374085,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11592133,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.596272662205564,
          43.04255432958301
        ]
      },
      azymuth: 257,
      speed: 0,
      createdOn: 1467970548779,
      createdBy: 164222588,
      updatedOn: 1675674132938,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15866505,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.989977180955023,
          43.439443170700685
        ]
      },
      azymuth: 255,
      speed: 0,
      createdOn: 1537452412734,
      createdBy: 280548302,
      updatedOn: 1668184094670,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16216328,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.620008252349205,
          43.396485419823385
        ]
      },
      azymuth: 28,
      speed: 0,
      createdOn: 1689974397298,
      createdBy: 1591212739,
      updatedOn: 1689974397309,
      updatedBy: 1591212739,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 15369544,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.945732600998404,
          43.60990077972669
        ]
      },
      azymuth: 300,
      speed: 0,
      createdOn: 1508397304429,
      createdBy: 19910588,
      updatedOn: 1633778906347,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16236565,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.630898609072,
          44.611957926478
        ]
      },
      azymuth: 51,
      speed: 0,
      createdOn: 1702764541155,
      createdBy: 11225731,
      updatedOn: 1722512158114,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236573,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.53399908711,
          44.542390238355
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702767475958,
      createdBy: 11225731,
      updatedOn: 1702767475970,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236572,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.5181693875,
          44.528509371674
        ]
      },
      azymuth: 58,
      speed: 0,
      createdOn: 1702767299716,
      createdBy: 11225731,
      updatedOn: 1722512268686,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236571,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.501475318594,
          44.520569579233
        ]
      },
      azymuth: 58,
      speed: 0,
      createdOn: 1702767044504,
      createdBy: 11225731,
      updatedOn: 1722512245068,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5700952,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.960422,
          44.809235
        ]
      },
      azymuth: 314,
      createdOn: 1407675847200,
      createdBy: 11225731,
      updatedOn: 1511519518114,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236581,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.653378845944,
          44.630634025494
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702768229666,
      createdBy: 11225731,
      updatedOn: 1702768229678,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236580,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.611166237044,
          44.601248314304
        ]
      },
      azymuth: 56,
      speed: 0,
      createdOn: 1702768115166,
      createdBy: 11225731,
      updatedOn: 1722512319514,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236579,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.602164743588,
          44.596569209865
        ]
      },
      azymuth: 53,
      speed: 0,
      createdOn: 1702767997986,
      createdBy: 11225731,
      updatedOn: 1722512304033,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236578,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.580369116557,
          44.57118662698
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702767889133,
      createdBy: 11225731,
      updatedOn: 1702767889148,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236577,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.56868004625,
          44.563438016556
        ]
      },
      azymuth: 55,
      speed: 0,
      createdOn: 1702767830647,
      createdBy: 11225731,
      updatedOn: 1722512284066,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236576,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.557886840594,
          44.553402473596
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1702767697339,
      createdBy: 11225731,
      updatedOn: 1702767697354,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9791563,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.9256025410003,
          44.59478237496198
        ]
      },
      azymuth: 322,
      createdOn: 1450967182587,
      createdBy: 294214965,
      updatedOn: 1510489009636,
      updatedBy: 290714023,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16236585,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.698741750177,
          44.649280696047
        ]
      },
      azymuth: 123,
      speed: 0,
      createdOn: 1702768330716,
      createdBy: 11225731,
      updatedOn: 1722512357253,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236584,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          24.67492502409,
          44.648587276892
        ]
      },
      azymuth: 62,
      speed: 0,
      createdOn: 1702768298447,
      createdBy: 11225731,
      updatedOn: 1722512334942,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16222423,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.227355913786734,
          41.05809000664832
        ]
      },
      azymuth: 210,
      speed: 0,
      createdOn: 1693913029551,
      createdBy: 11171607,
      updatedOn: 1693913029565,
      updatedBy: 11171607,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 15864726,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.46795568026683,
          41.2153757809642
        ]
      },
      azymuth: 145,
      speed: 60,
      createdOn: 1534446519616,
      createdBy: 11225731,
      updatedOn: 1534446519582,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11082284,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.402196966934476,
          41.09503784298781
        ]
      },
      azymuth: 92,
      speed: 130,
      createdOn: 1462658693469,
      createdBy: 20650508,
      updatedOn: 1505215825913,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 15864727,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.468001994702757,
          41.215325078753324
        ]
      },
      azymuth: 325,
      speed: 60,
      createdOn: 1534446519715,
      createdBy: 11225731,
      updatedOn: 1534446519681,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864724,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.469350585319464,
          41.207380773043155
        ]
      },
      azymuth: 51,
      speed: 60,
      createdOn: 1534446061059,
      createdBy: 11225731,
      updatedOn: 1534446061036,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864725,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.47047734808852,
          41.21248458776191
        ]
      },
      azymuth: 330,
      speed: 0,
      createdOn: 1534446143326,
      createdBy: 11225731,
      updatedOn: 1534446143301,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864730,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.461451970828357,
          41.220982446341864
        ]
      },
      azymuth: 164,
      speed: 60,
      createdOn: 1534446913191,
      createdBy: 11225731,
      updatedOn: 1534446913148,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864731,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.456329881101016,
          41.22581916599848
        ]
      },
      azymuth: 145,
      speed: 60,
      createdOn: 1534447167158,
      createdBy: 11225731,
      updatedOn: 1534447167176,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864728,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.47042000856307,
          41.21255775950225
        ]
      },
      azymuth: 148,
      speed: 60,
      createdOn: 1534446565020,
      createdBy: 11225731,
      updatedOn: 1534446564985,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864729,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.461476415344247,
          41.22092231029312
        ]
      },
      azymuth: 344,
      speed: 60,
      createdOn: 1534446913091,
      createdBy: 11225731,
      updatedOn: 1534446913049,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864734,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.454918504876606,
          41.23717277641283
        ]
      },
      azymuth: 204,
      speed: 60,
      createdOn: 1534447476749,
      createdBy: 11225731,
      updatedOn: 1534447476777,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864735,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.45762933498764,
          41.24164047755123
        ]
      },
      azymuth: 6,
      speed: 0,
      createdOn: 1534447587418,
      createdBy: 11225731,
      updatedOn: 1534447587443,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864732,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.45642010047989,
          41.22572980526379
        ]
      },
      azymuth: 327,
      speed: 60,
      createdOn: 1534447167258,
      createdBy: 11225731,
      updatedOn: 1534447167275,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864733,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.454858822090152,
          41.23707089574707
        ]
      },
      azymuth: 25,
      speed: 60,
      createdOn: 1534447476650,
      createdBy: 11225731,
      updatedOn: 1534447476677,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864738,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.440643004962944,
          41.25680964056588
        ]
      },
      azymuth: 126,
      speed: 60,
      createdOn: 1534447819698,
      createdBy: 11225731,
      updatedOn: 1534447819720,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864739,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.437355923942583,
          41.26031475591047
        ]
      },
      azymuth: 154,
      speed: 60,
      createdOn: 1534447937050,
      createdBy: 11225731,
      updatedOn: 1534447937069,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864736,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.45760900331487,
          41.24157633507116
        ]
      },
      azymuth: 194,
      speed: 0,
      createdOn: 1534447587518,
      createdBy: 11225731,
      updatedOn: 1534447587544,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864737,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.440548800205544,
          41.25686209768312
        ]
      },
      azymuth: 306,
      speed: 60,
      createdOn: 1534447819597,
      createdBy: 11225731,
      updatedOn: 1534447819621,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864740,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.437299905187693,
          41.26041237043231
        ]
      },
      azymuth: 338,
      speed: 60,
      createdOn: 1534447937149,
      createdBy: 11225731,
      updatedOn: 1534447937168,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 12231911,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.465053453863717,
          41.20505508020776
        ]
      },
      azymuth: 218,
      speed: 60,
      createdOn: 1476637458103,
      createdBy: 20650508,
      updatedOn: 1534446072636,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11222681,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.347759624300075,
          41.752582086537245
        ]
      },
      azymuth: 167,
      createdOn: 1464016259338,
      createdBy: 164222588,
      updatedOn: 1592643053153,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11850305,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.137755168800293,
          41.58155630977549
        ]
      },
      azymuth: 332,
      speed: 0,
      createdOn: 1471272394957,
      createdBy: 164222588,
      updatedOn: 1471272394970,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16262253,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.389949221288,
          41.526249653403
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1720788347800,
      createdBy: 321598575,
      updatedOn: 1724253205371,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16262254,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.389938492453,
          41.526217524733
        ]
      },
      azymuth: 186,
      speed: 0,
      createdOn: 1720788368045,
      createdBy: 321598575,
      updatedOn: 1724253205372,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11231276,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.347748895464612,
          41.75263611355014
        ]
      },
      azymuth: 349,
      speed: 0,
      createdOn: 1464114738147,
      createdBy: 164222588,
      updatedOn: 1592643053152,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16252543,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.467809226456247,
          41.83188858318246
        ]
      },
      azymuth: 63,
      speed: 0,
      createdOn: 1713030431790,
      createdBy: 19910588,
      updatedOn: 1713030441359,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 16252542,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.467779718612,
          41.831881583552
        ]
      },
      azymuth: 245,
      speed: 0,
      createdOn: 1713030373619,
      createdBy: 19910588,
      updatedOn: 1713030441360,
      updatedBy: 19910588,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 11845817,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.13753653372954,
          41.58148792409195
        ]
      },
      azymuth: 149,
      createdOn: 1470583157395,
      createdBy: 164222588,
      updatedOn: 1471272394959,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16218122,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.390264996080347,
          41.58855094924692
        ]
      },
      azymuth: 151,
      speed: 0,
      createdOn: 1691054321713,
      createdBy: 19910588,
      updatedOn: 1694762847130,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16218121,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.390521147041877,
          41.588632196512826
        ]
      },
      azymuth: 330,
      speed: 0,
      createdOn: 1691054321713,
      createdBy: 19910588,
      updatedOn: 1694762847130,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10736994,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.280935921989936,
          42.20084599542314
        ]
      },
      azymuth: 252,
      speed: 140,
      createdOn: 1459373827241,
      createdBy: 193666435,
      updatedOn: 1621603734307,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10736993,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.28089300664534,
          42.200722803400964
        ]
      },
      azymuth: 70,
      speed: 140,
      createdOn: 1459373804255,
      createdBy: 193666435,
      updatedOn: 1604770428233,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125845,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.08841567713369,
          42.148419481032136
        ]
      },
      azymuth: 253,
      speed: 0,
      createdOn: 1625539796229,
      createdBy: 1238041227,
      updatedOn: 1625539796294,
      updatedBy: 1238041227,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14375583,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.20914987055262,
          42.17692735853854
        ]
      },
      azymuth: 254,
      speed: 50,
      createdOn: 1499772586686,
      createdBy: 164222588,
      updatedOn: 1604935873043,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16202419,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.079878071045137,
          42.361372530448016
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1682323284036,
      createdBy: 1415399428,
      updatedOn: 1682323284049,
      updatedBy: 1415399428,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16202418,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.079725977385262,
          42.36116494571331
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1682323266139,
      createdBy: 1415399428,
      updatedOn: 1682323266157,
      updatedBy: 1415399428,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16171472,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.183641785580434,
          42.081513167647884
        ]
      },
      azymuth: 294,
      speed: 0,
      createdOn: 1663668828606,
      createdBy: 164222588,
      updatedOn: 1667235954248,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14387153,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.20918004540409,
          42.1769368001985
        ]
      },
      azymuth: 71,
      speed: 0,
      createdOn: 1499882433504,
      createdBy: 164222588,
      updatedOn: 1673000433578,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16081861,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.121277768695,
          42.199737351799
        ]
      },
      azymuth: 289,
      speed: 0,
      createdOn: 1600072667228,
      createdBy: 1238041227,
      updatedOn: 1721460466245,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16081862,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.121163774812,
          42.199486989174
        ]
      },
      azymuth: 106,
      speed: 0,
      createdOn: 1600072667228,
      createdBy: 1238041227,
      updatedOn: 1721460466246,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15881540,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.183614006737226,
          42.08147244328467
        ]
      },
      azymuth: 112,
      speed: 0,
      createdOn: 1565877842527,
      createdBy: 755311072,
      updatedOn: 1606750952264,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14518341,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.41224883516999,
          42.02188868408155
        ]
      },
      azymuth: 147,
      speed: 0,
      createdOn: 1500994514699,
      createdBy: 755311072,
      updatedOn: 1593624666876,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15880232,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.346708861208338,
          42.203512251728014
        ]
      },
      azymuth: 60,
      speed: 0,
      createdOn: 1563743920402,
      createdBy: 1197572217,
      updatedOn: 1599852730892,
      updatedBy: 1238041227,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 11632077,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.208397944441,
          42.088901002638
        ]
      },
      azymuth: 49,
      createdOn: 1468339180220,
      createdBy: 164222588,
      updatedOn: 1723439838139,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11632078,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.208306749335,
          42.08896768377
        ]
      },
      azymuth: 232,
      createdOn: 1468339685118,
      createdBy: 164222588,
      updatedOn: 1723439838139,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16241751,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.338912106097723,
          42.854186236176226
        ]
      },
      azymuth: 175,
      speed: 0,
      createdOn: 1706366510540,
      createdBy: 1498591580,
      updatedOn: 1706366510554,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10722496,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.435415475597303,
          42.61364801921156
        ]
      },
      azymuth: 291,
      speed: 0,
      createdOn: 1459238027015,
      createdBy: 10994850,
      updatedOn: 1695579179876,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16236050,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.338912106098,
          42.854186236176
        ]
      },
      azymuth: 107,
      speed: 0,
      createdOn: 1702412629189,
      createdBy: 1498591580,
      updatedOn: 1706366510555,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16236049,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.338905018016,
          42.854189311034
        ]
      },
      azymuth: 277,
      speed: 0,
      createdOn: 1702412629189,
      createdBy: 1498591580,
      updatedOn: 1706366510554,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 13972349,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.379966677246056,
          42.654150950215865
        ]
      },
      azymuth: 179,
      speed: 60,
      createdOn: 1495963021834,
      createdBy: 164222588,
      updatedOn: 1604395766728,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16145405,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.333718041815473,
          42.82219854130071
        ]
      },
      azymuth: 253,
      speed: 0,
      createdOn: 1641903017423,
      createdBy: 19910588,
      updatedOn: 1686868323787,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16136344,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.32346353360919,
          42.71564985021892
        ]
      },
      azymuth: 292,
      speed: 0,
      createdOn: 1633381833896,
      createdBy: 1184496663,
      updatedOn: 1686502930985,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16136345,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.323464204161127,
          42.71564935754407
        ]
      },
      azymuth: 116,
      speed: 0,
      createdOn: 1633381833896,
      createdBy: 1184496663,
      updatedOn: 1686502930985,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12846100,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.118621533123,
          42.633384601564
        ]
      },
      azymuth: 94,
      speed: 0,
      createdOn: 1484059127544,
      createdBy: 164222588,
      updatedOn: 1723879905040,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16234951,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.316994040091,
          42.86869429519
        ]
      },
      azymuth: 166,
      speed: 0,
      createdOn: 1701678949360,
      createdBy: 1498591580,
      updatedOn: 1701678949373,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16234563,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.316982403696,
          42.86871985044
        ]
      },
      azymuth: 346,
      speed: 0,
      createdOn: 1701412055065,
      createdBy: 1498591580,
      updatedOn: 1712392139958,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16251875,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.317036032573927,
          42.86870991323858
        ]
      },
      azymuth: 97,
      speed: 0,
      createdOn: 1712392139886,
      createdBy: 1498591580,
      updatedOn: 1712392139970,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16251874,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          25.316952899396,
          42.868680533833
        ]
      },
      azymuth: 220,
      speed: 0,
      createdOn: 1712392139886,
      createdBy: 1498591580,
      updatedOn: 1712392139968,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15106384,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.475008852466,
          42.980258243371
        ]
      },
      azymuth: 205,
      createdOn: 1505916816500,
      createdBy: 739390538,
      updatedOn: 1723314573140,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15183504,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.475037015661,
          42.980285715196
        ]
      },
      azymuth: 33,
      speed: 0,
      createdOn: 1506627488652,
      createdBy: 792191586,
      updatedOn: 1723314573141,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16210188,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.333718041815473,
          42.82219854130071
        ]
      },
      azymuth: 74,
      speed: 0,
      createdOn: 1686695899062,
      createdBy: 1184496663,
      updatedOn: 1686868323786,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10722495,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.435304163923007,
          42.613473325831585
        ]
      },
      azymuth: 111,
      speed: 0,
      createdOn: 1459238027015,
      createdBy: 10994850,
      updatedOn: 1695579179877,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13975752,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.379969359455597,
          42.65421012967269
        ]
      },
      azymuth: 0,
      speed: 60,
      createdOn: 1495992686299,
      createdBy: 164222588,
      updatedOn: 1604395766729,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12845082,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.118586664405,
          42.633392494796
        ]
      },
      azymuth: 274,
      speed: 0,
      createdOn: 1484043074386,
      createdBy: 164222588,
      updatedOn: 1723879905040,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16164439,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.34868987748823,
          43.198260193901724
        ]
      },
      azymuth: 318,
      speed: 0,
      createdOn: 1657556149979,
      createdBy: 1184496663,
      updatedOn: 1657556150013,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15885616,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.21534768114966,
          43.04282273764723
        ]
      },
      azymuth: 73,
      speed: 0,
      createdOn: 1573113501079,
      createdBy: 19910588,
      updatedOn: 1573113885097,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15372713,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.021128266664018,
          43.44534311219112
        ]
      },
      azymuth: 84,
      speed: 0,
      createdOn: 1508422801539,
      createdBy: 19910588,
      updatedOn: 1590763277001,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15999959,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.48603957083009,
          43.08895586366355
        ]
      },
      azymuth: 282,
      speed: 0,
      createdOn: 1589378116757,
      createdBy: 19910588,
      updatedOn: 1589378117181,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11568760,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.010827989597633,
          43.03307351110492
        ]
      },
      azymuth: 98,
      createdOn: 1467726416242,
      createdBy: 164222588,
      updatedOn: 1668064023504,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11592088,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.082549034534,
          43.031849711607
        ]
      },
      azymuth: 295,
      speed: 0,
      createdOn: 1467970208734,
      createdBy: 164222588,
      updatedOn: 1703856576606,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11568761,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.082426323471,
          43.031693351458
        ]
      },
      azymuth: 115,
      speed: 0,
      createdOn: 1467726596526,
      createdBy: 164222588,
      updatedOn: 1703856576607,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11568762,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.102468407567,
          43.009996243959
        ]
      },
      azymuth: 356,
      createdOn: 1467726837119,
      createdBy: 164222588,
      updatedOn: 1723369405053,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11582396,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.010744841118104,
          43.03308723521412
        ]
      },
      azymuth: 279,
      createdOn: 1467873720182,
      createdBy: 322559152,
      updatedOn: 1668064023505,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16164440,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.348691218593,
          43.198260193901724
        ]
      },
      azymuth: 134,
      speed: 0,
      createdOn: 1657556149979,
      createdBy: 1184496663,
      updatedOn: 1657556150017,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14492773,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.358041083183895,
          43.416381735575314
        ]
      },
      azymuth: 271,
      createdOn: 1500785239610,
      createdBy: 321729870,
      updatedOn: 1574787400899,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 141915,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.483411519264678,
          43.08937737838471
        ]
      },
      azymuth: 95,
      speed: 60,
      createdOn: 1310216665000,
      createdBy: 12461634,
      updatedOn: 1589379813335,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 229818,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.483347146248324,
          43.089380316565936
        ]
      },
      azymuth: 275,
      speed: 60,
      createdOn: 1320433130000,
      createdBy: -2,
      updatedOn: 1589379813335,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11592073,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.102468407567,
          43.009958978785
        ]
      },
      azymuth: 176,
      speed: 0,
      createdOn: 1467969992361,
      createdBy: 164222588,
      updatedOn: 1723369405053,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2810254,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.344512,
          44.654713
        ]
      },
      azymuth: 296,
      speed: 130,
      createdOn: 1375632171393,
      createdBy: 11225731,
      updatedOn: 1510748872897,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2943664,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.331984,
          44.659063
        ]
      },
      azymuth: 116,
      speed: 130,
      createdOn: 1377013514101,
      createdBy: 11225731,
      updatedOn: 1512329924032,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2902210,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.351132,
          44.652697
        ]
      },
      azymuth: 291,
      speed: 130,
      createdOn: 1376633089912,
      createdBy: 11225731,
      updatedOn: 1512108882789,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2902211,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.325167734020763,
          44.66156331330529
        ]
      },
      azymuth: 117,
      speed: 130,
      createdOn: 1376633197278,
      createdBy: 11225731,
      updatedOn: 1587135488884,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11082246,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.530134285878678,
          41.01633086276236
        ]
      },
      azymuth: 315,
      speed: 130,
      createdOn: 1462658429488,
      createdBy: 20650508,
      updatedOn: 1462658575651,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16187460,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.568903411044868,
          41.80446099044757
        ]
      },
      azymuth: 23,
      speed: 0,
      createdOn: 1678307644583,
      createdBy: 19910588,
      updatedOn: 1678307771704,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11220301,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.558676409532865,
          41.90163447396754
        ]
      },
      azymuth: 195,
      createdOn: 1463998914409,
      createdBy: 164222588,
      updatedOn: 1593110321025,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11234509,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.558865505267608,
          41.9015925506448
        ]
      },
      azymuth: 16,
      speed: 0,
      createdOn: 1464149861200,
      createdBy: 164222588,
      updatedOn: 1593110321026,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267038,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.88255880301267,
          41.95792445440714
        ]
      },
      azymuth: 275,
      speed: 0,
      createdOn: 1723637634666,
      createdBy: 19910588,
      updatedOn: 1723637661316,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267039,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.882548432768377,
          41.957813577951505
        ]
      },
      azymuth: 93,
      speed: 0,
      createdOn: 1723637661300,
      createdBy: 19910588,
      updatedOn: 1723637661315,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12540771,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.565094148215532,
          41.988036870738576
        ]
      },
      azymuth: 10,
      createdOn: 1480758652388,
      createdBy: 164222588,
      updatedOn: 1639230024775,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12574930,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.56507269054282,
          41.98796709314456
        ]
      },
      azymuth: 189,
      speed: 0,
      createdOn: 1481189216103,
      createdBy: 164222588,
      updatedOn: 1639230024775,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 116866,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.65706482598874,
          42.35633989952838
        ]
      },
      azymuth: 165,
      speed: 60,
      createdOn: 1306252327000,
      createdBy: 11331703,
      updatedOn: 1669972227266,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10898467,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.689676109123248,
          42.34374437698673
        ]
      },
      azymuth: 264,
      speed: 140,
      createdOn: 1460897134705,
      createdBy: 193666435,
      updatedOn: 1493396898072,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11657910,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.5787453938484,
          42.03891107949883
        ]
      },
      azymuth: 203,
      createdOn: 1468612535379,
      createdBy: 164083852,
      updatedOn: 1663565801936,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 7894326,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.99355402217699,
          42.497743419365435
        ]
      },
      azymuth: 83,
      speed: 50,
      createdOn: 1428918996112,
      createdBy: 243652788,
      updatedOn: 1604822196481,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15883964,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.64115818657341,
          42.42664802895385
        ]
      },
      azymuth: 166,
      speed: 0,
      createdOn: 1570261541340,
      createdBy: 10994850,
      updatedOn: 1678357340347,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10735340,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.656159580447316,
          42.35951311217836
        ]
      },
      azymuth: 349,
      speed: 0,
      createdOn: 1459358436357,
      createdBy: 193666435,
      updatedOn: 1669972161953,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10737009,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.656166285970265,
          42.359471490813284
        ]
      },
      azymuth: 167,
      speed: 0,
      createdOn: 1459373944706,
      createdBy: 193666435,
      updatedOn: 1669972157058,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8193390,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.657057449913854,
          42.35635922470012
        ]
      },
      azymuth: 352,
      speed: 60,
      createdOn: 1432988141489,
      createdBy: 193666435,
      updatedOn: 1669972227262,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12574955,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.672099715218884,
          42.31007282905785
        ]
      },
      azymuth: 181,
      speed: 0,
      createdOn: 1481189683984,
      createdBy: 164222588,
      updatedOn: 1604402281453,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11017279,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.719135712437488,
          42.44045696292133
        ]
      },
      azymuth: 71,
      speed: 60,
      createdOn: 1462034874852,
      createdBy: 193666435,
      updatedOn: 1595954937897,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13987852,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.683627490996816,
          42.43172500134902
        ]
      },
      azymuth: 250,
      speed: 50,
      createdOn: 1496129388916,
      createdBy: 164222588,
      updatedOn: 1604935156021,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12574948,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.63884273590166,
          42.205270983957405
        ]
      },
      azymuth: 166,
      speed: 0,
      createdOn: 1481189602349,
      createdBy: 164222588,
      updatedOn: 1503559622062,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10772536,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.629938690082064,
          42.32970031204055
        ]
      },
      azymuth: 248,
      speed: 0,
      createdOn: 1459704952373,
      createdBy: 193666435,
      updatedOn: 1671383379279,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12541282,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.664512932540926,
          42.401730961273984
        ]
      },
      azymuth: 38,
      createdOn: 1480763486443,
      createdBy: 164222588,
      updatedOn: 1604402058129,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12574946,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.62383845867316,
          42.09349274072838
        ]
      },
      azymuth: 196,
      speed: 0,
      createdOn: 1481189556679,
      createdBy: 164222588,
      updatedOn: 1504075922478,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 13493648,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.873898,
          42.475639
        ]
      },
      azymuth: 88,
      speed: 0,
      createdOn: 1491033728304,
      createdBy: 164222588,
      updatedOn: 1718618453024,
      updatedBy: 1415399428,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12541281,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.67210239742842,
          42.31011745841774
        ]
      },
      azymuth: 356,
      createdOn: 1480762925981,
      createdBy: 164222588,
      updatedOn: 1604402281452,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12541280,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.638965326389325,
          42.205295960478935
        ]
      },
      azymuth: 343,
      createdOn: 1480762372106,
      createdBy: 164222588,
      updatedOn: 1504419053240,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 12788572,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.59384224389618,
          42.41051773851413
        ]
      },
      azymuth: 227,
      speed: 50,
      createdOn: 1483453654461,
      createdBy: 164222588,
      updatedOn: 1704130506280,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13983434,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.9934869669511,
          42.49773353134827
        ]
      },
      azymuth: 265,
      speed: 50,
      createdOn: 1496066162883,
      createdBy: 164222588,
      updatedOn: 1604822196481,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12541279,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.62403083068849,
          42.093464173900855
        ]
      },
      azymuth: 16,
      createdOn: 1480761740982,
      createdBy: 164222588,
      updatedOn: 1501578965740,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 12542106,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.66446013171506,
          42.40168127629274
        ]
      },
      azymuth: 219,
      createdOn: 1480766351254,
      createdBy: 164222588,
      updatedOn: 1605185848666,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11482249,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.579011381614706,
          42.03882825326022
        ]
      },
      azymuth: 18,
      createdOn: 1466792265377,
      createdBy: 152110456,
      updatedOn: 1663565801936,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11031297,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.719031106286142,
          42.44066084223151
        ]
      },
      azymuth: 251,
      speed: 60,
      createdOn: 1462186942077,
      createdBy: 193666435,
      updatedOn: 1595954937896,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16253587,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.848994855577,
          42.040912075782
        ]
      },
      azymuth: 42,
      speed: 0,
      createdOn: 1713682990785,
      createdBy: 19910588,
      updatedOn: 1713683060699,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16253588,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.848964345448,
          42.040891159907
        ]
      },
      azymuth: 231,
      speed: 0,
      createdOn: 1713683012110,
      createdBy: 19910588,
      updatedOn: 1713683060699,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8299667,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.593865042672768,
          42.410537542106205
        ]
      },
      azymuth: 50,
      speed: 50,
      createdOn: 1434469607612,
      createdBy: 215139511,
      updatedOn: 1704130506281,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10760074,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.629882780426094,
          42.329525145813435
        ]
      },
      azymuth: 68,
      speed: 0,
      createdOn: 1459595169926,
      createdBy: 193666435,
      updatedOn: 1671383379271,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 10721612,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.843642377919,
          42.348557879079
        ]
      },
      azymuth: 131,
      createdOn: 1459224574180,
      createdBy: 10994850,
      updatedOn: 1724258912570,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10904534,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.689618516895006,
          42.34360308029886
        ]
      },
      azymuth: 84,
      speed: 140,
      createdOn: 1460963263056,
      createdBy: 193666435,
      updatedOn: 1469274787489,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 13650341,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.57354117259761,
          42.02392049759186
        ]
      },
      azymuth: 286,
      createdOn: 1492600635680,
      createdBy: 164222588,
      updatedOn: 1662988187103,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10722389,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.843615555829,
          42.34858166685
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1459236761681,
      createdBy: 10994850,
      updatedOn: 1724258912571,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13506040,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.87389398197943,
          42.47563926801306
        ]
      },
      azymuth: 269,
      speed: 0,
      createdOn: 1491153085362,
      createdBy: 164222588,
      updatedOn: 1718618434145,
      updatedBy: 1415399428,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15877193,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.641042851585706,
          42.426630210300225
        ]
      },
      azymuth: 256,
      speed: 0,
      createdOn: 1558010730939,
      createdBy: 10994850,
      updatedOn: 1678357340347,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12696737,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.64943908640559,
          42.816221621158846
        ]
      },
      azymuth: 157,
      speed: 0,
      createdOn: 1482527570143,
      createdBy: 164222588,
      updatedOn: 1694551292361,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16129687,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.631865093156687,
          42.60536279415237
        ]
      },
      azymuth: 90,
      speed: 0,
      createdOn: 1627992348875,
      createdBy: 1524248587,
      updatedOn: 1695481145873,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13791409,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.614721226440572,
          42.99957571700506
        ]
      },
      azymuth: 259,
      speed: 0,
      createdOn: 1494005644381,
      createdBy: 164222588,
      updatedOn: 1694214973334,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8836066,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.5594811373995,
          42.547555154281866
        ]
      },
      azymuth: 142,
      speed: 0,
      createdOn: 1440827769603,
      createdBy: 173313241,
      updatedOn: 1674549204765,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 12682247,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.64943908640559,
          42.81622358865936
        ]
      },
      azymuth: 336,
      createdOn: 1482400344437,
      createdBy: 164222588,
      updatedOn: 1694551292362,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11095076,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.614721226440572,
          42.99957473617634
        ]
      },
      azymuth: 80,
      speed: 0,
      createdOn: 1462775613061,
      createdBy: 19910588,
      updatedOn: 1694214973334,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10722413,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.591095865551914,
          42.512435990213625
        ]
      },
      azymuth: 167,
      speed: 0,
      createdOn: 1459237031478,
      createdBy: 10994850,
      updatedOn: 1505647933377,
      updatedBy: 10994850,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16129688,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.63186241094805,
          42.60536476834836
        ]
      },
      azymuth: 272,
      speed: 0,
      createdOn: 1627992348875,
      createdBy: 1524248587,
      updatedOn: 1695481145873,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10722412,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.590977392716464,
          42.51241032763908
        ]
      },
      azymuth: 345,
      speed: 0,
      createdOn: 1459237031478,
      createdBy: 10994850,
      updatedOn: 1506698771257,
      updatedBy: 10994850,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 11783971,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.73704580859558,
          42.70451107197706
        ]
      },
      azymuth: 97,
      createdOn: 1469945441818,
      createdBy: -2,
      updatedOn: 1694640091646,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11893765,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.804866088859626,
          42.64894178882594
        ]
      },
      azymuth: 142,
      createdOn: 1471661519621,
      createdBy: 207920944,
      updatedOn: 1695152431086,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8831668,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.55948129765959,
          42.54755465353595
        ]
      },
      azymuth: 319,
      speed: 60,
      createdOn: 1440781006211,
      createdBy: 178927639,
      updatedOn: 1695581863698,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15864454,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.649512134843413,
          42.90689158667209
        ]
      },
      azymuth: 11,
      speed: 0,
      createdOn: 1534151549952,
      createdBy: 11225731,
      updatedOn: 1694379309761,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12696724,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.73704480276723,
          42.70451082559608
        ]
      },
      azymuth: 281,
      speed: 0,
      createdOn: 1482527481815,
      createdBy: 164222588,
      updatedOn: 1694640091647,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16223276,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.64950811153,
          42.906889622058806
        ]
      },
      azymuth: 192,
      speed: 0,
      createdOn: 1694379309733,
      createdBy: 1184496663,
      updatedOn: 1694379309767,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16173385,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.96041306617168,
          42.519782926313454
        ]
      },
      azymuth: 311,
      speed: 0,
      createdOn: 1665647574564,
      createdBy: 19910588,
      updatedOn: 1665647622519,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16224456,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.804865403070472,
          42.64894204663457
        ]
      },
      azymuth: 321,
      speed: 0,
      createdOn: 1695152431074,
      createdBy: 1184496663,
      updatedOn: 1695152431090,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11874807,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.679679626717803,
          43.46804277689777
        ]
      },
      azymuth: 82,
      speed: 0,
      createdOn: 1471438850837,
      createdBy: 193666435,
      updatedOn: 1684751844576,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16205749,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.672523976373604,
          43.472675694217315
        ]
      },
      azymuth: 158,
      speed: 0,
      createdOn: 1684135317346,
      createdBy: 1184496663,
      updatedOn: 1684135317563,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9224719,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.880931484219,
          43.158686218556
        ]
      },
      azymuth: 117,
      speed: 60,
      createdOn: 1445597397173,
      createdBy: 281684896,
      updatedOn: 1723309842738,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11882994,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.679642075791442,
          43.46823354684351
        ]
      },
      azymuth: 263,
      speed: 0,
      createdOn: 1471533583450,
      createdBy: 193666435,
      updatedOn: 1684751844577,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15133869,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.666170671582513,
          43.14675105070356
        ]
      },
      azymuth: 181,
      createdOn: 1506167230852,
      createdBy: 164222588,
      updatedOn: 1653939798455,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 15183530,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.52791644420446,
          43.01854593855748
        ]
      },
      azymuth: 159,
      speed: 0,
      createdOn: 1506627647869,
      createdBy: 792191586,
      updatedOn: 1683573147174,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15170985,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.641199592681794,
          43.29627762137514
        ]
      },
      azymuth: 195,
      speed: 0,
      createdOn: 1506516649634,
      createdBy: 164222588,
      updatedOn: 1694033131163,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16170872,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.678823466693764,
          43.07372468796195
        ]
      },
      azymuth: 56,
      speed: 0,
      createdOn: 1662932707083,
      createdBy: 1184496663,
      updatedOn: 1662932707140,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16170873,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.678823466693764,
          43.07372468796195
        ]
      },
      azymuth: 239,
      speed: 0,
      createdOn: 1662932707083,
      createdBy: 1184496663,
      updatedOn: 1662932707144,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 3220197,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.637080044855118,
          43.33769019915005
        ]
      },
      azymuth: 353,
      speed: 50,
      createdOn: 1380383553388,
      createdBy: 14143113,
      updatedOn: 1694028037384,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13724229,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.621865213775973,
          43.189119781537535
        ]
      },
      azymuth: 186,
      createdOn: 1493346909667,
      createdBy: 154470400,
      updatedOn: 1694116698532,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14211532,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.619485572847427,
          43.180315491756815
        ]
      },
      azymuth: 355,
      createdOn: 1498317883897,
      createdBy: 353467617,
      updatedOn: 1694124419105,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16222626,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.636889608014705,
          43.33816815017252
        ]
      },
      azymuth: 173,
      speed: 0,
      createdOn: 1694028037361,
      createdBy: 1184496663,
      updatedOn: 1694028037383,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16222625,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.636889608015608,
          43.3381691255791
        ]
      },
      azymuth: 354,
      speed: 0,
      createdOn: 1694028037361,
      createdBy: 1184496663,
      updatedOn: 1694028037381,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13431963,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.634490207402706,
          43.054173458552
        ]
      },
      azymuth: 175,
      createdOn: 1489928272610,
      createdBy: 255555256,
      updatedOn: 1694212602428,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11955373,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.67110262811289,
          43.46697129934618
        ]
      },
      azymuth: 226,
      speed: 0,
      createdOn: 1472415579993,
      createdBy: 19910588,
      updatedOn: 1588829420145,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13449872,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.634491548506574,
          43.054172968573965
        ]
      },
      azymuth: 356,
      createdOn: 1490544063585,
      createdBy: 306963193,
      updatedOn: 1694212602427,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11439089,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.726572737007,
          43.086397130877
        ]
      },
      azymuth: 167,
      createdOn: 1466334972598,
      createdBy: 339796469,
      updatedOn: 1722626358966,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14023029,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.644250272351876,
          43.37190906343671
        ]
      },
      azymuth: 5,
      createdOn: 1496490009423,
      createdBy: 428745940,
      updatedOn: 1593961440481,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11920980,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.641199592681794,
          43.29627908549369
        ]
      },
      azymuth: 15,
      createdOn: 1471998642317,
      createdBy: 307908826,
      updatedOn: 1694033131164,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11032971,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.613219425081,
          43.134156489284
        ]
      },
      azymuth: 355,
      createdOn: 1462187957888,
      createdBy: 295180603,
      updatedOn: 1722626024801,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369548,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.672523976373604,
          43.47267666745982
        ]
      },
      azymuth: 338,
      speed: 0,
      createdOn: 1508397344849,
      createdBy: 19910588,
      updatedOn: 1684135317559,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125904,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.67111473175342,
          43.46697663572201
        ]
      },
      azymuth: 50,
      speed: 0,
      createdOn: 1625603264504,
      createdBy: 1184496663,
      updatedOn: 1625603264539,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15170958,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.666167989372973,
          43.1467530076557
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1506516443920,
      createdBy: 164222588,
      updatedOn: 1653939798455,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 15066440,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.527899009846053,
          43.018590062195486
        ]
      },
      azymuth: 347,
      createdOn: 1505548614611,
      createdBy: 710233766,
      updatedOn: 1683573147174,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10939415,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.613221436739,
          43.134155999945
        ]
      },
      azymuth: 169,
      createdOn: 1461301752890,
      createdBy: 186759469,
      updatedOn: 1722626024801,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10816662,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.637081385959885,
          43.33769019915005
        ]
      },
      azymuth: 175,
      speed: 50,
      createdOn: 1460113205497,
      createdBy: 312101899,
      updatedOn: 1694028037384,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14006118,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.621866554880743,
          43.189119292638395
        ]
      },
      azymuth: 6,
      createdOn: 1496325672362,
      createdBy: 739096347,
      updatedOn: 1694116698531,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119489,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.610171824693747,
          43.44211480025019
        ]
      },
      azymuth: 237,
      speed: 0,
      createdOn: 1620131348402,
      createdBy: 1184496663,
      updatedOn: 1627507178330,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119488,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.610173549256075,
          43.44211485683195
        ]
      },
      azymuth: 57,
      speed: 0,
      createdOn: 1620131348402,
      createdBy: 1184496663,
      updatedOn: 1684086987985,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16265101,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.72657207003503,
          43.08639713574039
        ]
      },
      azymuth: 343,
      speed: 0,
      createdOn: 1722626358952,
      createdBy: 1184496663,
      updatedOn: 1722626358970,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15170975,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.61948624339936,
          43.180315491756815
        ]
      },
      azymuth: 176,
      speed: 0,
      createdOn: 1506516544730,
      createdBy: 164222588,
      updatedOn: 1694124419105,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8666808,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.682227831279,
          43.075172893685
        ]
      },
      azymuth: 59,
      speed: 60,
      createdOn: 1438958786148,
      createdBy: 227396993,
      updatedOn: 1723308953462,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14628770,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.644264421004454,
          43.37182717478662
        ]
      },
      azymuth: 186,
      createdOn: 1501908346432,
      createdBy: 258074153,
      updatedOn: 1593961440482,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15369559,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.74377844705506,
          43.45367736929497
        ]
      },
      azymuth: 319,
      speed: 0,
      createdOn: 1508397392094,
      createdBy: 19910588,
      updatedOn: 1508397392047,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16108624,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.906531342379267,
          43.756411949072564
        ]
      },
      azymuth: 342,
      speed: 0,
      createdOn: 1613043518781,
      createdBy: 163790455,
      updatedOn: 1621442705365,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 3247926,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.975839851398,
          43.84139081434
        ]
      },
      azymuth: 65,
      speed: 50,
      createdOn: 1380790047640,
      createdBy: 26562146,
      updatedOn: 1721216921397,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341869,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.982082364229424,
          43.81181932346823
        ]
      },
      azymuth: 167,
      speed: 0,
      createdOn: 1508111605588,
      createdBy: 320401907,
      updatedOn: 1592685690063,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14176818,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.82201283162106,
          43.571845980895205
        ]
      },
      azymuth: 188,
      createdOn: 1498012760823,
      createdBy: 160392248,
      updatedOn: 1627594998952,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16129084,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.982081098007317,
          43.811820188799196
        ]
      },
      azymuth: 350,
      speed: 0,
      createdOn: 1627580274105,
      createdBy: 1184496663,
      updatedOn: 1627580274142,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341895,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.91438303887638,
          43.78979778590131
        ]
      },
      azymuth: 192,
      speed: 0,
      createdOn: 1508111767281,
      createdBy: 320401907,
      updatedOn: 1625768070854,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15884063,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.979040473014024,
          43.9434828838481
        ]
      },
      azymuth: 6,
      speed: 0,
      createdOn: 1570466817956,
      createdBy: 258350245,
      updatedOn: 1617608380056,
      updatedBy: 11225731,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 11889210,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.82201404068516,
          43.5718465185279
        ]
      },
      azymuth: 7,
      createdOn: 1471615310480,
      createdBy: 355083700,
      updatedOn: 1684014337038,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15873405,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.872495366736302,
          43.639701950134295
        ]
      },
      azymuth: 198,
      speed: 0,
      createdOn: 1550393456756,
      createdBy: 19910588,
      updatedOn: 1683927834058,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13920421,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.872890588502884,
          43.64113199124989
        ]
      },
      azymuth: 185,
      speed: 90,
      createdOn: 1495380202788,
      createdBy: 363606583,
      updatedOn: 1670920624889,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 3186371,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.87289008007799,
          43.641138070725795
        ]
      },
      azymuth: 5,
      speed: 90,
      createdOn: 1379929045700,
      createdBy: 12046585,
      updatedOn: 1627814161547,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15885638,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.998170565552364,
          43.896642625701844
        ]
      },
      azymuth: 327,
      speed: 0,
      createdOn: 1573195451519,
      createdBy: 258350245,
      updatedOn: 1687449850898,
      updatedBy: 258350245,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 15885639,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.99832613367506,
          43.8964754401994
        ]
      },
      azymuth: 146,
      speed: 0,
      createdOn: 1573195470371,
      createdBy: 258350245,
      updatedOn: 1687449850901,
      updatedBy: 258350245,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 11591366,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.906534024588808,
          43.75640565277107
        ]
      },
      azymuth: 166,
      createdOn: 1467957648406,
      createdBy: 289427511,
      updatedOn: 1621442705366,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16126176,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.931162267923778,
          43.82014333923204
        ]
      },
      azymuth: 190,
      speed: 0,
      createdOn: 1625767621548,
      createdBy: 1184496663,
      updatedOn: 1625767621591,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16079458,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          25.975840137599,
          43.841389058684
        ]
      },
      azymuth: 243,
      speed: 60,
      createdOn: 1599145680259,
      createdBy: 1184496663,
      updatedOn: 1714503003565,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16126177,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.931391596793517,
          43.82020768655952
        ]
      },
      azymuth: 33,
      speed: 0,
      createdOn: 1625767621548,
      createdBy: 1184496663,
      updatedOn: 1625767621591,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15144447,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.914385050533085,
          43.78980069026466
        ]
      },
      azymuth: 13,
      createdOn: 1506257961566,
      createdBy: 439916913,
      updatedOn: 1625768070854,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16205483,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.872495366736302,
          43.63970340595781
        ]
      },
      azymuth: 14,
      speed: 0,
      createdOn: 1683927834025,
      createdBy: 1184496663,
      updatedOn: 1683927834065,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16236150,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.99452367034144,
          44.33927447500655
        ]
      },
      azymuth: 311,
      speed: 0,
      createdOn: 1702503714644,
      createdBy: 11225731,
      updatedOn: 1702503714660,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236149,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.976198818351484,
          44.351794227859806
        ]
      },
      azymuth: 306,
      speed: 0,
      createdOn: 1702503690147,
      createdBy: 11225731,
      updatedOn: 1702503690164,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 13886168,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.607631,
          44.100941
        ]
      },
      azymuth: 252,
      createdOn: 1494572876810,
      createdBy: 331095216,
      updatedOn: 1512225436177,
      updatedBy: 260211201,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 2810253,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.838779253967285,
          44.467208433553175
        ]
      },
      azymuth: 269,
      speed: 130,
      createdOn: 1375630844572,
      createdBy: 11225731,
      updatedOn: 1512104799270,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8726818,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.960424,
          44.355198
        ]
      },
      azymuth: 225,
      createdOn: 1439618531438,
      createdBy: 268236957,
      updatedOn: 1512205180013,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16097730,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.943116070746,
          44.336238894556985
        ]
      },
      azymuth: 11,
      speed: 0,
      createdOn: 1606142280417,
      createdBy: 11225731,
      updatedOn: 1606142280427,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16048286,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.502996753131278,
          44.89376545249391
        ]
      },
      azymuth: 143,
      speed: 0,
      createdOn: 1594186730927,
      createdBy: 11225731,
      updatedOn: 1594186730950,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5488984,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.99250462432854,
          44.91069981289056
        ]
      },
      azymuth: 148,
      createdOn: 1405859124461,
      createdBy: 171787081,
      updatedOn: 1505856763726,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 7096812,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.56565000859834,
          45.487095934178996
        ]
      },
      azymuth: 28,
      createdOn: 1420799802225,
      createdBy: 178616431,
      updatedOn: 1508318426674,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 3083850,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.544164003498,
          45.367880511278
        ]
      },
      azymuth: 149,
      speed: 70,
      createdOn: 1378647482200,
      createdBy: 11225731,
      updatedOn: 1714453642185,
      updatedBy: 1175159865,
      permissions: -1,
      rank: 4,
      lockRank: 4
    },
    {
      id: 3134538,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.624088,
          45.268263
        ]
      },
      azymuth: 182,
      speed: 70,
      createdOn: 1379261579381,
      createdBy: 11225731,
      updatedOn: 1512305876755,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9112429,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.81972800741359,
          45.032859688732835
        ]
      },
      azymuth: 317,
      createdOn: 1444379077194,
      createdBy: 274522421,
      updatedOn: 1724316894969,
      updatedBy: 1175159865,
      permissions: 0,
      rank: 6,
      lockRank: 6
    },
    {
      id: 3123560,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.554641,
          45.444803
        ]
      },
      azymuth: 12,
      speed: 70,
      createdOn: 1379148116471,
      createdBy: 11225731,
      updatedOn: 1511110901190,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16268497,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.819628272943724,
          45.03279002230449
        ]
      },
      azymuth: 137,
      createdOn: 1724316894847,
      createdBy: 1175159865,
      updatedOn: 1724316894953,
      updatedBy: 1175159865,
      permissions: 0,
      rank: 6,
      lockRank: 6
    },
    {
      id: 3313656,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.72529620370485,
          45.12165216433128
        ]
      },
      azymuth: 138,
      speed: 70,
      createdOn: 1381684443592,
      createdBy: 36063847,
      updatedOn: 1501871441980,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9293703,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.760523,
          45.099989
        ]
      },
      azymuth: 150,
      createdOn: 1446238486108,
      createdBy: 186318893,
      updatedOn: 1501436201269,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 3074181,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.543586,
          45.42015
        ]
      },
      azymuth: 59,
      speed: 70,
      createdOn: 1378547525450,
      createdBy: 11225731,
      updatedOn: 1512526002737,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10487030,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.672626195106517,
          45.194516492160005
        ]
      },
      azymuth: 328,
      createdOn: 1457102418440,
      createdBy: 221319122,
      updatedOn: 1507982978374,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15880228,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.56139016185707,
          45.33178069055984
        ]
      },
      azymuth: 309,
      speed: 0,
      createdOn: 1563742066914,
      createdBy: 11225731,
      updatedOn: 1563742066912,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 3075953,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.744132982803322,
          45.10667815069134
        ]
      },
      azymuth: 302,
      speed: 70,
      createdOn: 1378565316112,
      createdBy: 155037800,
      updatedOn: 1508125166810,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 3874276,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          25.93087483068849,
          45.36619492881371
        ]
      },
      azymuth: 0,
      createdOn: 1388940465611,
      createdBy: 36063847,
      updatedOn: 1502533355248,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10996687,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.257788673610683,
          41.029540432305424
        ]
      },
      azymuth: 179,
      speed: 50,
      createdOn: 1461854586850,
      createdBy: 251422543,
      updatedOn: 1501752287824,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11089361,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.298114519014693,
          41.154700602534454
        ]
      },
      azymuth: 15,
      speed: 50,
      createdOn: 1462725706682,
      createdBy: 20650508,
      updatedOn: 1474233025204,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11089150,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.25813000000009,
          41.020391281125015
        ]
      },
      azymuth: 357,
      speed: 50,
      createdOn: 1462724739567,
      createdBy: 20650508,
      updatedOn: 1462724816403,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 15755280,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.21863842683995,
          41.77816119208367
        ]
      },
      azymuth: 310,
      speed: 0,
      createdOn: 1512204150538,
      createdBy: 164222588,
      updatedOn: 1622537172057,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11370620,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.456519300057,
          41.649803942486
        ]
      },
      azymuth: 311,
      speed: 100,
      createdOn: 1465551943896,
      createdBy: 283601178,
      updatedOn: 1710509119285,
      updatedBy: 34018042,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 15755268,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.087645538899874,
          41.86270508397629
        ]
      },
      azymuth: 338,
      speed: 0,
      createdOn: 1512204054359,
      createdBy: 164222588,
      updatedOn: 1589733528525,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16267036,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.140704610986,
          41.832151602175
        ]
      },
      azymuth: 327,
      speed: 0,
      createdOn: 1723637495915,
      createdBy: 19910588,
      updatedOn: 1723637495933,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16267037,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.140543871750197,
          41.83216342857631
        ]
      },
      azymuth: 147,
      speed: 0,
      createdOn: 1723637495915,
      createdBy: 19910588,
      updatedOn: 1723637495933,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14971858,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.443699726540952,
          41.693711377473235
        ]
      },
      azymuth: 299,
      speed: 110,
      createdOn: 1504733098063,
      createdBy: 164222588,
      updatedOn: 1538318802724,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15634200,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.21842385011908,
          41.777981173495014
        ]
      },
      azymuth: 131,
      createdOn: 1510990267633,
      createdBy: 164222588,
      updatedOn: 1622537172056,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14971859,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.443662175614598,
          41.69365529805553
        ]
      },
      azymuth: 116,
      speed: 110,
      createdOn: 1504733098063,
      createdBy: 164222588,
      updatedOn: 1538318802823,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15634199,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.08733440265358,
          41.862629176473085
        ]
      },
      azymuth: 157,
      createdOn: 1510989814711,
      createdBy: 164222588,
      updatedOn: 1589733528526,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15634391,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.158025,
          41.774434
        ]
      },
      azymuth: 183,
      createdOn: 1510991818749,
      createdBy: 164222588,
      updatedOn: 1604087628864,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15755274,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.158150885564986,
          41.77442198886686
        ]
      },
      azymuth: 5,
      speed: 0,
      createdOn: 1512204104924,
      createdBy: 164222588,
      updatedOn: 1512204104929,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 14863048,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.347829757285485,
          42.09241707996346
        ]
      },
      azymuth: 202,
      createdOn: 1503775667680,
      createdBy: 164222588,
      updatedOn: 1606897835878,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10881858,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.07112653505703,
          42.46493845865378
        ]
      },
      azymuth: 60,
      speed: 140,
      createdOn: 1460745182660,
      createdBy: 193666435,
      updatedOn: 1466950763773,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10898466,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.071108266201076,
          42.46507541015354
        ]
      },
      azymuth: 242,
      speed: 140,
      createdOn: 1460896228393,
      createdBy: 193666435,
      updatedOn: 1460963369412,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 8448518,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.06929838657704,
          42.46432917334168
        ]
      },
      azymuth: 233,
      speed: 140,
      createdOn: 1436507195240,
      createdBy: 250836954,
      updatedOn: 1641143239778,
      updatedBy: 1266393878,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14869236,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.34785389716684,
          42.092472810362516
        ]
      },
      azymuth: 19,
      speed: 0,
      createdOn: 1503840300039,
      createdBy: 164222588,
      updatedOn: 1606897835878,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11553536,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.484126855507824,
          42.54053663796333
        ]
      },
      azymuth: 64,
      speed: 140,
      createdOn: 1467569959487,
      createdBy: 193666435,
      updatedOn: 1495543812150,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 13504863,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.235130198632,
          42.612943271492
        ]
      },
      azymuth: 243,
      speed: 0,
      createdOn: 1491145040308,
      createdBy: 164222588,
      updatedOn: 1723307678318,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16087831,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.082423658292637,
          42.53291571051299
        ]
      },
      azymuth: 232,
      speed: 0,
      createdOn: 1601580843350,
      createdBy: 19910588,
      updatedOn: 1601580843364,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11553541,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.48414294876236,
          42.54066509404122
        ]
      },
      azymuth: 241,
      speed: 140,
      createdOn: 1467569979746,
      createdBy: 193666435,
      updatedOn: 1640763539699,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12141137,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.431419833746354,
          42.525784680126094
        ]
      },
      azymuth: 305,
      speed: 90,
      createdOn: 1475501043720,
      createdBy: 164222588,
      updatedOn: 1608801082660,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12141138,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.431371553984544,
          42.52573180294435
        ]
      },
      azymuth: 120,
      speed: 90,
      createdOn: 1475501043720,
      createdBy: 164222588,
      updatedOn: 1608801082660,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16095662,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.212354340866217,
          42.634622162132565
        ]
      },
      azymuth: 77,
      speed: 0,
      createdOn: 1604914458620,
      createdBy: 19910588,
      updatedOn: 1604914458642,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13493649,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.235205300483,
          42.612817925156
        ]
      },
      azymuth: 65,
      createdOn: 1491035368349,
      createdBy: 164222588,
      updatedOn: 1723307678318,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12845081,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.392785597973702,
          42.56236183630611
        ]
      },
      azymuth: 325,
      createdOn: 1484037768630,
      createdBy: 164222588,
      updatedOn: 1625596292175,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12846122,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.3928392421537,
          42.56228874060085
        ]
      },
      azymuth: 142,
      speed: 0,
      createdOn: 1484059270273,
      createdBy: 164222588,
      updatedOn: 1625596292174,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15370069,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.446064028605765,
          42.967123179621744
        ]
      },
      azymuth: 126,
      speed: 0,
      createdOn: 1508402537525,
      createdBy: 19910588,
      updatedOn: 1508402542041,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15879736,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.284161273613126,
          43.3142489867802
        ]
      },
      azymuth: 294,
      speed: 0,
      createdOn: 1562831270854,
      createdBy: 377953647,
      updatedOn: 1604509159087,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15328005,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.414967474718267,
          43.11816622938393
        ]
      },
      azymuth: 253,
      createdOn: 1507986318901,
      createdBy: 800940653,
      updatedOn: 1589741175083,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16136378,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.129333763550164,
          43.15870166538674
        ]
      },
      azymuth: 342,
      speed: 0,
      createdOn: 1633423968111,
      createdBy: 1184496663,
      updatedOn: 1633424033871,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16136379,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.12933175189346,
          43.15870166538674
        ]
      },
      azymuth: 162,
      speed: 0,
      createdOn: 1633423968111,
      createdBy: 1184496663,
      updatedOn: 1633424033871,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15370076,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.2006633614103,
          43.35068769945018
        ]
      },
      azymuth: 95,
      speed: 0,
      createdOn: 1508402685521,
      createdBy: 19910588,
      updatedOn: 1512984298147,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15882230,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.23061637580274,
          43.61206549031615
        ]
      },
      azymuth: 295,
      speed: 0,
      createdOn: 1567007509602,
      createdBy: 1184496663,
      updatedOn: 1635333199879,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341743,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.48715784827057,
          43.521473808393786
        ]
      },
      azymuth: 36,
      speed: 0,
      createdOn: 1508110475572,
      createdBy: 320401907,
      updatedOn: 1641207881219,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16228018,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.371492852035512,
          43.815640592092784
        ]
      },
      azymuth: 94,
      speed: 0,
      createdOn: 1697225117147,
      createdBy: 1184496663,
      updatedOn: 1697225117163,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16228017,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.371487487617333,
          43.815642527498035
        ]
      },
      azymuth: 272,
      speed: 0,
      createdOn: 1697225117147,
      createdBy: 1184496663,
      updatedOn: 1697225117160,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16139761,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.245228051721625,
          43.601129896379234
        ]
      },
      azymuth: 322,
      speed: 0,
      createdOn: 1635984754913,
      createdBy: 1184496663,
      updatedOn: 1635984754970,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341862,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.019705440093656,
          43.77734767186242
        ]
      },
      azymuth: 132,
      speed: 0,
      createdOn: 1508111514183,
      createdBy: 320401907,
      updatedOn: 1684681117675,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341861,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.019704769540823,
          43.777348156023606
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1508111514183,
      createdBy: 320401907,
      updatedOn: 1684681117675,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341744,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.487129685075804,
          43.52145241434051
        ]
      },
      azymuth: 218,
      speed: 0,
      createdOn: 1508110475572,
      createdBy: 320401907,
      updatedOn: 1641207881218,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16262114,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.363639673629898,
          43.89432669842935
        ]
      },
      azymuth: 307,
      speed: 0,
      createdOn: 1720640470536,
      createdBy: 1184496663,
      updatedOn: 1720640470547,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16262115,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.36363900307707,
          43.89432573200272
        ]
      },
      azymuth: 126,
      speed: 0,
      createdOn: 1720640470536,
      createdBy: 1184496663,
      updatedOn: 1720640470549,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119508,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.01986515522239,
          43.878431159371765
        ]
      },
      azymuth: 227,
      speed: 0,
      createdOn: 1620135135125,
      createdBy: 1184496663,
      updatedOn: 1708266905500,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341192,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.491331942869355,
          43.64494518438303
        ]
      },
      azymuth: 188,
      speed: 0,
      createdOn: 1508105858902,
      createdBy: 320401907,
      updatedOn: 1508105858873,
      updatedBy: 320401907,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16119505,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.014469627059,
          43.874783503682
        ]
      },
      azymuth: 53,
      speed: 0,
      createdOn: 1620135042922,
      createdBy: 1184496663,
      updatedOn: 1708266869075,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119507,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.01986616105074,
          43.87843236772757
        ]
      },
      azymuth: 48,
      speed: 0,
      createdOn: 1620135135125,
      createdBy: 1184496663,
      updatedOn: 1708266905499,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119506,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.014468285955,
          43.87478302031
        ]
      },
      azymuth: 230,
      speed: 0,
      createdOn: 1620135042922,
      createdBy: 1184496663,
      updatedOn: 1708266869075,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15537349,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.319625026847326,
          43.5713474694532
        ]
      },
      azymuth: 142,
      speed: 0,
      createdOn: 1510013834721,
      createdBy: 320401907,
      updatedOn: 1625595691648,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15537348,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.319618321325276,
          43.57135329936792
        ]
      },
      azymuth: 321,
      speed: 0,
      createdOn: 1510013834721,
      createdBy: 320401907,
      updatedOn: 1625595691647,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341191,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.491331434287666,
          43.64494692267856
        ]
      },
      azymuth: 8,
      speed: 0,
      createdOn: 1508105858902,
      createdBy: 320401907,
      updatedOn: 1508105858870,
      updatedBy: 320401907,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 11684993,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.08708965821,
          43.910762308427
        ]
      },
      azymuth: 238,
      speed: 0,
      createdOn: 1468957149493,
      createdBy: 19910588,
      updatedOn: 1721332082138,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341916,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.092229502858338,
          43.805051934920684
        ]
      },
      azymuth: 295,
      speed: 0,
      createdOn: 1508111933846,
      createdBy: 320401907,
      updatedOn: 1680733694663,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 15341917,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.092233526171746,
          43.805049999172844
        ]
      },
      azymuth: 113,
      speed: 0,
      createdOn: 1508111933846,
      createdBy: 320401907,
      updatedOn: 1680733694662,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341906,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.087096363732,
          43.910761342267
        ]
      },
      azymuth: 56,
      speed: 0,
      createdOn: 1508111862639,
      createdBy: 320401907,
      updatedOn: 1721332082139,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119503,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.016200251881116,
          43.874685864855714
        ]
      },
      azymuth: 198,
      speed: 0,
      createdOn: 1620135000809,
      createdBy: 1184496663,
      updatedOn: 1708266889017,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119502,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.016200992979,
          43.874684895762
        ]
      },
      azymuth: 17,
      speed: 0,
      createdOn: 1620135000809,
      createdBy: 1184496663,
      updatedOn: 1708266889017,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16204171,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.375595474169423,
          43.99318845969007
        ]
      },
      azymuth: 81,
      speed: 0,
      createdOn: 1683133342507,
      createdBy: 1184496663,
      updatedOn: 1683133342523,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16204170,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.375593462512715,
          43.9931899069209
        ]
      },
      azymuth: 261,
      speed: 0,
      createdOn: 1683133342507,
      createdBy: 1184496663,
      updatedOn: 1683133342520,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119499,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.19329092545286,
          43.93961967927716
        ]
      },
      azymuth: 255,
      speed: 0,
      createdOn: 1620134095508,
      createdBy: 1184496663,
      updatedOn: 1695187257491,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16119498,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.193293607662397,
          43.939620644968414
        ]
      },
      azymuth: 76,
      speed: 0,
      createdOn: 1620134095508,
      createdBy: 1184496663,
      updatedOn: 1695187257491,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16115317,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.14926357148864,
          44.36092643556848
        ]
      },
      azymuth: 149,
      speed: 0,
      createdOn: 1617189514728,
      createdBy: 11225731,
      updatedOn: 1617189514752,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236151,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.014465894366985,
          44.328348437043964
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1702503737841,
      createdBy: 11225731,
      updatedOn: 1702503737859,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16182068,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.00014237762323,
          44.49633094162336
        ]
      },
      azymuth: 131,
      speed: 0,
      createdOn: 1673474362500,
      createdBy: 11225731,
      updatedOn: 1673474362516,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 3816638,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.010273925870365,
          44.19762631405148
        ]
      },
      azymuth: 25,
      createdOn: 1388345751260,
      createdBy: 36063847,
      updatedOn: 1617475483074,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15864887,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.241077312604226,
          44.408554678331924
        ]
      },
      azymuth: 255,
      speed: 0,
      createdOn: 1534761086197,
      createdBy: 11225731,
      updatedOn: 1562489607667,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16182973,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.13860618402608,
          44.47822032172299
        ]
      },
      azymuth: 347,
      speed: 0,
      createdOn: 1674322890423,
      createdBy: 11225731,
      updatedOn: 1674322890437,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236154,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.133726144934,
          44.328340786152
        ]
      },
      azymuth: 76,
      speed: 0,
      createdOn: 1702504076323,
      createdBy: 11225731,
      updatedOn: 1722511641413,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236153,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.060464210638678,
          44.31323506927842
        ]
      },
      azymuth: 81,
      speed: 0,
      createdOn: 1702503818074,
      createdBy: 11225731,
      updatedOn: 1702503818092,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16236152,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.0399307867537,
          44.3147259303169
        ]
      },
      azymuth: 295,
      speed: 0,
      createdOn: 1702503795430,
      createdBy: 11225731,
      updatedOn: 1702503795448,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16237095,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.158001061432,
          44.332536312965
        ]
      },
      azymuth: 81,
      speed: 0,
      createdOn: 1703092308850,
      createdBy: 11225731,
      updatedOn: 1722511770514,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16237094,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.109470036091,
          44.323625711915
        ]
      },
      azymuth: 78,
      speed: 0,
      createdOn: 1703092293707,
      createdBy: 11225731,
      updatedOn: 1722511738984,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16237093,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.03365161633,
          44.316848955788
        ]
      },
      azymuth: 305,
      speed: 0,
      createdOn: 1703092132529,
      createdBy: 11225731,
      updatedOn: 1722511704582,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16237092,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.02839985108,
          44.319529858439
        ]
      },
      azymuth: 307,
      speed: 0,
      createdOn: 1703092115375,
      createdBy: 11225731,
      updatedOn: 1722511724408,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16183905,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.191182854001983,
          44.441021059285234
        ]
      },
      azymuth: 310,
      speed: 0,
      createdOn: 1675181705033,
      createdBy: 11225731,
      updatedOn: 1675181705047,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16243246,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.189379745393,
          44.342684975542
        ]
      },
      azymuth: 78,
      speed: 0,
      createdOn: 1707247127970,
      createdBy: 11225731,
      updatedOn: 1722511624160,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16252965,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.255761066837017,
          44.40304185333516
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1713255922481,
      createdBy: 11225731,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16237096,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.208074230047,
          44.344549728312
        ]
      },
      azymuth: 85,
      speed: 0,
      createdOn: 1703092344121,
      createdBy: 11225731,
      updatedOn: 1722511753774,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16182871,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.026791036128895,
          44.39266490737832
        ]
      },
      azymuth: 247,
      speed: 0,
      createdOn: 1674213128238,
      createdBy: 11225731,
      updatedOn: 1674213128251,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16183316,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.047161167369516,
          44.37732382105629
        ]
      },
      azymuth: 189,
      speed: 0,
      createdOn: 1674721493633,
      createdBy: 11225731,
      updatedOn: 1674721493648,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16252885,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.180507986835,
          44.340496781816
        ]
      },
      azymuth: 68,
      speed: 0,
      createdOn: 1713196971990,
      createdBy: 11225731,
      updatedOn: 1722511688812,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16252884,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.264695822279,
          44.389245289675
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1713196846900,
      createdBy: 11225731,
      updatedOn: 1713196846914,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16252883,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.244040189995,
          44.35444564718
        ]
      },
      azymuth: 227,
      speed: 0,
      createdOn: 1713196824481,
      createdBy: 11225731,
      updatedOn: 1723484935944,
      updatedBy: 105774162,
      permissions: 0,
      rank: 6,
      lockRank: "None"
    },
    {
      id: 16182430,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.151030586036,
          44.495573347201
        ]
      },
      azymuth: 204,
      speed: 0,
      createdOn: 1673878267153,
      createdBy: 11225731,
      updatedOn: 1723484826197,
      updatedBy: 105774162,
      permissions: 0,
      rank: 6,
      lockRank: "None"
    },
    {
      id: 16182431,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.125482755811944,
          44.4787100884643
        ]
      },
      azymuth: 84,
      speed: 0,
      createdOn: 1673878342586,
      createdBy: 11225731,
      updatedOn: 1673878342598,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16181785,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.000666320323237,
          44.49616226396153
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1673256324972,
      createdBy: 11225731,
      updatedOn: 1673474362509,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 7018267,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.078639,
          44.333297
        ]
      },
      azymuth: 13,
      createdOn: 1420128424448,
      createdBy: 223392019,
      updatedOn: 1512235235059,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2859609,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.491539,
          44.453729
        ]
      },
      azymuth: 286,
      speed: 130,
      createdOn: 1376239283687,
      createdBy: 11225731,
      updatedOn: 1500299464863,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16184138,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.009769,
          44.49046400000001
        ]
      },
      azymuth: 296,
      speed: 0,
      createdOn: 1675413948552,
      createdBy: 11225731,
      updatedOn: 1675413948568,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16184244,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.070016863466513,
          44.57235652523538
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1675514279573,
      createdBy: 11225731,
      updatedOn: 1675514279585,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16153523,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.070265243711685,
          44.57935911368057
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1648482928121,
      createdBy: 11225731,
      updatedOn: 1648483162747,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: 5
    },
    {
      id: 5377880,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.07774475934089,
          44.846884136890104
        ]
      },
      azymuth: 353,
      createdOn: 1404800669215,
      createdBy: 192154738,
      updatedOn: 1724317124542,
      updatedBy: 1175159865,
      permissions: 0,
      rank: 6,
      lockRank: 6
    },
    {
      id: 16230585,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.136152053884675,
          44.59757471593606
        ]
      },
      azymuth: 288,
      speed: 0,
      createdOn: 1698698481588,
      createdBy: 11225731,
      updatedOn: 1698698481602,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16122660,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.069240106307745,
          44.626430894159746
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1622665093013,
      createdBy: 11225731,
      updatedOn: 1622665093051,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8481618,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.220884721565305,
          44.953595074303834
        ]
      },
      azymuth: 93,
      createdOn: 1436879256735,
      createdBy: 255665845,
      updatedOn: 1503241971766,
      updatedBy: 44520847,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16231780,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.161598455984436,
          44.584379529241005
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1699566310392,
      createdBy: 11225731,
      updatedOn: 1699566310412,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4554258,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.047309673610687,
          44.88392362871454
        ]
      },
      azymuth: 326,
      createdOn: 1396369465241,
      createdBy: 11852746,
      updatedOn: 1510490128818,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15485883,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.059327169311512,
          44.8708278811542
        ]
      },
      azymuth: 146,
      speed: 70,
      createdOn: 1509545645478,
      createdBy: 211679422,
      updatedOn: 1539437588886,
      updatedBy: 44520847,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 3073207,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.201337119049104,
          44.66955227217154
        ]
      },
      azymuth: 27,
      speed: 130,
      createdOn: 1378537890534,
      createdBy: 11225731,
      updatedOn: 1509701757755,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16267745,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.047238259797087,
          44.88393929944971
        ]
      },
      azymuth: 147,
      createdOn: 1724076067359,
      createdBy: 1175159865,
      updatedOn: 1724076067384,
      updatedBy: 1175159865,
      permissions: 0,
      rank: 6,
      lockRank: 6
    },
    {
      id: 8198886,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.070513159035617,
          44.583916091004006
        ]
      },
      azymuth: 182,
      createdOn: 1433064473747,
      createdBy: 161775637,
      updatedOn: 1543071923917,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16187373,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.147102246501,
          44.62552298282984
        ]
      },
      azymuth: 251,
      speed: 0,
      createdOn: 1678263862812,
      createdBy: 11225731,
      updatedOn: 1678263862827,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16193706,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.072117381814152,
          44.6085019314047
        ]
      },
      azymuth: 184,
      speed: 0,
      createdOn: 1680111911538,
      createdBy: 11225731,
      updatedOn: 1680112007980,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16201514,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.069017640235188,
          44.55399110064212
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1681942312275,
      createdBy: 11225731,
      updatedOn: 1681942312288,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4756840,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.076152,
          44.662848
        ]
      },
      azymuth: 5,
      createdOn: 1398503483360,
      createdBy: 11225731,
      updatedOn: 1510495495079,
      updatedBy: 36063847,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16216023,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.086309853711168,
          44.617578432927345
        ]
      },
      azymuth: 49,
      speed: 0,
      createdOn: 1689805813874,
      createdBy: 11225731,
      updatedOn: 1723484859088,
      updatedBy: 105774162,
      permissions: 0,
      rank: 6,
      lockRank: "None"
    },
    {
      id: 16241426,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.097519687955,
          44.598367982879
        ]
      },
      azymuth: 82,
      speed: 0,
      createdOn: 1706037788153,
      createdBy: 11225731,
      updatedOn: 1722511656935,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14020594,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.080646711639382,
          44.825457688618954
        ]
      },
      azymuth: 355,
      createdOn: 1496467037829,
      createdBy: 715747942,
      updatedOn: 1512207242024,
      updatedBy: 44520847,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16233680,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.222846200536,
          44.546608614016
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1700770530886,
      createdBy: 11225731,
      updatedOn: 1700770530900,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16228703,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.085003024524678,
          44.59698609668689
        ]
      },
      azymuth: 264,
      speed: 0,
      createdOn: 1697639025418,
      createdBy: 11225731,
      updatedOn: 1698258660962,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16185951,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.123013080588404,
          44.62054764489293
        ]
      },
      azymuth: 76,
      speed: 0,
      createdOn: 1676993236386,
      createdBy: 11225731,
      updatedOn: 1676993236401,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15796635,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.068963930932192,
          44.55036350177249
        ]
      },
      azymuth: 0,
      speed: 70,
      createdOn: 1512575871976,
      createdBy: 11225731,
      updatedOn: 1512575871955,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16268498,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.07755795491318,
          44.846896928656385
        ]
      },
      azymuth: 173,
      createdOn: 1724317124506,
      createdBy: 1175159865,
      updatedOn: 1724317124532,
      updatedBy: 1175159865,
      permissions: 0,
      rank: 6,
      lockRank: 6
    },
    {
      id: 8774261,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.109198425760326,
          44.726896047186564
        ]
      },
      azymuth: 28,
      createdOn: 1440145019120,
      createdBy: 246976708,
      updatedOn: 1589359940241,
      updatedBy: 44520847,
      permissions: -1,
      rank: 3,
      lockRank: 3
    },
    {
      id: 2981788,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.069730830688492,
          44.56717665392055
        ]
      },
      azymuth: 182,
      speed: 70,
      createdOn: 1377451653635,
      createdBy: 11225731,
      updatedOn: 1512466705365,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8116239,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.07232391191,
          44.60890292989183
        ]
      },
      azymuth: 4,
      speed: 0,
      createdOn: 1431872099919,
      createdBy: 11225731,
      updatedOn: 1680111967531,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16230017,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.12433133232353,
          44.599862009620885
        ]
      },
      azymuth: 275,
      speed: 0,
      createdOn: 1698258601022,
      createdBy: 11225731,
      updatedOn: 1698258601037,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16088973,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.069351891173426,
          44.63934453541114
        ]
      },
      azymuth: 83,
      speed: 0,
      createdOn: 1601910704067,
      createdBy: 11225731,
      updatedOn: 1601910704032,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16233679,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.109387629093,
          44.623035067474
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1700770225965,
      createdBy: 11225731,
      updatedOn: 1700770225977,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16122637,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.069412346645592,
          44.62296583717145
        ]
      },
      azymuth: 358,
      speed: 0,
      createdOn: 1622645827936,
      createdBy: 358960284,
      updatedOn: 1622645827971,
      updatedBy: 358960284,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15881165,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.08185153990105,
          44.50596682594871
        ]
      },
      azymuth: 9,
      speed: 0,
      createdOn: 1565258716334,
      createdBy: 11225731,
      updatedOn: 1565258716313,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16254940,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.523200757756292,
          41.396125779419165
        ]
      },
      azymuth: 220,
      speed: 0,
      createdOn: 1714590440291,
      createdBy: 15720180,
      updatedOn: 1714590440336,
      updatedBy: 15720180,
      permissions: -1,
      rank: 4,
      lockRank: 4
    },
    {
      id: 11089376,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.523081836805368,
          41.39582663918237
        ]
      },
      azymuth: 43,
      speed: 0,
      createdOn: 1462725767851,
      createdBy: 20650508,
      updatedOn: 1714590440330,
      updatedBy: 15720180,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 11089408,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.53617034970297,
          41.40664085543811
        ]
      },
      azymuth: 218,
      speed: 0,
      createdOn: 1462726053485,
      createdBy: 20650508,
      updatedOn: 1504363252698,
      updatedBy: 20650508,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16134358,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.5836524568647,
          41.68222272320403
        ]
      },
      azymuth: 226,
      speed: 50,
      createdOn: 1631826600542,
      createdBy: 1524248587,
      updatedOn: 1631826600583,
      updatedBy: 1524248587,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16134359,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.583705449786777,
          41.68196615250497
        ]
      },
      azymuth: 48,
      speed: 50,
      createdOn: 1631826638545,
      createdBy: 1524248587,
      updatedOn: 1631913394830,
      updatedBy: 1524248587,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15867088,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.577456209280562,
          41.663307333587795
        ]
      },
      azymuth: 315,
      speed: 50,
      createdOn: 1538319176513,
      createdBy: 164222588,
      updatedOn: 1538319176502,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14867466,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.55658278599893,
          41.67643339615429
        ]
      },
      azymuth: 145,
      speed: 0,
      createdOn: 1503828096943,
      createdBy: 164222588,
      updatedOn: 1585251189582,
      updatedBy: 88679,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14867467,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.556772117562996,
          41.676456231537216
        ]
      },
      azymuth: 243,
      speed: 0,
      createdOn: 1503828096943,
      createdBy: 164222588,
      updatedOn: 1503828096922,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16134495,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.610632670097047,
          41.63657901935501
        ]
      },
      azymuth: 309,
      speed: 70,
      createdOn: 1631913007601,
      createdBy: 1524248587,
      updatedOn: 1631913007646,
      updatedBy: 1524248587,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14867468,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.55675065989118,
          41.676380103330004
        ]
      },
      azymuth: 323,
      speed: 0,
      createdOn: 1503828096943,
      createdBy: 164222588,
      updatedOn: 1503828096924,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 14867469,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.556592173730518,
          41.67635626623196
        ]
      },
      azymuth: 54,
      speed: 0,
      createdOn: 1503828096943,
      createdBy: 164222588,
      updatedOn: 1585251189612,
      updatedBy: 88679,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14867478,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.552928512045128,
          41.6769610794742
        ]
      },
      azymuth: 268,
      speed: 0,
      createdOn: 1503828158585,
      createdBy: 164222588,
      updatedOn: 1503828158559,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 14867479,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.55284360496557,
          41.6770130691064
        ]
      },
      azymuth: 176,
      speed: 0,
      createdOn: 1503828158585,
      createdBy: 164222588,
      updatedOn: 1503828158563,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16134496,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.60973681228631,
          41.63681155409806
        ]
      },
      azymuth: 135,
      speed: 50,
      createdOn: 1631913007601,
      createdBy: 1524248587,
      updatedOn: 1631913007646,
      updatedBy: 1524248587,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14867477,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          26.552791719385606,
          41.676951062688616
        ]
      },
      azymuth: 86,
      speed: 0,
      createdOn: 1503828158585,
      createdBy: 164222588,
      updatedOn: 1503828158555,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15867087,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.577327463247855,
          41.663207144413555
        ]
      },
      azymuth: 135,
      speed: 50,
      createdOn: 1538319158492,
      createdBy: 164222588,
      updatedOn: 1538319158481,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15867084,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.525016340829733,
          41.67756266360907
        ]
      },
      azymuth: 293,
      speed: 50,
      createdOn: 1538318884563,
      createdBy: 164222588,
      updatedOn: 1538318884561,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15867085,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.524992200948375,
          41.67750657011418
        ]
      },
      azymuth: 111,
      speed: 50,
      createdOn: 1538318905828,
      createdBy: 164222588,
      updatedOn: 1538318905822,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16208391,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.607423479245522,
          42.29392405466649
        ]
      },
      azymuth: 319,
      speed: 0,
      createdOn: 1685433023388,
      createdBy: 19910588,
      updatedOn: 1685433173325,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13101507,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.706309009556424,
          42.154114549247886
        ]
      },
      azymuth: 89,
      speed: 50,
      createdOn: 1486915968296,
      createdBy: 264515630,
      updatedOn: 1640510717731,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11647988,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.53781296893993,
          42.12582502700696
        ]
      },
      azymuth: 29,
      speed: 50,
      createdOn: 1468511564746,
      createdBy: 155398034,
      updatedOn: 1662127136449,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16160075,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.66252913625895,
          42.16898610097305
        ]
      },
      azymuth: 94,
      speed: 0,
      createdOn: 1653500101098,
      createdBy: 1238041227,
      updatedOn: 1653500101145,
      updatedBy: 1238041227,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16170064,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.537787768477905,
          42.12579830736071
        ]
      },
      azymuth: 217,
      speed: 50,
      createdOn: 1662127136417,
      createdBy: 19910588,
      updatedOn: 1681320957245,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16199400,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.543189964664737,
          42.09948112862396
        ]
      },
      azymuth: 141,
      speed: 0,
      createdOn: 1681320734125,
      createdBy: 19910588,
      updatedOn: 1681320957246,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16093485,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.50472469028033,
          42.64461763792365
        ]
      },
      azymuth: 293,
      speed: 0,
      createdOn: 1603699196754,
      createdBy: 19910588,
      updatedOn: 1603699196806,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14517921,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.556970504066975,
          42.53680004308068
        ]
      },
      azymuth: 217,
      createdOn: 1500989945581,
      createdBy: 36166203,
      updatedOn: 1593498067510,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16219795,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.63512169695526,
          42.74309907021777
        ]
      },
      azymuth: 240,
      speed: 0,
      createdOn: 1692293861444,
      createdBy: 19910588,
      updatedOn: 1692293908771,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10904563,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.692422726441947,
          42.556852691098875
        ]
      },
      azymuth: 89,
      speed: 140,
      createdOn: 1460963763461,
      createdBy: 193666435,
      updatedOn: 1657603780198,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16054169,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.66704178429103,
          42.645280055375395
        ]
      },
      azymuth: 220,
      speed: 0,
      createdOn: 1596289796981,
      createdBy: 19910588,
      updatedOn: 1596289797188,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10898465,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.69272313385188,
          42.556992967976335
        ]
      },
      azymuth: 270,
      speed: 140,
      createdOn: 1460894836885,
      createdBy: 193666435,
      updatedOn: 1657603780197,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16145910,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.656999764477703,
          43.21983682856418
        ]
      },
      azymuth: 307,
      speed: 0,
      createdOn: 1642229944022,
      createdBy: 19910588,
      updatedOn: 1651242617916,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12260410,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.92016159831,
          43.317389132213
        ]
      },
      azymuth: 312,
      createdOn: 1477032191207,
      createdBy: 164222588,
      updatedOn: 1718215720412,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15341803,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.558557467813085,
          43.325337927522526
        ]
      },
      azymuth: 347,
      speed: 0,
      createdOn: 1508110962284,
      createdBy: 320401907,
      updatedOn: 1595527005705,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12260409,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.923283,
          43.315324
        ]
      },
      azymuth: 312,
      speed: 60,
      createdOn: 1477032172154,
      createdBy: 164222588,
      updatedOn: 1660390091398,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341804,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.558565514439906,
          43.32530963475353
        ]
      },
      azymuth: 164,
      speed: 0,
      createdOn: 1508110962284,
      createdBy: 320401907,
      updatedOn: 1595527005704,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341856,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.523537356098036,
          43.25934137083196
        ]
      },
      azymuth: 285,
      speed: 0,
      createdOn: 1508111458504,
      createdBy: 320401907,
      updatedOn: 1699621746090,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16159740,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.93401713448759,
          43.30647361065541
        ]
      },
      azymuth: 193,
      speed: 0,
      createdOn: 1653327214853,
      createdBy: 1184496663,
      updatedOn: 1653327214889,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341857,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.52356551929281,
          43.25933258078507
        ]
      },
      azymuth: 106,
      speed: 0,
      createdOn: 1508111458504,
      createdBy: 320401907,
      updatedOn: 1699621746091,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16159741,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.934274626552995,
          43.306437501775
        ]
      },
      azymuth: 13,
      speed: 0,
      createdOn: 1653327214853,
      createdBy: 1184496663,
      updatedOn: 1653327214892,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16080059,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.90019951678473,
          43.337190056141964
        ]
      },
      azymuth: 283,
      speed: 0,
      createdOn: 1599403957595,
      createdBy: 19910588,
      updatedOn: 1688331609140,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16080058,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.90013916708179,
          43.33699204512972
        ]
      },
      azymuth: 101,
      speed: 0,
      createdOn: 1599403943037,
      createdBy: 19910588,
      updatedOn: 1688331609140,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15263420,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.650354312548924,
          43.28419800232207
        ]
      },
      azymuth: 68,
      createdOn: 1507375639476,
      createdBy: 332307790,
      updatedOn: 1628026327660,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341820,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.65034894813074,
          43.28419800232207
        ]
      },
      azymuth: 245,
      speed: 0,
      createdOn: 1508111091602,
      createdBy: 320401907,
      updatedOn: 1628026299279,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13780744,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.851146221971,
          43.362110103528
        ]
      },
      azymuth: 137,
      createdOn: 1493882301529,
      createdBy: 289427511,
      updatedOn: 1709587726586,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341768,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.92177301002221,
          43.34000328051145
        ]
      },
      azymuth: 194,
      speed: 0,
      createdOn: 1508110688013,
      createdBy: 320401907,
      updatedOn: 1508110688027,
      updatedBy: 320401907,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 13776372,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.851147563075,
          43.36211107855
        ]
      },
      azymuth: 318,
      createdOn: 1493830224969,
      createdBy: 289427511,
      updatedOn: 1709587726587,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341767,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.921773479120027,
          43.34000480866452
        ]
      },
      azymuth: 13,
      speed: 0,
      createdOn: 1508110688013,
      createdBy: 320401907,
      updatedOn: 1508110688024,
      updatedBy: 320401907,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16128539,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.502566067060613,
          43.19664727408843
        ]
      },
      azymuth: 248,
      speed: 0,
      createdOn: 1627365413205,
      createdBy: 1184496663,
      updatedOn: 1627365413242,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12262165,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.920187079295,
          43.317369617408
        ]
      },
      azymuth: 133,
      createdOn: 1477053533285,
      createdBy: 164222588,
      updatedOn: 1718215720411,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 667045,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.557771058002114,
          43.25446515932844
        ]
      },
      azymuth: 40,
      speed: 60,
      createdOn: 1339952905000,
      createdBy: 11325523,
      updatedOn: 1593714840536,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 7918989,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.557750941434172,
          43.254444647575404
        ]
      },
      azymuth: 221,
      speed: 60,
      createdOn: 1429260384370,
      createdBy: 241330672,
      updatedOn: 1593714840537,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 475441,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.923054,
          43.315336
        ]
      },
      azymuth: 130,
      speed: 60,
      createdOn: 1332610800000,
      createdBy: 16084321,
      updatedOn: 1660390091397,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341788,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.549748941299455,
          43.42071283429404
        ]
      },
      azymuth: 130,
      speed: 0,
      createdOn: 1508110883441,
      createdBy: 320401907,
      updatedOn: 1641997723967,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341789,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.54965238177493,
          43.42077517534264
        ]
      },
      azymuth: 312,
      speed: 0,
      createdOn: 1508110883441,
      createdBy: 320401907,
      updatedOn: 1641997723964,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15211408,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.502568214611028,
          43.19664390336418
        ]
      },
      azymuth: 64,
      createdOn: 1506871853149,
      createdBy: 793987595,
      updatedOn: 1593252993041,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 6045029,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.613913550159,
          43.015200153821546
        ]
      },
      azymuth: 82,
      speed: 0,
      createdOn: 1410621282353,
      createdBy: 37242206,
      updatedOn: 1651992184746,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16264772,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.977820519984,
          43.255832605701
        ]
      },
      azymuth: 21,
      speed: 60,
      createdOn: 1722436575312,
      createdBy: 1184496663,
      updatedOn: 1722437154406,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16264773,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.977821525811,
          43.255831873155
        ]
      },
      azymuth: 200,
      speed: 90,
      createdOn: 1722436575312,
      createdBy: 1184496663,
      updatedOn: 1722437154406,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341930,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.793208255227704,
          43.67581224748612
        ]
      },
      azymuth: 201,
      speed: 0,
      createdOn: 1508112102478,
      createdBy: 320401907,
      updatedOn: 1674835279686,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15369563,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.834525565049116,
          43.99531053033125
        ]
      },
      azymuth: 228,
      speed: 0,
      createdOn: 1508397483229,
      createdBy: 19910588,
      updatedOn: 1508397499913,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15341929,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.793091579135233,
          43.675847166236366
        ]
      },
      azymuth: 20,
      speed: 0,
      createdOn: 1508112102478,
      createdBy: 320401907,
      updatedOn: 1674835279687,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16125890,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.536748505498963,
          43.904583227221366
        ]
      },
      azymuth: 42,
      speed: 0,
      createdOn: 1625594868878,
      createdBy: 1184496663,
      updatedOn: 1625594868915,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125891,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.536741799976014,
          43.9045783959211
        ]
      },
      azymuth: 223,
      speed: 0,
      createdOn: 1625594868878,
      createdBy: 1184496663,
      updatedOn: 1625594868918,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16263773,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.838979862477252,
          43.62888551963396
        ]
      },
      azymuth: 282,
      speed: 50,
      createdOn: 1721767462012,
      createdBy: 1184496663,
      updatedOn: 1721767462113,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16263774,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          26.83898433123916,
          43.62888631008445
        ]
      },
      azymuth: 104,
      speed: 30,
      createdOn: 1721767462012,
      createdBy: 1184496663,
      updatedOn: 1721767462990,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125882,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.600037358857662,
          43.50492684951387
        ]
      },
      azymuth: 116,
      speed: 0,
      createdOn: 1625587950509,
      createdBy: 1184496663,
      updatedOn: 1625587950548,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125881,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.600025288916537,
          43.50493074040768
        ]
      },
      azymuth: 293,
      speed: 0,
      createdOn: 1625587950509,
      createdBy: 1184496663,
      updatedOn: 1625587950549,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2847823,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.531896,
          44.447528
        ]
      },
      azymuth: 101,
      speed: 130,
      createdOn: 1376086540141,
      createdBy: 11225731,
      updatedOn: 1506772243319,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2817461,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.860043238098136,
          44.42582728289375
        ]
      },
      azymuth: 92,
      speed: 130,
      createdOn: 1375553030345,
      createdBy: 11225731,
      updatedOn: 1510915199809,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2847824,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.850359593917837,
          44.4268143097443
        ]
      },
      azymuth: 105,
      speed: 130,
      createdOn: 1376087316773,
      createdBy: 11225731,
      updatedOn: 1505481419333,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15875222,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          26.883476777813005,
          45.25234982233716
        ]
      },
      azymuth: 26,
      speed: 0,
      createdOn: 1554392326989,
      createdBy: 11225731,
      updatedOn: 1554392327013,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15872531,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.49120546505654,
          41.16480858954247
        ]
      },
      azymuth: 154,
      speed: 50,
      createdOn: 1548682794764,
      createdBy: 1090249794,
      updatedOn: 1548682794771,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15872525,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.491052860284867,
          41.165142737256
        ]
      },
      azymuth: 155,
      speed: 50,
      createdOn: 1548681638303,
      createdBy: 1090249794,
      updatedOn: 1548681765061,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16179616,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.477129429200577,
          41.971440564449175
        ]
      },
      azymuth: 53,
      speed: 0,
      createdOn: 1671111590600,
      createdBy: 1025514422,
      updatedOn: 1671111590661,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16179617,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.47710260711058,
          41.97142959654551
        ]
      },
      azymuth: 236,
      speed: 0,
      createdOn: 1671111590600,
      createdBy: 1025514422,
      updatedOn: 1671111590663,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10887716,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.437093685157365,
          42.46894155768825
        ]
      },
      azymuth: 348,
      speed: 0,
      createdOn: 1460807972689,
      createdBy: 193666435,
      updatedOn: 1660388153173,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11739349,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.19677930748281,
          42.35259711959156
        ]
      },
      azymuth: 236,
      speed: 0,
      createdOn: 1469551404018,
      createdBy: 19910588,
      updatedOn: 1593864684678,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14424250,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.359707548128284,
          42.42534230066189
        ]
      },
      azymuth: 41,
      speed: 0,
      createdOn: 1500200359472,
      createdBy: 19910588,
      updatedOn: 1640512666297,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10587563,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.471769098676866,
          42.491406108759364
        ]
      },
      azymuth: 157,
      speed: 0,
      createdOn: 1458070657079,
      createdBy: 39536414,
      updatedOn: 1613049441143,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16111743,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.41989416256143,
          42.46761033723095
        ]
      },
      azymuth: 256,
      speed: 0,
      createdOn: 1614508933374,
      createdBy: 1025514422,
      updatedOn: 1660746320260,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16165574,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.422574964451695,
          42.107173449105105
        ]
      },
      azymuth: 125,
      speed: 0,
      createdOn: 1658533792836,
      createdBy: 1025514422,
      updatedOn: 1658533792877,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10904596,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.43689251948149,
          42.46890396591272
        ]
      },
      azymuth: 168,
      speed: 0,
      createdOn: 1460964330819,
      createdBy: 193666435,
      updatedOn: 1660743228872,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10521907,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.424207423034016,
          42.46983392627926
        ]
      },
      azymuth: 29,
      speed: 50,
      createdOn: 1457468735913,
      createdBy: 39536414,
      updatedOn: 1723289127149,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16165573,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.42258502273611,
          42.107187378467586
        ]
      },
      azymuth: 305,
      speed: 0,
      createdOn: 1658533792836,
      createdBy: 1025514422,
      updatedOn: 1658533792874,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16111744,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.41987069323291,
          42.467359554758524
        ]
      },
      azymuth: 348,
      speed: 0,
      createdOn: 1614509005626,
      createdBy: 1025514422,
      updatedOn: 1667497299875,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16159713,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.110223346723657,
          42.24140055891471
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1653302546072,
      createdBy: 1238041227,
      updatedOn: 1653302546105,
      updatedBy: 1238041227,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 10587577,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.47214729014815,
          42.49133985230019
        ]
      },
      azymuth: 277,
      speed: 0,
      createdOn: 1458070745393,
      createdBy: 39536414,
      updatedOn: 1613049441141,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10587578,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.471695337928928,
          42.491225139459395
        ]
      },
      azymuth: 79,
      speed: 0,
      createdOn: 1458070745393,
      createdBy: 39536414,
      updatedOn: 1613049441142,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16222475,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.45064790692444,
          42.49339813416196
        ]
      },
      azymuth: 311,
      speed: 0,
      createdOn: 1693928317291,
      createdBy: 1025514422,
      updatedOn: 1693928317310,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15882639,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.442998903216,
          42.48663161285
        ]
      },
      azymuth: 36,
      speed: 80,
      createdOn: 1567587468365,
      createdBy: 208002844,
      updatedOn: 1708167098914,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16222474,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.450399802590624,
          42.49332940763502
        ]
      },
      azymuth: 44,
      speed: 0,
      createdOn: 1693928317291,
      createdBy: 1025514422,
      updatedOn: 1693928317306,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16222473,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.450423271920943,
          42.49368861415379
        ]
      },
      azymuth: 220,
      speed: 0,
      createdOn: 1693928232381,
      createdBy: 1025514422,
      updatedOn: 1693928232394,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16076021,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.383934551394898,
          42.59334056029714
        ]
      },
      azymuth: 267,
      speed: 0,
      createdOn: 1597767547264,
      createdBy: 1025514422,
      updatedOn: 1597767547280,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14090710,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.47295917845,
          42.54201063791
        ]
      },
      azymuth: 12,
      createdOn: 1497180477826,
      createdBy: 734809195,
      updatedOn: 1721972794222,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16054133,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.254355553105462,
          42.71350814341927
        ]
      },
      azymuth: 4,
      speed: 0,
      createdOn: 1596274713353,
      createdBy: 1025514422,
      updatedOn: 1709983910653,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 956172,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.427310093528526,
          42.55564827662843
        ]
      },
      azymuth: 145,
      speed: 80,
      createdOn: 1347210979000,
      createdBy: 19402606,
      updatedOn: 1661085037098,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16054134,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.254313308312867,
          42.71350962149346
        ]
      },
      azymuth: 184,
      speed: 0,
      createdOn: 1596274713353,
      createdBy: 1025514422,
      updatedOn: 1709983910654,
      updatedBy: 1498591580,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14767138,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.01532394497,
          42.984370991024
        ]
      },
      azymuth: 96,
      speed: 0,
      createdOn: 1503006374474,
      createdBy: 164222588,
      updatedOn: 1722698169734,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16248690,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.254454255555,
          42.712538978532
        ]
      },
      azymuth: 178,
      speed: 50,
      createdOn: 1709984182359,
      createdBy: 1498591580,
      updatedOn: 1715844071543,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10898464,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.40084073984829,
          42.5863511333433
        ]
      },
      azymuth: 327,
      speed: 0,
      createdOn: 1460893236695,
      createdBy: 193666435,
      updatedOn: 1660416793690,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14246805,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.015284921235374,
          42.9843785657799
        ]
      },
      azymuth: 277,
      createdOn: 1498628022414,
      createdBy: 223386176,
      updatedOn: 1722698169733,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10904567,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.226431746217393,
          42.58927457760489
        ]
      },
      azymuth: 248,
      speed: 140,
      createdOn: 1460963858559,
      createdBy: 193666435,
      updatedOn: 1504336545603,
      updatedBy: 193666435,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16103393,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.46472396006529,
          42.512880804687704
        ]
      },
      azymuth: 151,
      speed: 0,
      createdOn: 1609716783910,
      createdBy: 1470184196,
      updatedOn: 1609716783928,
      updatedBy: 1470184196,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16108641,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.470361507448,
          42.50288714678
        ]
      },
      azymuth: 342,
      speed: 0,
      createdOn: 1613049770256,
      createdBy: 1025514422,
      updatedOn: 1723306205510,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16103392,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.468946029158374,
          42.50653392116185
        ]
      },
      azymuth: 165,
      speed: 0,
      createdOn: 1609716696475,
      createdBy: 1470184196,
      updatedOn: 1700507786542,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16108640,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.470497061050658,
          42.50307603944369
        ]
      },
      azymuth: 257,
      speed: 0,
      createdOn: 1613049770256,
      createdBy: 1025514422,
      updatedOn: 1613067335287,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12592598,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.46747759361192,
          42.51065479874004
        ]
      },
      azymuth: 300,
      speed: 0,
      createdOn: 1481382550565,
      createdBy: 264515630,
      updatedOn: 1597867717257,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16104045,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.45624259718991,
          42.51301264476734
        ]
      },
      azymuth: 107,
      speed: 0,
      createdOn: 1610210614874,
      createdBy: 1470184196,
      updatedOn: 1636921415983,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16215661,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.471109289860937,
          42.50737403008482
        ]
      },
      azymuth: 319,
      speed: 0,
      createdOn: 1689576578732,
      createdBy: 208002844,
      updatedOn: 1689757316121,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16215660,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.471269321800875,
          42.507116905551776
        ]
      },
      azymuth: 328,
      speed: 0,
      createdOn: 1689576578732,
      createdBy: 208002844,
      updatedOn: 1718109722253,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16047530,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.277682866114,
          42.685060148378
        ]
      },
      azymuth: 304,
      speed: 0,
      createdOn: 1593968075554,
      createdBy: 1025514422,
      updatedOn: 1723316292935,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16047531,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.277702982681,
          42.685038460017
        ]
      },
      azymuth: 130,
      speed: 0,
      createdOn: 1593968075554,
      createdBy: 1025514422,
      updatedOn: 1723316292936,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10881860,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.400618116500603,
          42.58624153192016
        ]
      },
      azymuth: 146,
      speed: 0,
      createdOn: 1460748287182,
      createdBy: 193666435,
      updatedOn: 1660416793691,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16166420,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.288882466445127,
          42.95573256361958
        ]
      },
      azymuth: 25,
      speed: 0,
      createdOn: 1659134442115,
      createdBy: 1025514422,
      updatedOn: 1659134442708,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10881861,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.44678827498489,
          42.53333141198019
        ]
      },
      azymuth: 150,
      speed: 0,
      createdOn: 1460748555969,
      createdBy: 193666435,
      updatedOn: 1660416644755,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10881859,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.226368910381296,
          42.58915621400112
        ]
      },
      azymuth: 67,
      speed: 140,
      createdOn: 1460747886970,
      createdBy: 193666435,
      updatedOn: 1603729758717,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16142865,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.288907081990715,
          42.95572559288798
        ]
      },
      azymuth: 208,
      speed: 0,
      createdOn: 1639672238574,
      createdBy: 19910588,
      updatedOn: 1639672291916,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16108639,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.470053721993,
          42.502919532582
        ]
      },
      azymuth: 75,
      speed: 0,
      createdOn: 1613049691142,
      createdBy: 1025514422,
      updatedOn: 1723306096798,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16076313,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.467240888666396,
          42.51056335340895
        ]
      },
      azymuth: 120,
      speed: 0,
      createdOn: 1597867623729,
      createdBy: 1025514422,
      updatedOn: 1603807536472,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16076314,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.466994125437353,
          42.51050304884704
        ]
      },
      azymuth: 326,
      speed: 0,
      createdOn: 1597867731377,
      createdBy: 1025514422,
      updatedOn: 1603807536473,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16133316,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.456453319651217,
          42.52229678824768
        ]
      },
      azymuth: 146,
      speed: 0,
      createdOn: 1630966545075,
      createdBy: 1025514422,
      updatedOn: 1630966545133,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15881025,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.38393284339953,
          42.59330327336359
        ]
      },
      azymuth: 94,
      speed: 0,
      createdOn: 1565087109600,
      createdBy: 1197572217,
      updatedOn: 1597767547275,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11414491,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.005777161016518,
          42.65357886900514
        ]
      },
      azymuth: 160,
      createdOn: 1466073722720,
      createdBy: 164222588,
      updatedOn: 1644050210996,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10450344,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.470055735619,
          42.503217378489
        ]
      },
      azymuth: 345,
      createdOn: 1456727607838,
      createdBy: 311103709,
      updatedOn: 1723306205509,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10898463,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.44695859525736,
          42.53341738868917
        ]
      },
      azymuth: 328,
      speed: 0,
      createdOn: 1460892980640,
      createdBy: 193666435,
      updatedOn: 1660416644754,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11413207,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.005766432181055,
          42.65361339067929
        ]
      },
      azymuth: 341,
      createdOn: 1466047038761,
      createdBy: 164222588,
      updatedOn: 1644050210997,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16149199,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.008853878470102,
          42.654032598224305
        ]
      },
      azymuth: 64,
      speed: 0,
      createdOn: 1644049955267,
      createdBy: 19910588,
      updatedOn: 1644050147952,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14199526,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.472784,
          42.542053
        ]
      },
      azymuth: 193,
      createdOn: 1498224830927,
      createdBy: 164222588,
      updatedOn: 1660416062343,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16167881,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.25445559666,
          42.712560164589
        ]
      },
      azymuth: 5,
      speed: 50,
      createdOn: 1660397150875,
      createdBy: 1025514422,
      updatedOn: 1715844071542,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16169481,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.388519579191552,
          42.599759007657795
        ]
      },
      azymuth: 332,
      speed: 0,
      createdOn: 1661606674425,
      createdBy: 1025514422,
      updatedOn: 1661606674502,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 5874634,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.444271425387015,
          43.19372254473054
        ]
      },
      azymuth: 181,
      speed: 0,
      createdOn: 1409119675310,
      createdBy: 187779299,
      updatedOn: 1681114913688,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16254235,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.4214661893842,
          43.31249940874238
        ]
      },
      azymuth: 277,
      speed: 0,
      createdOn: 1714149305739,
      createdBy: 1795796617,
      updatedOn: 1714149305749,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 13921520,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.228634588226853,
          43.29777636846066
        ]
      },
      azymuth: 139,
      speed: 0,
      createdOn: 1495388559810,
      createdBy: 320401907,
      updatedOn: 1637766824417,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13921521,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.228948406681788,
          43.297843716219845
        ]
      },
      azymuth: 315,
      speed: 0,
      createdOn: 1495388559810,
      createdBy: 320401907,
      updatedOn: 1637766824415,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16135967,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.003219843871868,
          43.190516597085804
        ]
      },
      azymuth: 352,
      speed: 0,
      createdOn: 1633108792179,
      createdBy: 1184496663,
      updatedOn: 1633109073110,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16260082,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.05160369821371,
          43.29245264438529
        ]
      },
      azymuth: 69,
      speed: 0,
      createdOn: 1718873811441,
      createdBy: 1184496663,
      updatedOn: 1718873811455,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16260083,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.051603694448,
          43.29245263951
        ]
      },
      azymuth: 247,
      speed: 0,
      createdOn: 1718873811441,
      createdBy: 1184496663,
      updatedOn: 1718873811458,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14694428,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.003219843871868,
          43.190516597085804
        ]
      },
      azymuth: 173,
      createdOn: 1502444361680,
      createdBy: 761265024,
      updatedOn: 1633109073109,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13921498,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.149397752876894,
          43.32322882924205
        ]
      },
      azymuth: 84,
      speed: 0,
      createdOn: 1495388404286,
      createdBy: 320401907,
      updatedOn: 1625822102375,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 5904469,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.444404194733124,
          43.19371667838826
        ]
      },
      azymuth: 1,
      speed: 0,
      createdOn: 1409379507246,
      createdBy: 187779299,
      updatedOn: 1675695229103,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13921499,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.149448624079003,
          43.32334111378458
        ]
      },
      azymuth: 264,
      speed: 0,
      createdOn: 1495388404286,
      createdBy: 320401907,
      updatedOn: 1625822102376,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16262185,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.294437124194456,
          43.95529611650915
        ]
      },
      azymuth: 11,
      speed: 0,
      createdOn: 1720732657286,
      createdBy: 1184496663,
      updatedOn: 1720732657297,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16158292,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.017446903644906,
          43.626893081223415
        ]
      },
      azymuth: 271,
      speed: 0,
      createdOn: 1652202214482,
      createdBy: 1184496663,
      updatedOn: 1652202214520,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16262186,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.294433100881044,
          43.95529418563654
        ]
      },
      azymuth: 197,
      speed: 0,
      createdOn: 1720732657286,
      createdBy: 1184496663,
      updatedOn: 1720732657300,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16243819,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.477867170864,
          43.911107846133
        ]
      },
      azymuth: 145,
      speed: 0,
      createdOn: 1707586726002,
      createdBy: 1184496663,
      updatedOn: 1707586726022,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16158291,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.017447536794585,
          43.62689353952631
        ]
      },
      azymuth: 93,
      speed: 0,
      createdOn: 1652202080688,
      createdBy: 1184496663,
      updatedOn: 1652202142484,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16243818,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.477867174689045,
          43.91110785104454
        ]
      },
      azymuth: 325,
      speed: 0,
      createdOn: 1707586726002,
      createdBy: 1184496663,
      updatedOn: 1707586726019,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2847826,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.369132,
          44.407895
        ]
      },
      azymuth: 102,
      speed: 130,
      createdOn: 1376088554814,
      createdBy: 11225731,
      updatedOn: 1512480442937,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10359759,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.36426466062037,
          44.01671966658069
        ]
      },
      azymuth: 147,
      speed: 0,
      createdOn: 1455864687864,
      createdBy: 300557953,
      updatedOn: 1593527907284,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14612988,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.137733347011824,
          44.09738107321528
        ]
      },
      azymuth: 103,
      speed: 0,
      createdOn: 1501783839742,
      createdBy: 417711387,
      updatedOn: 1501783839705,
      updatedBy: 417711387,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 2859608,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.173585,
          44.424906
        ]
      },
      azymuth: 269,
      speed: 130,
      createdOn: 1376237319333,
      createdBy: 11225731,
      updatedOn: 1502554412522,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10359753,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.364237838530375,
          44.01677367508158
        ]
      },
      azymuth: 344,
      speed: 0,
      createdOn: 1455864598029,
      createdBy: 300557953,
      updatedOn: 1593527907283,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15121505,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.383771,
          44.5624
        ]
      },
      azymuth: 31,
      createdOn: 1506063139420,
      createdBy: 272775009,
      updatedOn: 1509189053379,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14684736,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.85046466634533,
          41.14682347538793
        ]
      },
      azymuth: 111,
      speed: 80,
      createdOn: 1502373241427,
      createdBy: 727783122,
      updatedOn: 1502373241415,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15872530,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.507067122178643,
          41.182550524043805
        ]
      },
      azymuth: 30,
      speed: 50,
      createdOn: 1548682702539,
      createdBy: 1090249794,
      updatedOn: 1548682702545,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15872528,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.542200463774027,
          41.29318868660139
        ]
      },
      azymuth: 128,
      speed: 50,
      createdOn: 1548681817966,
      createdBy: 1090249794,
      updatedOn: 1548681817994,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16099895,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.80896922512133,
          41.178512976868035
        ]
      },
      azymuth: 0,
      speed: 50,
      createdOn: 1607115013384,
      createdBy: 153965488,
      updatedOn: 1607115013378,
      updatedBy: 153965488,
      permissions: -1,
      rank: 0,
      lockRank: 0
    },
    {
      id: 15872529,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.541942971708625,
          41.293027465773164
        ]
      },
      azymuth: 307,
      speed: 50,
      createdOn: 1548681833209,
      createdBy: 1090249794,
      updatedOn: 1548681833235,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684742,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.85009183929211,
          41.14688608831824
        ]
      },
      azymuth: 299,
      speed: 80,
      createdOn: 1502373255493,
      createdBy: 727783122,
      updatedOn: 1510210723383,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15872532,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.506874410548757,
          41.182330949848826
        ]
      },
      azymuth: 33,
      speed: 50,
      createdOn: 1548682909310,
      createdBy: 1090249794,
      updatedOn: 1548682909310,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684747,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.835023576098415,
          41.151840157065976
        ]
      },
      azymuth: 106,
      speed: 80,
      createdOn: 1502373278359,
      createdBy: 727783122,
      updatedOn: 1502373278341,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684719,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.87723115380281,
          41.13702420338138
        ]
      },
      azymuth: 110,
      speed: 112,
      createdOn: 1502373161813,
      createdBy: 727783122,
      updatedOn: 1502373171145,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684754,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.81980873738803,
          41.154926832486346
        ]
      },
      azymuth: 274,
      speed: 80,
      createdOn: 1502373301646,
      createdBy: 727783122,
      updatedOn: 1502373301627,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684727,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.865612631446627,
          41.14113089939906
        ]
      },
      azymuth: 122,
      speed: 80,
      createdOn: 1502373211021,
      createdBy: 727783122,
      updatedOn: 1502373211011,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684759,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.81727799724266,
          41.14749035574851
        ]
      },
      azymuth: 274,
      speed: 80,
      createdOn: 1502373322874,
      createdBy: 727783122,
      updatedOn: 1502373322858,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 14684765,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.81747584985275,
          41.14743198541663
        ]
      },
      azymuth: 105,
      speed: 80,
      createdOn: 1502373333923,
      createdBy: 727783122,
      updatedOn: 1502373333908,
      updatedBy: 727783122,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15872527,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.507067122178643,
          41.182550524043805
        ]
      },
      azymuth: 32,
      speed: 50,
      createdOn: 1548681710542,
      createdBy: 1090249794,
      updatedOn: 1548681765061,
      updatedBy: 1090249794,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 10881862,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.543985444105974,
          42.428267377431766
        ]
      },
      azymuth: 99,
      speed: 90,
      createdOn: 1460749509154,
      createdBy: 193666435,
      updatedOn: 1590938673596,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10881863,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.58625517400883,
          42.44368539209015
        ]
      },
      azymuth: 95,
      speed: 50,
      createdOn: 1460749667436,
      createdBy: 193666435,
      updatedOn: 1662987905602,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10904615,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.63872830862782,
          42.431845300842255
        ]
      },
      azymuth: 340,
      speed: 0,
      createdOn: 1460964554809,
      createdBy: 193666435,
      updatedOn: 1691867086661,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16168565,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.742393213450608,
          42.26939083539787
        ]
      },
      azymuth: 174,
      speed: 0,
      createdOn: 1661092085479,
      createdBy: 1025514422,
      updatedOn: 1661092085524,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 6035384,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.74243510063737,
          42.26939350497309
        ]
      },
      azymuth: 355,
      speed: 0,
      createdOn: 1410536583423,
      createdBy: 37242206,
      updatedOn: 1660745746204,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10904610,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.58621225866429,
          42.44369034037026
        ]
      },
      azymuth: 274,
      speed: 50,
      createdOn: 1460964479261,
      createdBy: 193666435,
      updatedOn: 1662987905600,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10887715,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.544010194457243,
          42.42838431067872
        ]
      },
      azymuth: 280,
      speed: 90,
      createdOn: 1460807561256,
      createdBy: 193666435,
      updatedOn: 1590938673595,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16156497,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.83350283231553,
          42.16106478111296
        ]
      },
      azymuth: 28,
      speed: 50,
      createdOn: 1650634760324,
      createdBy: 1025514422,
      updatedOn: 1723308662936,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11229059,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.7781979710124,
          42.22339252486625
        ]
      },
      azymuth: 340,
      speed: 80,
      createdOn: 1464090996636,
      createdBy: 232915817,
      updatedOn: 1694011347461,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 6007840,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.638527142951947,
          42.43179580867171
        ]
      },
      azymuth: 160,
      speed: 90,
      createdOn: 1410263629407,
      createdBy: 37242206,
      updatedOn: 1691867086661,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15369588,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.838292863988997,
          42.166872142209876
        ]
      },
      azymuth: 345,
      speed: 0,
      createdOn: 1508397667476,
      createdBy: 19910588,
      updatedOn: 1633461271579,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16260411,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.69681069502,
          42.673283644338
        ]
      },
      azymuth: 15,
      speed: 80,
      createdOn: 1719162406293,
      createdBy: 19910588,
      updatedOn: 1721920430433,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14613782,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.621514001272374,
          42.64891215285093
        ]
      },
      azymuth: 285,
      speed: 0,
      createdOn: 1501789515412,
      createdBy: 303144014,
      updatedOn: 1692778518008,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 12137586,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.676897662114012,
          42.69128882053351
        ]
      },
      azymuth: 133,
      speed: 60,
      createdOn: 1475241575337,
      createdBy: 164222588,
      updatedOn: 1628715569378,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14613781,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.6215528933035,
          42.64890031595556
        ]
      },
      azymuth: 104,
      speed: 90,
      createdOn: 1501789499392,
      createdBy: 303144014,
      updatedOn: 1692778518009,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 12137588,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.702195127364,
          42.695674901848
        ]
      },
      azymuth: 20,
      createdOn: 1475241784970,
      createdBy: 164222588,
      updatedOn: 1705586434846,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 13781599,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.539262234065195,
          42.61799251858587
        ]
      },
      azymuth: 20,
      speed: 0,
      createdOn: 1493896236410,
      createdBy: 19910588,
      updatedOn: 1493896689564,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14082508,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.79543848399975,
          42.9936283136234
        ]
      },
      azymuth: 5,
      createdOn: 1497099436480,
      createdBy: 447611037,
      updatedOn: 1654034163648,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15866799,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.680926454357554,
          42.656784268290345
        ]
      },
      azymuth: 67,
      speed: 80,
      createdOn: 1537901922032,
      createdBy: 209238910,
      updatedOn: 1661069347562,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16264101,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.696806001154,
          42.673268361007
        ]
      },
      azymuth: 195,
      speed: 80,
      createdOn: 1721920430401,
      createdBy: 19910588,
      updatedOn: 1721920430433,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16130728,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.648057544954213,
          42.70091871011225
        ]
      },
      azymuth: 87,
      speed: 90,
      createdOn: 1628715798997,
      createdBy: 1184496663,
      updatedOn: 1628715799032,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14032054,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.530168242243537,
          42.565123560271275
        ]
      },
      azymuth: 105,
      createdOn: 1496580958140,
      createdBy: 289427511,
      updatedOn: 1589703751618,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 14032055,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.67555288138286,
          42.654868234044606
        ]
      },
      azymuth: 62,
      createdOn: 1496581827610,
      createdBy: 289427511,
      updatedOn: 1593986457464,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11566354,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.605565746004626,
          42.580073729098785
        ]
      },
      azymuth: 206,
      createdOn: 1467706520657,
      createdBy: 44849397,
      updatedOn: 1593949787393,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14004919,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.768225484847186,
          42.96005675453702
        ]
      },
      azymuth: 164,
      createdOn: 1496315216954,
      createdBy: -2,
      updatedOn: 1595492613292,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11645460,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.701431984486,
          42.694416981519
        ]
      },
      azymuth: 200,
      speed: 60,
      createdOn: 1468494464183,
      createdBy: 342858884,
      updatedOn: 1723396114516,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14137394,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.653365364418033,
          42.64711924414991
        ]
      },
      azymuth: 71,
      createdOn: 1497641727807,
      createdBy: 352645932,
      updatedOn: 1498235495692,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 13242860,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.615489126901196,
          42.75444078804526
        ]
      },
      azymuth: 315,
      speed: 0,
      createdOn: 1488297889880,
      createdBy: 14318025,
      updatedOn: 1593690063343,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14033648,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.79543982510452,
          42.99362684223824
        ]
      },
      azymuth: 185,
      createdOn: 1496591151650,
      createdBy: 264195690,
      updatedOn: 1654034163648,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11645462,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.826585586646146,
          42.77430660639498
        ]
      },
      azymuth: 121,
      createdOn: 1468495953756,
      createdBy: 342858884,
      updatedOn: 1633111495638,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 14199728,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.87679918950172,
          42.82637481702399
        ]
      },
      azymuth: 180,
      speed: 50,
      createdOn: 1498236032829,
      createdBy: 164222588,
      updatedOn: 1593109020429,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14056639,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.768212073802186,
          42.96008521684562
        ]
      },
      azymuth: 345,
      createdOn: 1496839209500,
      createdBy: 285430962,
      updatedOn: 1595492613293,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14038458,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.836432441661756,
          42.91166590123739
        ]
      },
      azymuth: 298,
      createdOn: 1496641088925,
      createdBy: 289427511,
      updatedOn: 1593613490939,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16102808,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.648058215507945,
          42.70091797092457
        ]
      },
      azymuth: 266,
      speed: 90,
      createdOn: 1609242089652,
      createdBy: 1025514422,
      updatedOn: 1628715799029,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 9404793,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.876798518949787,
          42.826393505209424
        ]
      },
      azymuth: 2,
      speed: 50,
      createdOn: 1447330319766,
      createdBy: 209388160,
      updatedOn: 1593109020428,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12138895,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.702183727976,
          42.695659624024
        ]
      },
      azymuth: 199,
      speed: 0,
      createdOn: 1475418969846,
      createdBy: 164222588,
      updatedOn: 1705586434846,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16047618,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.605677323561373,
          42.580004400551616
        ]
      },
      azymuth: 15,
      speed: 0,
      createdOn: 1593984934464,
      createdBy: 1025514422,
      updatedOn: 1593984934483,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14199727,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.75500142582289,
          42.754428739074434
        ]
      },
      azymuth: 165,
      speed: 0,
      createdOn: 1498236032829,
      createdBy: 164222588,
      updatedOn: 1692295264307,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16183233,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.755000638813357,
          42.754467748707725
        ]
      },
      azymuth: 345,
      speed: 0,
      createdOn: 1674609288688,
      createdBy: 1025514422,
      updatedOn: 1674609288701,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14199520,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.83649413246857,
          42.911634469847236
        ]
      },
      azymuth: 117,
      createdOn: 1498220548461,
      createdBy: 164222588,
      updatedOn: 1593613490940,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 12138880,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.67689597629063,
          42.691286300008784
        ]
      },
      azymuth: 315,
      speed: 60,
      createdOn: 1475418868217,
      createdBy: 164222588,
      updatedOn: 1623831321585,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14199522,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.826605703214096,
          42.77432777135552
        ]
      },
      azymuth: 302,
      createdOn: 1498221820522,
      createdBy: 164222588,
      updatedOn: 1633111495639,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 15883912,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.74624490956514,
          42.71323047900951
        ]
      },
      azymuth: 271,
      speed: 50,
      createdOn: 1570110346278,
      createdBy: 19910588,
      updatedOn: 1570110346278,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14199523,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.675446263574806,
          42.65503245500363
        ]
      },
      azymuth: 243,
      createdOn: 1498223579123,
      createdBy: 164222588,
      updatedOn: 1593986457464,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15883913,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.734330537121163,
          42.711145364591324
        ]
      },
      azymuth: 48,
      speed: 50,
      createdOn: 1570110444820,
      createdBy: 19910588,
      updatedOn: 1591021682434,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14199524,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.65335,
          42.647135
        ]
      },
      azymuth: 252,
      createdOn: 1498223700306,
      createdBy: 164222588,
      updatedOn: 1498390795250,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 14199525,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.530256755140346,
          42.56532999664935
        ]
      },
      azymuth: 287,
      createdOn: 1498224631900,
      createdBy: 164222588,
      updatedOn: 1589703751619,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16250615,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.936536338400245,
          43.22020608721013
        ]
      },
      azymuth: 230,
      speed: 0,
      createdOn: 1711309569816,
      createdBy: 1795796617,
      updatedOn: 1711309569826,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16250614,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.937340809747,
          43.220050398786
        ]
      },
      azymuth: 276,
      speed: 0,
      createdOn: 1711309420584,
      createdBy: 1795796617,
      updatedOn: 1711309420595,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16250613,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.937221451444,
          43.219937520324
        ]
      },
      azymuth: 31,
      speed: 0,
      createdOn: 1711309363898,
      createdBy: 1795796617,
      updatedOn: 1711309363908,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15876854,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.894737114086606,
          43.21344825791144
        ]
      },
      azymuth: 244,
      speed: 0,
      createdOn: 1557321379398,
      createdBy: 1109378885,
      updatedOn: 1661868691004,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10999084,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.87447101728328,
          43.222086889781046
        ]
      },
      azymuth: 171,
      speed: 50,
      createdOn: 1461877103574,
      createdBy: -2,
      updatedOn: 1589645368233,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 10999339,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.910532878593028,
          43.20507426679495
        ]
      },
      azymuth: 162,
      speed: 0,
      createdOn: 1461878960483,
      createdBy: -2,
      updatedOn: 1675520129559,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16079225,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.826181411514856,
          43.152247030529935
        ]
      },
      azymuth: 25,
      speed: 0,
      createdOn: 1598980451618,
      createdBy: 19910588,
      updatedOn: 1598980451581,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11023653,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.91055324785043,
          43.47741261717024
        ]
      },
      azymuth: 282,
      speed: 50,
      createdOn: 1462110519291,
      createdBy: -2,
      updatedOn: 1507019524935,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16079224,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.82593196607628,
          43.1523350853792
        ]
      },
      azymuth: 198,
      speed: 0,
      createdOn: 1598980451618,
      createdBy: 19910588,
      updatedOn: 1598980451578,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16250618,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.933983884499185,
          43.21917774822578
        ]
      },
      azymuth: 108,
      speed: 0,
      createdOn: 1711309714708,
      createdBy: 1795796617,
      updatedOn: 1711309714720,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10999209,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.904223567970124,
          43.21285920350939
        ]
      },
      azymuth: 218,
      speed: 50,
      createdOn: 1461878133510,
      createdBy: -2,
      updatedOn: 1589799766039,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16250617,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.934494174764644,
          43.219099074256015
        ]
      },
      azymuth: 212,
      speed: 0,
      createdOn: 1711309678727,
      createdBy: 1795796617,
      updatedOn: 1711309678738,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15886973,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.860771784209,
          43.057266535501
        ]
      },
      azymuth: 163,
      speed: 60,
      createdOn: 1575883342222,
      createdBy: 33409425,
      updatedOn: 1723308754178,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16250616,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.93509232132402,
          43.2188864871002
        ]
      },
      azymuth: 59,
      speed: 0,
      createdOn: 1711309639445,
      createdBy: 1795796617,
      updatedOn: 1711309639455,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 8279790,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.962617195758,
          43.26992298115
        ]
      },
      azymuth: 163,
      createdOn: 1434192218283,
      createdBy: 164656382,
      updatedOn: 1723980188062,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10593721,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.822882219249124,
          43.25845657200709
        ]
      },
      azymuth: 332,
      speed: 0,
      createdOn: 1458140553093,
      createdBy: 300557953,
      updatedOn: 1604671491034,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16126038,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.78118288198332,
          43.240868327615075
        ]
      },
      azymuth: 267,
      speed: 0,
      createdOn: 1625690160830,
      createdBy: 1184496663,
      updatedOn: 1625690160876,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16126039,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.78120433965514,
          43.24064948638294
        ]
      },
      azymuth: 88,
      speed: 0,
      createdOn: 1625690160830,
      createdBy: 1184496663,
      updatedOn: 1625690160879,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245077,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.87480317740135,
          43.22155958325866
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1708187197535,
      createdBy: 1795796617,
      updatedOn: 1708187197546,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16122194,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.867118655826,
          43.224292719332
        ]
      },
      azymuth: 311,
      speed: 0,
      createdOn: 1622271311651,
      createdBy: 1479238035,
      updatedOn: 1709801514992,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16122195,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.86695034721,
          43.224152486122
        ]
      },
      azymuth: 130,
      speed: 0,
      createdOn: 1622271311651,
      createdBy: 1479238035,
      updatedOn: 1709801514992,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16245074,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.87506126142059,
          43.22190529422859
        ]
      },
      azymuth: 284,
      speed: 0,
      createdOn: 1708186969024,
      createdBy: 1795796617,
      updatedOn: 1708187173941,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16245073,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.874210092524,
          43.221782686916
        ]
      },
      azymuth: 105,
      speed: 0,
      createdOn: 1708186933769,
      createdBy: 1795796617,
      updatedOn: 1716322710273,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14033649,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.82323540142886,
          43.11592030553018
        ]
      },
      azymuth: 136,
      createdOn: 1496591876747,
      createdBy: 264195690,
      updatedOn: 1622266336109,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16122193,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.85726907173519,
          43.22627564756919
        ]
      },
      azymuth: 290,
      speed: 0,
      createdOn: 1622271166377,
      createdBy: 1479238035,
      updatedOn: 1626710318852,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11114628,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.81724018821654,
          43.269974895316274
        ]
      },
      azymuth: 336,
      createdOn: 1462978147665,
      createdBy: 19910588,
      updatedOn: 1557577308649,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16250693,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.917273080111,
          43.207158178943
        ]
      },
      azymuth: 64,
      speed: 0,
      createdOn: 1711376627721,
      createdBy: 1795796617,
      updatedOn: 1711376627733,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10478636,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.866359590676,
          43.224514550997
        ]
      },
      azymuth: 129,
      speed: 0,
      createdOn: 1457027893329,
      createdBy: 300557953,
      updatedOn: 1709801469110,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16250691,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.917615065647528,
          43.207228564378326
        ]
      },
      azymuth: 311,
      speed: 0,
      createdOn: 1711376610250,
      createdBy: 1795796617,
      updatedOn: 1711376610260,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 10478634,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.866525887635,
          43.224657715057
        ]
      },
      azymuth: 307,
      speed: 0,
      createdOn: 1457027865047,
      createdBy: 300557953,
      updatedOn: 1709801469111,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16250690,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.917362351534,
          43.207427756689
        ]
      },
      azymuth: 229,
      speed: 0,
      createdOn: 1711376533476,
      createdBy: 1795796617,
      updatedOn: 1711376533488,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16250831,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.859964940592,
          43.211543428001
        ]
      },
      azymuth: 111,
      speed: 0,
      createdOn: 1711563896473,
      createdBy: 1795796617,
      updatedOn: 1711563896483,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16250702,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.906424719636274,
          43.22851566693656
        ]
      },
      azymuth: 332,
      speed: 0,
      createdOn: 1711378139174,
      createdBy: 1795796617,
      updatedOn: 1711378139185,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16250830,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.860049430177,
          43.211752599403
        ]
      },
      azymuth: 292,
      speed: 0,
      createdOn: 1711563868979,
      createdBy: 1795796617,
      updatedOn: 1711563868990,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16263620,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.90642511677,
          43.22851510421
        ]
      },
      azymuth: 154,
      speed: 0,
      createdOn: 1721680231073,
      createdBy: 1184496663,
      updatedOn: 1721680231085,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11022959,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.910568708003975,
          43.47741891234492
        ]
      },
      azymuth: 89,
      speed: 0,
      createdOn: 1462104140981,
      createdBy: -2,
      updatedOn: 1675187875432,
      updatedBy: 19910588,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16251952,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.91462183272783,
          43.20616623685358
        ]
      },
      azymuth: 62,
      speed: 0,
      createdOn: 1712430782882,
      createdBy: 1795796617,
      updatedOn: 1712430782891,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15873976,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.83928955415731,
          43.23946595848878
        ]
      },
      azymuth: 112,
      speed: 0,
      createdOn: 1551634778002,
      createdBy: 19910588,
      updatedOn: 1626710424135,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16252452,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.813207465780746,
          43.200471030775496
        ]
      },
      azymuth: 296,
      speed: 0,
      createdOn: 1712950347045,
      createdBy: 1795796617,
      updatedOn: 1712950347056,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 5350021,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.822745426588703,
          43.25841750455658
        ]
      },
      azymuth: 156,
      speed: 0,
      createdOn: 1404525875928,
      createdBy: 187779299,
      updatedOn: 1592817251760,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 8279722,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.962615854653,
          43.269924445903
        ]
      },
      azymuth: 340,
      speed: 0,
      createdOn: 1434188395459,
      createdBy: 224030152,
      updatedOn: 1723980188063,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10999167,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.8959286112914,
          43.2159723406137
        ]
      },
      azymuth: 300,
      speed: 0,
      createdOn: 1461877753774,
      createdBy: -2,
      updatedOn: 1639132837389,
      updatedBy: 1266393878,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16140334,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          27.860765078687,
          43.057281479083
        ]
      },
      azymuth: 339,
      speed: 60,
      createdOn: 1636569038589,
      createdBy: 1184496663,
      updatedOn: 1723308754179,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16251951,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.91485652601665,
          43.206180545235256
        ]
      },
      azymuth: 170,
      speed: 0,
      createdOn: 1712430769896,
      createdBy: 1795796617,
      updatedOn: 1712430769907,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16251950,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.914887367534,
          43.206474774403
        ]
      },
      azymuth: 240,
      speed: 0,
      createdOn: 1712430751557,
      createdBy: 1795796617,
      updatedOn: 1712430751566,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16251949,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.91457690572749,
          43.20641405325653
        ]
      },
      azymuth: 151,
      speed: 0,
      createdOn: 1712430725842,
      createdBy: 1795796617,
      updatedOn: 1712430725855,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 14970427,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.896594779446517,
          43.19900831834727
        ]
      },
      azymuth: 1,
      speed: 50,
      createdOn: 1504722384032,
      createdBy: 392331643,
      updatedOn: 1589633231671,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125334,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.583582138154,
          43.227252975564
        ]
      },
      azymuth: 85,
      speed: 0,
      createdOn: 1625051107493,
      createdBy: 1479238035,
      updatedOn: 1722169582259,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16125333,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.583564033241,
          43.22742349434
        ]
      },
      azymuth: 267,
      speed: 0,
      createdOn: 1625051107493,
      createdBy: 1479238035,
      updatedOn: 1722169612369,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16039696,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.82526365817817,
          43.44116303648352
        ]
      },
      azymuth: 15,
      speed: 0,
      createdOn: 1592811892769,
      createdBy: 19910588,
      updatedOn: 1592811892771,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16127376,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.85722347418292,
          43.22618965373319
        ]
      },
      azymuth: 116,
      speed: 0,
      createdOn: 1626710318814,
      createdBy: 1184496663,
      updatedOn: 1626710318852,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16127377,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.839409583010358,
          43.239703855215105
        ]
      },
      azymuth: 294,
      speed: 0,
      createdOn: 1626710424100,
      createdBy: 1184496663,
      updatedOn: 1626710434042,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14038459,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.82321059099557,
          43.11593743748968
        ]
      },
      azymuth: 315,
      createdOn: 1496642193434,
      createdBy: 289427511,
      updatedOn: 1622266336110,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16232199,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.557590975264,
          43.413340617321
        ]
      },
      azymuth: 1,
      speed: 0,
      createdOn: 1699898315521,
      createdBy: 1795796617,
      updatedOn: 1709320226081,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16249223,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.917540381364,
          43.212308718756
        ]
      },
      azymuth: 220,
      speed: 0,
      createdOn: 1710354070382,
      createdBy: 1795796617,
      updatedOn: 1723395060521,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 7616513,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.896097389723,
          43.199039216379
        ]
      },
      azymuth: 177,
      speed: 0,
      createdOn: 1425896524184,
      createdBy: 166105939,
      updatedOn: 1706995407520,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16247565,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.557674127579574,
          43.41330749946293
        ]
      },
      azymuth: 179,
      speed: 0,
      createdOn: 1709320246357,
      createdBy: 1795796617,
      updatedOn: 1709320246366,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16119561,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.955947178197505,
          43.21619482168162
        ]
      },
      azymuth: 250,
      speed: 0,
      createdOn: 1620195065672,
      createdBy: 1479238035,
      updatedOn: 1633110653384,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16249225,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.91722556096782,
          43.212189233198025
        ]
      },
      azymuth: 131,
      speed: 0,
      createdOn: 1710354246535,
      createdBy: 1795796617,
      updatedOn: 1723395060522,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16119562,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.956034349990443,
          43.216031113062044
        ]
      },
      azymuth: 70,
      speed: 0,
      createdOn: 1620195076439,
      createdBy: 1479238035,
      updatedOn: 1633110653384,
      updatedBy: 1184496663,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16232200,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.550734578472,
          43.397732929043
        ]
      },
      azymuth: 18,
      speed: 0,
      createdOn: 1699898473880,
      createdBy: 1795796617,
      updatedOn: 1709320265328,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16249224,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          27.91762286317691,
          43.21216870721173
        ]
      },
      azymuth: 313,
      speed: 0,
      createdOn: 1710354216418,
      createdBy: 1795796617,
      updatedOn: 1723395060521,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16264408,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.756085579449145,
          43.80919505607954
        ]
      },
      azymuth: 345,
      speed: 0,
      createdOn: 1722191554148,
      createdBy: 1795796617,
      updatedOn: 1722191554163,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 16233525,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.709195463738,
          43.5398310768
        ]
      },
      azymuth: 242,
      speed: 0,
      createdOn: 1700685464849,
      createdBy: 1795796617,
      updatedOn: 1711660174244,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 14578861,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.572553542327878,
          43.64253275264188
        ]
      },
      azymuth: 338,
      createdOn: 1501485686437,
      createdBy: 760308899,
      updatedOn: 1503006532706,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 15342047,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.9056065438787,
          43.55181388408022
        ]
      },
      azymuth: 117,
      speed: 0,
      createdOn: 1508113272435,
      createdBy: 320401907,
      updatedOn: 1593530801192,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15342028,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.819407380224998,
          43.53504498951246
        ]
      },
      azymuth: 11,
      speed: 0,
      createdOn: 1508113091049,
      createdBy: 320401907,
      updatedOn: 1660553542203,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15342029,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.81939665138864,
          43.53501776684197
        ]
      },
      azymuth: 189,
      speed: 0,
      createdOn: 1508113091049,
      createdBy: 320401907,
      updatedOn: 1660553542201,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15342048,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.905574357370526,
          43.55182943555973
        ]
      },
      azymuth: 296,
      speed: 0,
      createdOn: 1508113272435,
      createdBy: 320401907,
      updatedOn: 1593530801193,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15342070,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.87490307278644,
          43.618058696071785
        ]
      },
      azymuth: 48,
      speed: 0,
      createdOn: 1508113471848,
      createdBy: 320401907,
      updatedOn: 1604415545070,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15342071,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.8748695451744,
          43.61804121989426
        ]
      },
      azymuth: 229,
      speed: 0,
      createdOn: 1508113471848,
      createdBy: 320401907,
      updatedOn: 1604415545069,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 14704142,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.572454402446738,
          43.64250364892026
        ]
      },
      azymuth: 157,
      createdOn: 1502518162870,
      createdBy: 179225510,
      updatedOn: 1503912032856,
      updatedBy: 164222588,
      permissions: -1,
      rank: 2,
      lockRank: "None"
    },
    {
      id: 5280453,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.84454784788514,
          44.38749549552281
        ]
      },
      azymuth: 156,
      createdOn: 1403808004676,
      createdBy: 160036772,
      updatedOn: 1504554665603,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 7901137,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.86451340608209,
          44.7560294280025
        ]
      },
      azymuth: 283,
      createdOn: 1429003382792,
      createdBy: 239431745,
      updatedOn: 1509107216725,
      updatedBy: 211579407,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 5907181,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.516788,
          44.814377
        ]
      },
      azymuth: 39,
      createdOn: 1409398722437,
      createdBy: 180007439,
      updatedOn: 1510057533807,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16194707,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.884562605024115,
          45.212922205251814
        ]
      },
      azymuth: 46,
      speed: 0,
      createdOn: 1680512858943,
      createdBy: 11225731,
      updatedOn: 1680512858957,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15876731,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          27.91414705910624,
          45.413758341283746
        ]
      },
      azymuth: 105,
      speed: 0,
      createdOn: 1557113351341,
      createdBy: 11225731,
      updatedOn: 1557113351368,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 15879518,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.00536436236503,
          41.30439358903134
        ]
      },
      azymuth: 128,
      speed: 50,
      createdOn: 1562340607339,
      createdBy: 153965488,
      updatedOn: 1562340607360,
      updatedBy: 153965488,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15879516,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.01425856745869,
          41.28381406469578
        ]
      },
      azymuth: 147,
      speed: 50,
      createdOn: 1562340527220,
      createdBy: 153965488,
      updatedOn: 1562340527252,
      updatedBy: 153965488,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15879517,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.00557763070916,
          41.30439457076117
        ]
      },
      azymuth: 304,
      speed: 50,
      createdOn: 1562340575880,
      createdBy: 153965488,
      updatedOn: 1562340575903,
      updatedBy: 153965488,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16099898,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.013972563786993,
          41.28444075369587
        ]
      },
      azymuth: 0,
      speed: 50,
      createdOn: 1607115159425,
      createdBy: 153965488,
      updatedOn: 1607115159414,
      updatedBy: 153965488,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15341986,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.052677668632,
          43.393145590326
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1508112626853,
      createdBy: 320401907,
      updatedOn: 1723544560147,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341979,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.0194502527176,
          43.2659591407409
        ]
      },
      azymuth: 19,
      speed: 0,
      createdOn: 1508112566933,
      createdBy: 320401907,
      updatedOn: 1575820087638,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341987,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.052677668632,
          43.393130485221
        ]
      },
      azymuth: 180,
      speed: 0,
      createdOn: 1508112626853,
      createdBy: 320401907,
      updatedOn: 1723544560147,
      updatedBy: 1025514422,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16250598,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.101152701995,
          43.472680269351
        ]
      },
      azymuth: 302,
      speed: 0,
      createdOn: 1711303731394,
      createdBy: 1795796617,
      updatedOn: 1711303731404,
      updatedBy: 1795796617,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16144541,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.153258673222638,
          43.43691401887438
        ]
      },
      azymuth: 52,
      speed: 0,
      createdOn: 1641134576142,
      createdBy: 19910588,
      updatedOn: 1641134576176,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 15341980,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.019235675996732,
          43.26600015648254
        ]
      },
      azymuth: 200,
      speed: 0,
      createdOn: 1508112566933,
      createdBy: 320401907,
      updatedOn: 1575820087639,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16165051,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.09558466046133,
          43.7488689597148
        ]
      },
      azymuth: 44,
      speed: 0,
      createdOn: 1658133528229,
      createdBy: 19910588,
      updatedOn: 1658133580874,
      updatedBy: 19910588,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 2971721,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.041138,
          44.290312
        ]
      },
      azymuth: 139,
      speed: 130,
      createdOn: 1377346540530,
      createdBy: 152930013,
      updatedOn: 1503129018885,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4962799,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.250029,
          44.210856
        ]
      },
      azymuth: 99,
      speed: 130,
      createdOn: 1400746701890,
      createdBy: 11225731,
      updatedOn: 1503852894222,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5277972,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.151663,
          44.2355
        ]
      },
      azymuth: 112,
      createdOn: 1403785938517,
      createdBy: 177390975,
      updatedOn: 1503824539693,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5361205,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.038041,
          44.308546
        ]
      },
      azymuth: 2,
      createdOn: 1404643982452,
      createdBy: 188716552,
      updatedOn: 1484906502087,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4966286,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.477310084655752,
          44.1411018566833
        ]
      },
      azymuth: 87,
      speed: 130,
      createdOn: 1400773571335,
      createdBy: 11225731,
      updatedOn: 1503824539704,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5277973,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.19857,
          44.218208
        ]
      },
      azymuth: 112,
      createdOn: 1403786022291,
      createdBy: 177390975,
      updatedOn: 1499966539025,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4870604,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.129482,
          44.244318
        ]
      },
      azymuth: 288,
      speed: 137,
      createdOn: 1399217250744,
      createdBy: 175024319,
      updatedOn: 1506168108225,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5167497,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.392616,
          44.133349
        ]
      },
      azymuth: 104,
      createdOn: 1402726230468,
      createdBy: 187254372,
      updatedOn: 1502947062565,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8702765,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.17634015189316,
          44.22887730299896
        ]
      },
      azymuth: 299,
      speed: 0,
      createdOn: 1439363126098,
      createdBy: 11225731,
      updatedOn: 1596132563061,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5750394,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.083917,
          44.247172
        ]
      },
      azymuth: 126,
      createdOn: 1408107286246,
      createdBy: 157739589,
      updatedOn: 1503663515019,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8526991,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.334071,
          44.166829
        ]
      },
      azymuth: 134,
      createdOn: 1437370232600,
      createdBy: 242074182,
      updatedOn: 1510328896914,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5026783,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.31458816071319,
          44.180214388399776
        ]
      },
      azymuth: 313,
      speed: 130,
      createdOn: 1401441334623,
      createdBy: -2,
      updatedOn: 1508526135031,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8620247,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.030041743384345,
          44.325503266730244
        ]
      },
      azymuth: 162,
      speed: 0,
      createdOn: 1438432707149,
      createdBy: 11225731,
      updatedOn: 1596132608048,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5280454,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.367796,
          44.141941
        ]
      },
      azymuth: 128,
      createdOn: 1403809497362,
      createdBy: 160036772,
      updatedOn: 1501574272424,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4966290,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.414509,
          44.133422
        ]
      },
      azymuth: 257,
      speed: 130,
      createdOn: 1400773705960,
      createdBy: 11225731,
      updatedOn: 1508000597392,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5722862,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.067102,
          44.260349
        ]
      },
      azymuth: 330,
      createdOn: 1407854260454,
      createdBy: 33972981,
      updatedOn: 1502625931621,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4966289,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.43386632275394,
          44.136901500767536
        ]
      },
      azymuth: 76,
      speed: 130,
      createdOn: 1400773705960,
      createdBy: 11225731,
      updatedOn: 1507917119249,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4962800,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.275347,
          44.204741
        ]
      },
      azymuth: 301,
      speed: 130,
      createdOn: 1400746760940,
      createdBy: 11225731,
      updatedOn: 1508000597408,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2859607,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.297133,
          44.19187
        ]
      },
      azymuth: 313,
      speed: 130,
      createdOn: 1376233551174,
      createdBy: 11225731,
      updatedOn: 1508526135035,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5197107,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.224367,
          44.21387
        ]
      },
      azymuth: 279,
      createdOn: 1403005752557,
      createdBy: 181858000,
      updatedOn: 1506168108219,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 8544510,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.029844,
          44.326268
        ]
      },
      azymuth: 343,
      createdOn: 1437582298458,
      createdBy: 262202197,
      updatedOn: 1502625931672,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 2859606,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.350879,
          44.154635
        ]
      },
      azymuth: 317,
      speed: 130,
      createdOn: 1376233551174,
      createdBy: 11225731,
      updatedOn: 1502305275988,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5347980,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.10582393100808,
          44.24152352655374
        ]
      },
      azymuth: 91,
      createdOn: 1404497057412,
      createdBy: 188858971,
      updatedOn: 1563007575467,
      updatedBy: 22086421,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 5792486,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.053994,
          44.278397
        ]
      },
      azymuth: 328,
      createdOn: 1408427881877,
      createdBy: 186924733,
      updatedOn: 1502625931632,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16103735,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.994899195450966,
          41.03781155912919
        ]
      },
      azymuth: 309,
      speed: 50,
      createdOn: 1610012164972,
      createdBy: 150306578,
      updatedOn: 1610012164991,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 12014003,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.948151162960514,
          41.050060160287714
        ]
      },
      azymuth: 40,
      speed: 80,
      createdOn: 1473162240870,
      createdBy: -2,
      updatedOn: 1700047819783,
      updatedBy: 1557085506,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 15891163,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.894664925308067,
          41.06910066821799
        ]
      },
      azymuth: 134,
      speed: 0,
      createdOn: 1582537886352,
      createdBy: 1131237437,
      updatedOn: 1582537886343,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16266708,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.946443171044287,
          41.12068114053989
        ]
      },
      azymuth: 352,
      speed: 90,
      createdOn: 1723542491830,
      createdBy: 759108619,
      updatedOn: 1723542491842,
      updatedBy: 759108619,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 10119063,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.985022916671795,
          41.03797060782441
        ]
      },
      azymuth: 61,
      speed: 50,
      createdOn: 1453671563842,
      createdBy: 150306578,
      updatedOn: 1507009610929,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16142779,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.95357563523068,
          41.00717347020464
        ]
      },
      azymuth: 178,
      speed: 0,
      createdOn: 1639584447995,
      createdBy: 150306578,
      updatedOn: 1639584448032,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16266709,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.9462601552915,
          41.12065938828366
        ]
      },
      azymuth: 170,
      speed: 90,
      createdOn: 1723542516746,
      createdBy: 759108619,
      updatedOn: 1723542516757,
      updatedBy: 759108619,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 11144740,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.923110394734522,
          41.030831485917965
        ]
      },
      azymuth: 71,
      speed: 50,
      createdOn: 1463261492152,
      createdBy: -2,
      updatedOn: 1584041560363,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 11144741,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.923084913748568,
          41.03098121302897
        ]
      },
      azymuth: 97,
      speed: 50,
      createdOn: 1463261492152,
      createdBy: -2,
      updatedOn: 1584041446755,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 4,
      lockRank: "None"
    },
    {
      id: 16139142,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.897428556621364,
          41.15590919886216
        ]
      },
      azymuth: 322,
      speed: 120,
      createdOn: 1635492576179,
      createdBy: 150306578,
      updatedOn: 1635492576217,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 14257096,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.988782,
          41.062115
        ]
      },
      azymuth: 53,
      createdOn: 1498724024288,
      createdBy: 752514127,
      updatedOn: 1501772543925,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 15893347,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.892748898128456,
          41.05813058897232
        ]
      },
      azymuth: 66,
      speed: 0,
      createdOn: 1583472678473,
      createdBy: 1131237437,
      updatedOn: 1583472678505,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 10173545,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.99520059727975,
          41.03736642503206
        ]
      },
      azymuth: 31,
      createdOn: 1454177518223,
      createdBy: 223960233,
      updatedOn: 1555430503474,
      updatedBy: 32506681,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16139141,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.897704651891093,
          41.15583656554451
        ]
      },
      azymuth: 143,
      speed: 120,
      createdOn: 1635492576179,
      createdBy: 150306578,
      updatedOn: 1635492576217,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 15893348,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.89318134830607,
          41.058382891280786
        ]
      },
      azymuth: 248,
      speed: 0,
      createdOn: 1583472725578,
      createdBy: 1131237437,
      updatedOn: 1583472725595,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16234543,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.948305428283,
          41.001441947783
        ]
      },
      azymuth: 261,
      speed: 80,
      createdOn: 1701376429510,
      createdBy: 1131237437,
      updatedOn: 1701376429522,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 11032113,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.864117796295144,
          41.0084916242589
        ]
      },
      azymuth: 165,
      createdOn: 1462193059380,
      createdBy: -2,
      updatedOn: 1487088741874,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 12013998,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.94953518281206,
          41.05115850469758
        ]
      },
      azymuth: 230,
      speed: 80,
      createdOn: 1473162205274,
      createdBy: -2,
      updatedOn: 1700047819784,
      updatedBy: 1557085506,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16234542,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.948872811858,
          41.001863060598
        ]
      },
      azymuth: 78,
      speed: 80,
      createdOn: 1701376410745,
      createdBy: 1131237437,
      updatedOn: 1701376410756,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 15192605,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.793770708004015,
          41.07960938624096
        ]
      },
      azymuth: 201,
      createdOn: 1506704728490,
      createdBy: 793118147,
      updatedOn: 1507641368027,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16151404,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.623018425110715,
          41.033488456490296
        ]
      },
      azymuth: 356,
      speed: 0,
      createdOn: 1646316133436,
      createdBy: 1131237437,
      updatedOn: 1646316151990,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 2,
      lockRank: 2
    },
    {
      id: 16223117,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          28.793603650428405,
          41.07358752825833
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1694323924088,
      createdBy: 1131237437,
      updatedOn: 1694323924099,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16234764,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          28.959693140955,
          41.003275348148
        ]
      },
      azymuth: 287,
      speed: 70,
      createdOn: 1701533827252,
      createdBy: 394148641,
      updatedOn: 1701533839034,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16218116,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.564665903132635,
          43.74260225229919
        ]
      },
      azymuth: 219,
      speed: 0,
      createdOn: 1691048853092,
      createdBy: 11225731,
      updatedOn: 1691048853106,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16218340,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.555942271430133,
          43.73313678855562
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1691137617256,
      createdBy: 11225731,
      updatedOn: 1691137617269,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 6036416,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.548494,
          44.145173
        ]
      },
      azymuth: 244,
      createdOn: 1410540886787,
      createdBy: 199601530,
      updatedOn: 1502009050709,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 4966285,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.503223,
          44.142184
        ]
      },
      azymuth: 267,
      speed: 130,
      createdOn: 1400773518306,
      createdBy: 11225731,
      updatedOn: 1507898156645,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 16186992,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.623978999644436,
          44.23487328563287
        ]
      },
      azymuth: 344,
      speed: 0,
      createdOn: 1677929401458,
      createdBy: 11225731,
      updatedOn: 1677929401473,
      updatedBy: 11225731,
      permissions: -1,
      rank: 5,
      lockRank: "None"
    },
    {
      id: 9614490,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          28.730094,
          45.101152
        ]
      },
      azymuth: 42,
      createdOn: 1449384776830,
      createdBy: 163328264,
      updatedOn: 1502787180946,
      updatedBy: 211579407,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16168180,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.176301133390222,
          41.02500460062705
        ]
      },
      azymuth: 320,
      speed: 80,
      createdOn: 1660696354950,
      createdBy: 394148641,
      updatedOn: 1660696354986,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168181,
      type: 3,
      geometry: {
        type: "Point",
        coordinates: [
          29.141179423927817,
          41.03475182881986
        ]
      },
      azymuth: 80,
      speed: 0,
      createdOn: 1660696585135,
      createdBy: 394148641,
      updatedOn: 1660696585184,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 14100691,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.053982,
          41.0231
        ]
      },
      azymuth: 267,
      createdOn: 1497277652953,
      createdBy: 743890648,
      updatedOn: 1551508421722,
      updatedBy: 793849958,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16168178,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.243507136160527,
          41.02704579830137
        ]
      },
      azymuth: 96,
      speed: 0,
      createdOn: 1660695866453,
      createdBy: 394148641,
      updatedOn: 1660695866496,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168179,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.176136177535938,
          41.02537996264667
        ]
      },
      azymuth: 140,
      speed: 80,
      createdOn: 1660696354950,
      createdBy: 394148641,
      updatedOn: 1660696354982,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16228209,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.37098834780033,
          41.08864666928481
        ]
      },
      azymuth: 266,
      speed: 80,
      createdOn: 1697359222166,
      createdBy: 1131237437,
      updatedOn: 1697359331858,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16224959,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.020968910847458,
          41.02583915670901
        ]
      },
      azymuth: 165,
      speed: 0,
      createdOn: 1695491658217,
      createdBy: 394148641,
      updatedOn: 1695491658230,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16103737,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.00972,
          41.05585999999998
        ]
      },
      azymuth: 191,
      speed: 0,
      createdOn: 1610012247264,
      createdBy: 150306578,
      updatedOn: 1610012247275,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16103736,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.008317067826496,
          41.05014280282431
        ]
      },
      azymuth: 10,
      speed: 50,
      createdOn: 1610012223030,
      createdBy: 150306578,
      updatedOn: 1610012223042,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 14161559,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.156892617586752,
          41.00177920309038
        ]
      },
      azymuth: 210,
      speed: 0,
      createdOn: 1497866725915,
      createdBy: 743948365,
      updatedOn: 1497866725891,
      updatedBy: 743948365,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 14216962,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.176290168558356,
          41.02494459543093
        ]
      },
      azymuth: 319,
      speed: 80,
      createdOn: 1498381875358,
      createdBy: 743948365,
      updatedOn: 1498381875318,
      updatedBy: 743948365,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16182572,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.029759778986904,
          41.016523598644014
        ]
      },
      azymuth: 45,
      speed: 0,
      createdOn: 1673966580581,
      createdBy: 150306578,
      updatedOn: 1673966580593,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 14216963,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.176435007845367,
          41.02504779499921
        ]
      },
      azymuth: 142,
      speed: 80,
      createdOn: 1498381875358,
      createdBy: 743948365,
      updatedOn: 1498381875321,
      updatedBy: 743948365,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 14188422,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.050425721565276,
          41.01947065311625
        ]
      },
      azymuth: 321,
      speed: 80,
      createdOn: 1498136998150,
      createdBy: 743948365,
      updatedOn: 1507896753413,
      updatedBy: 743948365,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16171670,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.278129171876568,
          41.04364223728436
        ]
      },
      azymuth: 244,
      speed: 90,
      createdOn: 1663887174379,
      createdBy: 394148641,
      updatedOn: 1663887194801,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16171671,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.278394243060365,
          41.04390302476658
        ]
      },
      azymuth: 68,
      speed: 90,
      createdOn: 1663887209600,
      createdBy: 394148641,
      updatedOn: 1663887209652,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168212,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.135861315325595,
          41.03163987595462
        ]
      },
      azymuth: 53,
      speed: 80,
      createdOn: 1660730790569,
      createdBy: 394148641,
      updatedOn: 1660730790616,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 10945028,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.00866979629518,
          41.05008907962441
        ]
      },
      azymuth: 97,
      createdOn: 1461347760370,
      createdBy: -2,
      updatedOn: 1463261603658,
      updatedBy: -2,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16146837,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.069773881395577,
          41.00555582091951
        ]
      },
      azymuth: 0,
      speed: 0,
      createdOn: 1642722943066,
      createdBy: 394148641,
      updatedOn: "None",
      updatedBy: "None",
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 16168210,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.176133342579558,
          41.02537842441595
        ]
      },
      azymuth: 139,
      speed: 80,
      createdOn: 1660730617988,
      createdBy: 394148641,
      updatedOn: 1660730645716,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168211,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.135649504299167,
          41.03127358010509
        ]
      },
      azymuth: 228,
      speed: 80,
      createdOn: 1660730790569,
      createdBy: 394148641,
      updatedOn: 1660730790612,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 14182847,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.135620522823363,
          41.031259020822645
        ]
      },
      azymuth: 229,
      speed: 80,
      createdOn: 1498075518260,
      createdBy: 743948365,
      updatedOn: 1511419846736,
      updatedBy: 743948365,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 14186623,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.058806025795008,
          41.002229233050976
        ]
      },
      azymuth: 196,
      speed: 80,
      createdOn: 1498122531262,
      createdBy: 743948365,
      updatedOn: 1505203293478,
      updatedBy: 743948365,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168209,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.176311709478842,
          41.025010144709796
        ]
      },
      azymuth: 318,
      speed: 80,
      createdOn: 1660730594919,
      createdBy: 394148641,
      updatedOn: 1660730594950,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 12013983,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.00913295746914,
          41.066991939155
        ]
      },
      azymuth: 92,
      speed: 80,
      createdOn: 1473162103840,
      createdBy: -2,
      updatedOn: 1473162111500,
      updatedBy: -2,
      permissions: -1,
      rank: 0,
      lockRank: "None"
    },
    {
      id: 15856857,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.08031694737489,
          41.02337440373956
        ]
      },
      azymuth: 69,
      speed: 0,
      createdOn: 1520959773756,
      createdBy: 150306578,
      updatedOn: 1520959773754,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 15856856,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.08014979449731,
          41.02318823443541
        ]
      },
      azymuth: 238,
      speed: 0,
      createdOn: 1520959758205,
      createdBy: 150306578,
      updatedOn: 1520959758366,
      updatedBy: 150306578,
      permissions: -1,
      rank: 3,
      lockRank: "None"
    },
    {
      id: 16171612,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.03392373048475,
          41.00424882860149
        ]
      },
      azymuth: 104,
      speed: 70,
      createdOn: 1663859284625,
      createdBy: 394148641,
      updatedOn: 1663859284673,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16171611,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.04105170094128,
          41.00288149545595
        ]
      },
      azymuth: 109,
      speed: 80,
      createdOn: 1663859147580,
      createdBy: 394148641,
      updatedOn: 1663859213992,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16171672,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.333069732724162,
          41.08478202631712
        ]
      },
      azymuth: 245,
      speed: 90,
      createdOn: 1663887321355,
      createdBy: 394148641,
      updatedOn: 1663887321412,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16171673,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.332321396409313,
          41.08462433487221
        ]
      },
      azymuth: 65,
      speed: 90,
      createdOn: 1663887334303,
      createdBy: 394148641,
      updatedOn: 1663887334364,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16232582,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.175942316298794,
          41.00706053241959
        ]
      },
      azymuth: 85,
      speed: 0,
      createdOn: 1700086741194,
      createdBy: 394148641,
      updatedOn: 1700086741203,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 15890180,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.12627946972596,
          41.008260708166155
        ]
      },
      azymuth: 32,
      speed: 0,
      createdOn: 1581407743618,
      createdBy: 1131237437,
      updatedOn: 1581407746671,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 15890181,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.126093104407243,
          41.00842776941613
        ]
      },
      azymuth: 117,
      speed: 0,
      createdOn: 1581407887736,
      createdBy: 1131237437,
      updatedOn: 1581407887716,
      updatedBy: 1131237437,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 12013966,
      type: 4,
      geometry: {
        type: "Point",
        coordinates: [
          29.00850129724512,
          41.049916540589635
        ]
      },
      azymuth: 189,
      speed: 0,
      createdOn: 1473162013053,
      createdBy: -2,
      updatedOn: 1555566482774,
      updatedBy: 793849958,
      permissions: -1,
      rank: 1,
      lockRank: 1
    },
    {
      id: 16168778,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.054246504719462,
          41.016932903363454
        ]
      },
      azymuth: 135,
      speed: 0,
      createdOn: 1661245651085,
      createdBy: 394148641,
      updatedOn: 1661245651135,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    },
    {
      id: 16168777,
      type: 2,
      geometry: {
        type: "Point",
        coordinates: [
          29.05110832017191,
          41.01918936681708
        ]
      },
      azymuth: 140,
      speed: 80,
      createdOn: 1661245567010,
      createdBy: 394148641,
      updatedOn: 1661245567043,
      updatedBy: 394148641,
      permissions: -1,
      rank: 1,
      lockRank: "None"
    }
  ]